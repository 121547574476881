import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDictationPlay = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={100}
        fill="currentColor"
        viewBox="0 0 256 256"
        {...props}>
        <defs>
            <clipPath id="dictation-play_svg__a">
                <path
                    fill="none"
                    d="M0 256V0h256v256zm243.37-53.59-48.127-23.893c-1.366-.682-3.072-1.024-4.438-1.024-7.509 0-13.312 6.144-13.312 13.312v48.128c0 7.51 5.803 13.654 13.312 13.654 1.707 0 3.072-.342 4.438-1.024l47.786-23.894c6.144-2.73 9.558-7.168 9.558-12.629 0-6.144-4.779-10.581-9.216-12.63z"
                />
            </clipPath>
        </defs>
        <g strokeMiterlimit={10} fontFamily="none" fontSize="none" fontWeight="none" textAnchor="none">
            <g
                fill="none"
                clipPath="url(#dictation-play_svg__a)"
                style={{
                    mixBlendMode: 'normal',
                }}>
                <path
                    fill="currentColor"
                    d="M189.12 14.56c0 15.84-3.7 22.84-8.48 26.72-4.78 3.88-12.06 5.1-20.32 6.24-8.26 1.14-17.48 2.2-25.12 8.32-6.34 5.08-10.72 13.48-12 26.08H20.48C9.22 81.92 0 91.14 0 102.4v102.4c0 11.26 9.22 20.48 20.48 20.48h215.04c11.26 0 20.48-9.22 20.48-20.48V102.4c0-11.26-9.22-20.48-20.48-20.48H133.6c1.1-9.92 4.18-15.02 8-18.08 4.76-3.82 11.88-4.94 20.16-6.08 8.28-1.14 17.64-2.26 25.28-8.48 7.64-6.22 12.32-17.18 12.32-34.72zM20.48 92.16h215.04c5.72 0 10.24 4.52 10.24 10.24v102.4c0 5.72-4.52 10.24-10.24 10.24H20.48c-5.72 0-10.24-4.52-10.24-10.24V102.4c0-5.72 4.52-10.24 10.24-10.24zm5.12 20.48v20.48h20.48v-20.48zm30.72 0v20.48H76.8v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zM25.6 143.36v20.48h35.84v-20.48zm46.08 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h35.84v-20.48zM25.6 174.08v20.48h20.48v-20.48zm30.72 0v20.48H76.8v-20.48zm30.72 0v20.48h81.92v-20.48zm92.16 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48z"
                />
            </g>
            <path
                d="M1.9 18c-1 0-1.9-.9-1.9-1.9V1.9C0 .9.8 0 1.9 0c.1 0 .3 0 .4.1l14.1 7c.4.2 1.6.8 1.6 1.9 0 1.1-1.2 1.7-1.6 1.9l-14.1 7c-.1.1-.3.1-.4.1zM2 2.2v13.7l13.6-6.7c.1 0 .1-.1.2-.1-.1 0-.1-.1-.2-.1z"
                style={{
                    mixBlendMode: 'normal',
                }}
                transform="translate(184.32 184.32)scale(3.41333)"
            />
        </g>
    </svg>
);
export default SvgDictationPlay;
