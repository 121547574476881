import styled from "@emotion/styled";
import { color, typographyLineHeight, typographySize } from "../../constants/Styles";
import { LabelSmall } from "./Typography";

interface CardTitleProps {
  fontWeight?: number;
}

export const CardTitle = styled.h2<CardTitleProps>`
  font-size: ${typographySize.font_2xlg};
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: ${typographyLineHeight.font_2xlg};
  text-transform: capitalize;
`;

export const CardItem = styled(LabelSmall)`
    color: ${color.neutral};
    margin-bottom: 0.875rem;
`;

export const CardTitleCentered = styled(CardTitle)`
  text-align: center;
  display: flex;
  align-items: center;
  align-self: center;
  gap: 0.5rem;
`;

export const CardSubtitle = styled.h3<CardTitleProps>`
  font-size: ${typographySize.font_lg};
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: ${typographyLineHeight.font_lg};
`;

export const CardSubtitleCentered = styled(CardSubtitle)`
  text-align: center;
  display: flex;
  align-self: center;
`;
