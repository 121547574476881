import styles from "../styles/score.module.css";
import { useContext } from "react";
import { GameContext } from "../context/game-context";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { color } from "../../../constants/Styles";

export default function Score() {
  const { score } = useContext(GameContext);
  const { t } = useTranslation();
  return (
    <ScoreBoard>
      {t('common:2048.score')}
      <div>{score}</div>
    </ScoreBoard>
  );
}

const ScoreBoard = styled.div`
  background: ${color.dark_primary};
  border: calc(var(--pixel-size) * 0.5) solid ${color.dark_primary};
  color: var(--tile-background);
  font-size: calc(var(--pixel-size) * 1.5);
  line-height: calc(var(--pixel-size) * 3);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase; 
  > div {
    font-size: calc(var(--pixel-size) * 2.5);
    color: var(--secondary-text-color);
  }

  @media (min-width: 575.98px) {
    .score {
      border: calc(var(--pixel-size) * 1) solid var(--secondary-background);
      font-size: calc(var(--pixel-size) * 2);
      line-height: calc(var(--pixel-size) * 3.5);
    }

    .score > div {
      font-size: calc(var(--pixel-size) * 3.5);
    }
  }
`
