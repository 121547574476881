import styled from "@emotion/styled";
import { IconName, iconMap } from "./IconMap";
import SrOnlyText from "../text/SrOnlyText";

export interface IIconsProps {
  name: IconName;
  ariaLabel?: string;
  size?: number;
  color?: string;
}
/**
 * This is a wrapper component for the icons defined in the IconMap.tsx file.
 * This has the flexibility to change the size and color of the icons.
 * By default the size is 24px and color is inherit -- which means that it will inherit the text color of the parent element when no color prop is passed.
 * Icons can be enhanced to have hover and active states if needed. An example of adding a hover state is below where the parent component is extended and the svg element is targeted to change the size and color.
 *
 *      const IconButtonLinkWithHover = styled(IconButtonLink)`
 *          :hover {
 *              svg {
 *                  height: 26px;
 *                  width: 26px;
 *                  color: ${color.bg_default};
 *              }
 *          }
 *      `;
 *
 *
 *
 *
 * It is important to note that the svg jsx components must have fill and stroke values set to 'currentcolor' for color changes to work.
 *
 *  */

function Icons({
  ariaLabel = "",
  name,
  size = 24,
  color = "",
  ...restProps
}: IIconsProps) {
  const svgToRender = iconMap[name] || null;

  return (
    <IconContainer
      aria-hidden={ariaLabel ? "false" : "true"}
      $size={size}
      $color={color}
      {...restProps}
    >
      {svgToRender}
      {ariaLabel && <SrOnlyText>{ariaLabel}</SrOnlyText>}
    </IconContainer>
  );
}

const IconContainer = styled("span")<{ $size?: number; $color?: string }>`
  color: ${(props) => props.$color || "inherit"};
  display: inline-block;
  line-height: 0;
  svg {
    width: ${(props) => props.$size + "px" || "24px"};
    height: ${(props) => props.$size + "px" || "24px"};
    transition: all 0.1s ease-in-out;
  }
`;

export default Icons;
