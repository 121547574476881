import {
    headTiltLeftThresholdRange,
    headTiltRightThresholdRange,
    headTurnRightThresholdRange,
    headTurnLeftThresholdRange,
    headTiltBackThresholdRange,
    headTiltForwardThresholdRange,
    mouthOpenThresholdRange,
    eyebrowRaiseThresholdRange,
    smileThresholdRange,
    headLeanForwardThresholdRange,
    headLeanBackThresholdRange,
    headLeanLeftThresholdRange,
    headLeanRightThresholdRange,
    headMoveDownThresholdRange,
    headMoveUpThresholdRange,
} from '../../constants/ExpressionThresholds';
import ExpressionSettingsModel from '../../models/devices/ExpressionSettingsModel';
import FaceExpression from '../../models/expressionDetection/FaceExpression';
import Point from '../../models/expressionDetection/Point';
import { getTriggerValue } from './getDetectionThresholdValues';

/** DISTANCE CALCULATIONS */
export const distance = (point1: Point | null, point2: Point | null): number | null => {
    if (!point1 || !point2) {
        return null;
    }
    return Math.sqrt(
        Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2) + Math.pow(point1.z - point2.z, 2)
    );
};

export const calculateMedianDistance = (dPoints: Point[], pointA: Point) => {
    // Calculate Euclidean distances from Point A to each data point
    const distances: number[] = dPoints.map((dPoint) => distance(dPoint, pointA));
    distances.sort((a, b) => a - b);
    const middleIndex = Math.floor(distances.length / 2);
    if (distances.length % 2 === 0) {
        return (distances[middleIndex - 1] + distances[middleIndex]) / 2;
    } else {
        return distances[middleIndex];
    }
};

/** BOUNDING BOX */
export const boundingCenter = (face: FaceExpression) => {
    return {
        x: face.boundingBox.x + face.boundingBox.width / 2,
        y: face.boundingBox.y + face.boundingBox.height / 2,
    };
};
export const boundingArea = (face: FaceExpression) => {
    return face.boundingBox.width * face.boundingBox.height;
};

/** FEATURE DISTANCE */
export const eyeDistance = (face: FaceExpression) => {
    // distance between left and right eye @ tear ducts
    return distance(face.leftEyePoints[0], face.rightEyePoints[0]);
};
export const mouthHeight = (face: FaceExpression) => {
    return distance(face.innerLipPoints[4], face.innerLipPoints[14]);
};
export const mouthWidth = (face: FaceExpression) => {
    // left most [9] /// right most[19]
    return distance(face.outerLipPoints[9], face.outerLipPoints[19]);
};

/** RATIOS */
export const mouthOpenRatio = (face: FaceExpression) => {
    return mouthHeight(face) / mouthWidth(face);
};
export const mouthEyeRatio = (face: FaceExpression) => {
    // left most [9] /// right most[19]
    return distance(face.outerLipPoints[9], face.outerLipPoints[19]) / eyeDistance(face);
};
export const leftBrowRaiseRatio = (face: FaceExpression) => {
    return calculateMedianDistance(face.rightEyebrowPoints, face.rightEyePoints[0]) / eyeDistance(face);
};
export const rightBrowRaiseRatio = (face: FaceExpression) => {
    return calculateMedianDistance(face.leftEyebrowPoints, face.leftEyePoints[0]) / eyeDistance(face);
};

/** TILT */
export const isTiltLeft = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        face?.rollDegrees - baselineFace.rollDegrees >
        getTriggerValue(headTiltLeftThresholdRange, expressionSettings?.headTiltLeft, 20)
    );
};
export const isTiltRight = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        face?.rollDegrees - baselineFace.rollDegrees <
        -getTriggerValue(headTiltRightThresholdRange, expressionSettings?.headTiltRight, 20)
    );
};
export const isTiltDown = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        face?.pitchDegrees - baselineFace.pitchDegrees >
        getTriggerValue(headTiltBackThresholdRange, expressionSettings?.headTiltUp, 20)
    );
};
export const isTiltUp = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        face?.pitchDegrees - baselineFace.pitchDegrees <
        -getTriggerValue(headTiltForwardThresholdRange, expressionSettings?.headTiltDown, 20)
    );
};

/** TURN */
export const isTurnRight = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        face?.yawDegrees - baselineFace.yawDegrees >
        getTriggerValue(headTurnRightThresholdRange, expressionSettings?.headTurnRight, 20)
    );
};
export const isTurnLeft = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        face?.yawDegrees - baselineFace.yawDegrees <
        -getTriggerValue(headTurnLeftThresholdRange, expressionSettings?.headTurnLeft, 20)
    );
};

/** MOUTH AND SMILE */
export const isMouthOpen = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        mouthOpenRatio(face) - mouthOpenRatio(baselineFace) >
        getTriggerValue(mouthOpenThresholdRange, expressionSettings?.mouthOpen, 0.1)
    );
};

export const isSmiling = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    const triggerValue = getTriggerValue(smileThresholdRange, expressionSettings?.smiling, 0.15);
    const mouth = mouthEyeRatio(face);
    const base = mouthEyeRatio(baselineFace);
    return mouth - base > triggerValue;
};
// export const isMouthPucker = (face: FaceExpression, baselineFace: FaceExpression, expressionSettings: ExpressionSettingsModel) => {
//     return face.mouthPuckerRatio - baselineFace.mouthPuckerRatio > getTriggerValue(mouthPuckerThresholdRange, expressionSettings?.mouthPucker, 0.2); // TODO: test values
// };
//

/** EYE */
export const isRightEyebrowRaised = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    // invert left and right for mirrored video
    return (
        leftBrowRaiseRatio(face) - leftBrowRaiseRatio(baselineFace) >
        getTriggerValue(eyebrowRaiseThresholdRange, expressionSettings?.eyebrowRaise, 0.035)
    );
};
export const isLeftEyebrowRaised = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    // invert left and right for mirrored video
    return (
        rightBrowRaiseRatio(face) - rightBrowRaiseRatio(baselineFace) >
        getTriggerValue(eyebrowRaiseThresholdRange, expressionSettings?.eyebrowRaise, 0.035)
    );
};

/** LEANING */
export const isLeaningForward = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        boundingArea(face) >
        getTriggerValue(headLeanForwardThresholdRange, expressionSettings?.leanForward, 2) * boundingArea(baselineFace)
    );
};
export const isLeaningBack = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        boundingArea(face) <
        boundingArea(baselineFace) / getTriggerValue(headLeanBackThresholdRange, expressionSettings?.leanBack, 2)
    );
};
export const isLeaningLeft = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        boundingCenter(face).x - boundingCenter(baselineFace).x >
        face.boundingBox.width / getTriggerValue(headLeanLeftThresholdRange, expressionSettings?.leanLeft, 4, true)
    );
};
export const isLeaningRight = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        boundingCenter(baselineFace).x - boundingCenter(face).x >
        face.boundingBox.width / getTriggerValue(headLeanRightThresholdRange, expressionSettings?.leanRight, 4, true)
    );
};

/** BODY POSITION */
export const isMovingDown = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        boundingCenter(face).y - boundingCenter(baselineFace).y >
        face.boundingBox.height / getTriggerValue(headMoveDownThresholdRange, expressionSettings?.moveDown, 4, true)
    );
};
export const isMovingUp = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel
) => {
    return (
        boundingCenter(baselineFace).y - boundingCenter(face).y >
        face.boundingBox.height / getTriggerValue(headMoveUpThresholdRange, expressionSettings?.moveUp, 4, true)
    );
};
