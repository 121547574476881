import { PeripheralType } from "../models/peripherals/PeripheralType";
import { useState } from "react";
import { createContainer } from "unstated-next";
import {
  handleCameraDevices,
  handleCameraPermission,
} from "./peripherals/CameraPeripheral";
import {
  handleHidSwitchDevices,
  handleHidSwitchPermission,
} from "./peripherals/HidSwitchPeripheral";
import {
  handleMicrophoneDevices,
  handleMicrophonePermission,
} from "./peripherals/MicrophonePeripheral";
import {
  handleCompanionAppDevices,
  handleCompanionAppPermission,
} from "./peripherals/CompanionAppPeripheral";

function usePeripheralsContainer() {
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [microphoneEnabled, setMicrophoneEnabled] = useState(false);
  const [companionAppEnabled, setCompanionAppEnabled] = useState(false);
  const [cameraDevices, setCameraDevices] = useState([] as MediaDeviceInfo[]);
  const [microphoneDevices, setMicrophoneDevices] = useState(
    [] as MediaDeviceInfo[]
  );
  const [companionAppDevices, setCompanionAppDevices] = useState(
    [] as string[]
  );
  const [selectedCameraDevice, setSelectedCameraDevice] = useState(
    null as MediaDeviceInfo
  );
  const [selectedMicrophoneDevice, setSelectedMicrophoneDevice] = useState(
    null as MediaDeviceInfo
  );
  const [detectedPeripheral, setDetectedPeripheral] = useState(
    [] as PeripheralType[]
  );

  async function getPeripherals() {
    const peripherals = [] as PeripheralType[];
    const cameraPermission = await handleCameraPermission();
    const microphonePermission = await handleMicrophonePermission();
    const companionAppPermission = await handleCompanionAppPermission();
    if (microphonePermission) {
      const mics = await handleMicrophoneDevices();
      setMicrophoneEnabled(mics?.length > 0);
      setMicrophoneDevices(mics);
      peripherals.push("microphone");
    }
    if (cameraPermission) {
      const cameras = await handleCameraDevices();
      setCameraEnabled(cameras?.length > 0);
      setCameraDevices(cameras);
      peripherals.push("camera");
    }
    if (companionAppPermission) {
      const companionApp = await handleCompanionAppDevices();
      setCompanionAppEnabled(companionAppPermission);
      setCompanionAppDevices(companionApp);
      peripherals.push("companionApp");
    }
    setDetectedPeripheral(peripherals);
  }

  return {
    cameraEnabled,
    microphoneEnabled,
    companionAppEnabled,
    cameraDevices,
    microphoneDevices,
    companionAppDevices,
    selectedCameraDevice,
    selectedMicrophoneDevice,
    detectedPeripheral,
    getPeripherals,
    setCameraEnabled,
    setMicrophoneEnabled,
    setCompanionAppEnabled,
    setCameraDevices,
    setMicrophoneDevices,
    setCompanionAppDevices,
    setSelectedCameraDevice,
    setSelectedMicrophoneDevice,
    setDetectedPeripheral,
  };
}

const PeripheralsContainer = createContainer(usePeripheralsContainer);
export default PeripheralsContainer;
