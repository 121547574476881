import { Dialog, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogTitleWrap, DisplayDialogTitle, StyledDialogContent, StyledDialogTag } from './CoreDialogStyles';
import IconsButton from '../buttons/IconsButton';
import { BodyMedium } from '../text/Typography';
import Button from '../buttons/CoreButton';
import { createUUID } from '../../constants/Keys';
import { ComponentProps } from 'react';
import { IconName } from '../images/IconMap';



export interface IContentDialogProps extends ComponentProps<typeof Dialog> {
    open: boolean;
    onClose?: () => void;
    onCancel?: () => void;
    onConfirm?: () => void;
    title: string;
    description?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    cancelIcon?: IconName;
    confirmIcon?: IconName;
    dialogTag?: string;
}


export default function ContentDialog({
    title,
    description,
    onCancel,
    cancelButtonText,
    onConfirm,
    onClose,
    confirmButtonText,
    confirmIcon,
    cancelIcon,
    children,
    scroll = 'paper',
    dialogTag,
    ...restProps
}: Readonly<IContentDialogProps>) {
    const randomId = createUUID();
    const { t } = useTranslation('common');

    const cancelText = cancelButtonText ?? t('common:cancel');
    const confirmText = confirmButtonText ?? t('common:save');

    return (
        <Dialog
            scroll={scroll}
            onClose={onClose}
            aria-labelledby={`dialog-title-${randomId}`}
            aria-describedby={description ? `dialog-description-${randomId}` : undefined}
            {...restProps}>
            <DialogTitleWrap>
                <DisplayDialogTitle id={`dialog-title-${randomId}`}>
                    {dialogTag && <StyledDialogTag as="span">{dialogTag}</StyledDialogTag>}
                    {title}
                </DisplayDialogTitle>
                {onClose && <IconsButton circle={true} label={'Close'} startIcon="close" onClick={onClose} />}
            </DialogTitleWrap>
            <StyledDialogContent>
                {description && <BodyMedium id={`dialog-description-${randomId}`}>{description}</BodyMedium>}
                {children}
            </StyledDialogContent>

            <DialogActions>
                {cancelText && onCancel && (
                    <Button $size="small" $color="simple" startIcon={cancelIcon} onClick={onCancel}>
                        {cancelText}
                    </Button>
                )}
                {onConfirm && <Button $size="small" $color="simple" startIcon={confirmIcon} onClick={onConfirm}>
                    {confirmText}
                </Button>}
            </DialogActions>
        </Dialog>
    );
}
