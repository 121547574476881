import React from "react";
import styled from "@emotion/styled";
import { color } from "../constants/Styles";

const DefaultStyles = styled.div`
  button,
  a,
  input {
    &:focus {
      outline: ${color.accent} auto 2px;
    }
  }
  a {
    text-decoration: none;
  }
  * {
    font-family: "Archivo", system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
  }
  height: 100vh;
`;

export default DefaultStyles;
