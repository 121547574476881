import styled from "@emotion/styled";
import { LabelSmall } from "../../text/Typography";
import { color, typographySize } from "../../../constants/Styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconsButton from "../../buttons/IconsButton";
import TextButton from "../../buttons/TextButton";
import { IconName } from "../../images/IconMap";
import { breakpoint_small } from "../../../constants/breakpoints";
export interface IToolbarItem {
  title: string;
  icon?: IconName;
  onClick?: () => void;
  href?: string;
  target?: string;
}

export interface IToolbarProps {
  items?: IToolbarItem[];
  title?: string;
  withBackButton?: boolean;
  onBack?: () => boolean;
  children?: React.ReactNode;
}

const Toolbar: React.FC<IToolbarProps> = ({
  items,
  title,
  children,
  withBackButton,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = () => {
    if (onBack && !onBack()) return;
    navigate(-1);
  };
  return (
    <ToolbarWrapper className="page-toolbar">
      <LeftContent>
        {withBackButton && (
          <StyledIconsButton
            startIcon="back"
            onClick={goBack}
            label={t("common:back")}
            iconSize={20}
          />
        )}
      </LeftContent>
      <CenterContent>
        {children ? (
          children
        ) : (
          <StyledLabelSmall as="h1">{title}</StyledLabelSmall>
        )}
      </CenterContent>
      <ToolbarItemContainer>
        {items?.map((item, index) => {
          return (
            <TextButton
              startIcon={item.icon}
              key={`step_${index}`}
              href={item.href}
              onClick={item.onClick}
            >
              {item.title}
            </TextButton>
          );
        })}
      </ToolbarItemContainer>
    </ToolbarWrapper>
  );
};
const StyledLabelSmall = styled(LabelSmall)`
  margin: auto;
  font-size: ${typographySize.font_xlg};
  line-height: 2rem;
  ${breakpoint_small} {
    font-size: ${typographySize.font_md};
    line-height: 1.25rem;
  }
`;
const LeftContent = styled.div`
  flex: 0 0 auto;
  min-width: 60px;
`;
const CenterContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  overflow-y: auto;
`;

const ToolbarWrapper = styled.div`
  position: sticky;
  background: ${color.neutral_accent_2};
  display: flex;
  align-items: center;
  padding: 0.5rem 16px;
  height: 68px;
  gap: 0.5rem;
`;

const StyledIconsButton = styled(IconsButton)`
  & :hover {
    color: ${color.neutral_accent_2} !important;
  }
  svg {
    padding: 0.5rem;
  }
`;

const ToolbarItem = styled.button`
  color: ${color.bg_default};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    font-size: ${typographySize.button_lg_hover};
  }
`;

const ToolbarItemContainer = styled.div`
  flex: 0 0 auto;
  min-width: 60px;
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-left: auto;
`;

export default Toolbar;
