import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" viewBox="0 0 20 20" {...props}>
        <path
            fill="currentcolor"
            d="M13.125 8.125a3.125 3.125 0 1 0-6.25 0 3.125 3.125 0 0 0 6.25 0Zm-5 0a1.875 1.875 0 1 1 3.75 0 1.875 1.875 0 0 1-3.75 0Zm9.375 7.5h-6.875v-1.281a6.25 6.25 0 1 0-1.25 0v1.28H2.5a.625.625 0 0 0 0 1.25h15a.625.625 0 1 0 0-1.25ZM5 8.125a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
        />
    </svg>
);
export default SvgCamera;
