import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <rect
            width={33.6}
            height={56}
            fill="#FBFDFE"
            stroke="#141526"
            strokeLinecap="round"
            strokeOpacity={0.3}
            strokeWidth={1.92}
            rx={4}
            transform="scale(-1 1) rotate(-18.875 67.294 251.063)"
        />
        <path fill="#141526" fillOpacity={0.3} d="m64.988 35.185-9.157-3.13-.776 2.27 9.156 3.13z" />
        <rect
            width={33.6}
            height={56}
            fill="#FBFDFE"
            stroke="#141526"
            strokeLinecap="round"
            strokeWidth={2.4}
            rx={4}
            transform="matrix(-1 0 0 1 51.2 24.4)"
        />
        <path fill="#141526" d="M40 28.4H28.8v2.4H40z" />
        <path
            stroke="#E51546"
            strokeLinecap="round"
            strokeWidth={2.4}
            d="M80 21c-3.532-3.333-14.89-10-32.07-10C30.748 11 28.5 12.5 18 17.5"
        />
        <path stroke="#E51546" strokeLinecap="round" strokeWidth={2.4} d="m82 15.6-.4 7.2-7.6 1.6" />
    </svg>
);
export default SvgComponent;
