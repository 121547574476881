import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSwitchControl = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M50 77.353c15.107 0 27.353-12.246 27.353-27.353 0-15.107-12.246-27.353-27.353-27.353-15.107 0-27.353 12.246-27.353 27.353 0 15.107 12.246 27.353 27.353 27.353ZM50 80c16.569 0 30-13.431 30-30 0-16.568-13.431-30-30-30-16.568 0-30 13.432-30 30 0 16.569 13.432 30 30 30Z"
            clipRule="evenodd"
        />
        <path fill="#E51546" d="M72 50c0 12.15-9.85 22-22 22s-22-9.85-22-22 9.85-22 22-22 22 9.85 22 22Z" />
    </svg>
);
export default SvgSwitchControl;
