// TODO: update based on the feedback from Alex on how to check if a app device is connected

const handleCompanionAppPermission = async () => {
    return false;
};

const handleCompanionAppDevices = async () => {
    return [] as string[];
};

export { handleCompanionAppPermission, handleCompanionAppDevices };
