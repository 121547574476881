import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { IToolbarItem } from "./Toolbar";
import TextButton from "../../buttons/TextButton";
import { color } from "../../../constants/Styles";

export interface IFooterProps {
  items?: IToolbarItem[];
  variant?: "default" | "transparent";
}

function Footer({ items, variant = "default" }: IFooterProps) {
  const { t } = useTranslation();
  return (
    <FooterWrap $variant={variant}>
      <ButtonWrap>
        <FooterTextButton href="https://cephable.com/support/" target="_blank">
          {t("common:support.title")}
        </FooterTextButton>
        {items?.map((item, index) => (
          <FooterTextButton {...item} key={`step_${index}`}>
            {item.title}
          </FooterTextButton>
        ))}
      </ButtonWrap>
      <FooterTextButton href="https://cephable.com/download" target="_blank">
        Download Cephable
      </FooterTextButton>
    </FooterWrap>
  );
}

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FooterTextButton = styled(TextButton)`
  padding: 1rem 0;
  svg {
    display: none;
  }
`;

const FooterWrap = styled.footer<{ $variant: "default" | "transparent" }>`
  margin-top: auto;
  position: relative;
  margin-bottom: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background: ${(props) =>
    props.$variant === "transparent" ? "transparent" : color.neutral_accent_2};
  max-width: 100vw;
  gap: 1rem;
  min-height: 1.75rem;
  flex-wrap: wrap;
  display: flex;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: space-between;
`;

export default Footer;
