import styled from '@emotion/styled';
import { DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { color } from '../../constants/Styles';
import { Tag } from '../text/ContentTypography';

export const DisplayDialogTitle = styled(DialogTitle)`
    flex: 0 1 auto;
    padding: 0;
    margin: 0;
    align-self: center;
    font-weight: 900;
    font-size: 1.75rem; // 28px
    color: ${color.text_default};
    line-height: 2rem; // 32px
    word-break: break-word;
`;

export const DialogTitleWrap = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 0 1.5rem;
    gap: 0.5rem;
`;

export const StyledDialogContent = styled(DialogContent)`
    padding: 0.25rem 1.5rem 1.5rem 1.5rem;
`;

export const StyledDialogContentText = styled(DialogContentText)`
    padding: 0.25rem 1.5rem 1.5rem 1.5rem;
`;

export const StyledDialogTag = styled(Tag)`
    word-break: keep-all;
    font-size: 1rem; // 16px
    line-height: 1.25rem; // 20px
`;
