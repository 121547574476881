import common from "./en/common.json";
import error from "./en/error.json";
import i18next from "i18next";
import Pseudo from "i18next-pseudo";
import { initReactI18next } from "react-i18next";

export const defaultNS = "common";
export const resources = {
  en: {
    common,
    error,
  },
} as const;

// set to true to enable pseudo localization
const pseudoEnabled = false; // process.env.ENABLED_ENVIRONMENT == "dev";

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(
    new Pseudo({
      enabled: pseudoEnabled,
      letterMultiplier: 1,
    })
  )
  .use(initReactI18next)
  .init({
    returnNull: false,
    defaultNS: defaultNS,
    resources: resources,
    interpolation: {
      escapeValue: false,
    },
    debug: true,
    lng: "en",
    fallbackLng: "en",
    postProcess: ["pseudo"],
    partialBundledLanguages: true,
  });

export default i18next;
