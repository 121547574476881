import React from "react";
import logo from "./logo.svg";
import {
  color,
  colorPercent,
  typographyLineHeight,
  typographySize,
} from "./constants/Styles";
import { ThemeProvider, createTheme } from "@mui/material";
import DefaultStyles from "./components/DefaultStyles";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LandingPage from "./pages/landingPage/LandingPage";
import WritingPage from "./pages/writingPage/WritingPage";
import MediaPage from "./pages/mediaPage/MediaPage";
import GamePage2048 from "./pages/2048/GamePage2048";
import AlertsContainer from "./hooks/AlertsContainer";
import VoiceCommandsContainer from "./hooks/VoiceCommandsContainer";
import PeripheralsContainer from "./hooks/PeripheralsContainer";
import './locales/i18n';
export const theme = createTheme({
  palette: {
      text: {
          primary: color.dark_primary,
      },
      primary: {
          main: color.secondary,
      },
      secondary: {
          main: color.primary,
      },
      error: {
          main: color.error,
      },
  },
  typography: {
      fontFamily: 'Archivo, sans-serif',
  },
  components: {
      MuiInputLabel: {
          styleOverrides: {
              root: {
                  fontSize: '1rem', // "0.875rem
                  fontWeight: 400,
                  color: color.neutral,
              },
          },
      },
      MuiButtonBase: {
          defaultProps: {
              disableRipple: true,
          },
      },
      MuiDialog: {
          styleOverrides: {
              paper: {
                  borderRadius: '0.5rem',
              },
          },
      },
      MuiTooltip: {
          styleOverrides: {
              tooltip: {
                  fontSize: typographySize.font_sm,
                  lineHeight: typographyLineHeight.font_xs,
                  backgroundColor: colorPercent.dark_semi_transparent,
              },
          },
          defaultProps: {
              enterDelay: 800,
              leaveDelay: 200,
              enterNextDelay: 400,
          },
      },
      MuiRadio: {
          defaultProps: {
              disableRipple: true,
          },
      },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AlertsContainer.Provider>
        <VoiceCommandsContainer.Provider>
          <PeripheralsContainer.Provider>
            <DefaultStyles>
              <Router>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/2048" element={<GamePage2048 />} />
                  <Route path="/2048/:inputType" element={<GamePage2048 />} />
                  <Route path="/write" element={<WritingPage />} />
                  <Route path="/media" element={<MediaPage />} />
                </Routes>
              </Router>
            </DefaultStyles>
          </PeripheralsContainer.Provider>
        </VoiceCommandsContainer.Provider>
      </AlertsContainer.Provider>
    </ThemeProvider>
  );
}

export default App;
