import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVoice = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 90 100" fill="none" {...props}>
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="M80.088 44.766a1.784 1.784 0 0 1 2.347.927C90.33 63.915 78.85 78.52 72.238 83.512a1.784 1.784 0 0 1-2.15-2.849c5.995-4.524 16.021-17.515 9.073-33.55a1.784 1.784 0 0 1 .927-2.347Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M48.62 17.652a1.284 1.284 0 0 1 2.554.264c-.333 3.221.427 11.087 6.032 16.424 1.587 1.512 2.53 2.973 2.756 4.411.24 1.528-.367 2.774-1.3 3.684-.893.87-2.108 1.468-3.286 1.877-.687.238-1.397.424-2.084.564l-.381.077-.248 4.96a2.544 2.544 0 0 1-2.377 2.412l-9.392.606-.128.255a2.46 2.46 0 0 0-.125 1.972c.397 1.106 1.782 2.729 5.786 4.247l.094.036.088.05c.481.276.733.724.809 1.171a2.5 2.5 0 0 1-.044.933c-.052.24-.13.5-.2.733l-.032.105v.003c-.065.213-.13.428-.195.666-.292 1.073-.544 2.5-.087 4.33.423 1.69.314 3.834-.405 5.683-.726 1.866-2.164 3.625-4.514 4.095-1.065.213-3.077.27-5.504.111a52.624 52.624 0 0 1-8.56-1.28c-6.095-1.445-12.876-4.314-16.19-9.772a1.284 1.284 0 0 1 2.195-1.333c2.752 4.532 8.64 7.195 14.588 8.605a50.067 50.067 0 0 0 8.134 1.217c2.38.155 4.114.077 4.833-.067 1.215-.243 2.099-1.156 2.624-2.507.53-1.362.599-2.965.308-4.13-.602-2.406-.259-4.31.1-5.627.048-.177.097-.345.143-.5l.127-.424-.406-.177c-3.517-1.532-5.411-3.356-6.11-5.3-.79-2.198.092-4.132 1.098-5.215l.348-.375 10.43-.673.354-7.077 1.078-.127c.936-.11 2.013-.33 3.003-.674 1.008-.35 1.824-.792 2.335-1.29.223-.217.391-.443.487-.697.099-.261.106-.511.068-.75-.098-.628-.58-1.605-1.99-2.948-6.385-6.082-7.195-14.869-6.815-18.548Z"
            clipRule="evenodd"
        />
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="M57.636 52.088a1.784 1.784 0 0 1 2.489.415c2.51 3.514 2.493 6.846 1.234 9.53-1.19 2.534-3.397 4.314-5.048 5.209a1.784 1.784 0 0 1-1.7-3.138c1.204-.652 2.745-1.942 3.517-3.588.703-1.497.865-3.459-.907-5.94a1.784 1.784 0 0 1 .415-2.488ZM69.444 47.952a1.784 1.784 0 0 1 2.31 1.014c4.98 12.762-2.88 22.648-7.378 25.99a1.784 1.784 0 0 1-2.128-2.865c3.828-2.844 10.337-11.18 6.182-21.828a1.784 1.784 0 0 1 1.014-2.31Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgVoice;
