import styled from "@emotion/styled";
import { IContainerProps } from "../../../models/components/IContainerProps";

const ToolbarPageContainerWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 164px);
`;
const Content = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  animation: float 0.25s ease-in-out;
  @keyframes float {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ToolbarPageContainer = ({ children }: IContainerProps) => {
  return (
    <ToolbarPageContainerWrapper className="toolbar-page-container">
      <Content>{children}</Content>
    </ToolbarPageContainerWrapper>
  );
};

export default ToolbarPageContainer;
