import styled from '@emotion/styled';
import { color, gradientRight, transition, typographySize, typographyWeight } from '../../constants/Styles';

// TODO: Finish depreciation in favor of IconsButton.tsx

const IconButton = styled.button`
    font-weight: ${typographyWeight.button_default};
    font-size: ${typographySize.button_default};
    color: ${color.bg_default};
    text-align: center;
    background: ${gradientRight.primary};
    height: 54px;
    width: 54px;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    transition: ${transition.default};
    img {
        height: 32px;
        width: 32px;
    }
    &:hover {
        font-size: 1.1em;
        background: ${gradientRight.primary_inverted};
    }
`;

export const IconButtonLink = styled.a`
    font-weight: ${typographyWeight.button_default};
    font-size: ${typographySize.button_default};
    color: ${color.bg_default};
    text-align: center;
    background: ${gradientRight.primary};
    height: 54px;
    width: 54px;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 25px;
        width: 25px;
    }
    &:hover {
        font-size: ${typographySize.button_hover};
        background: ${gradientRight.primary_inverted};
    }
`;

export default IconButton;
