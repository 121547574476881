import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMotionControl1 = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <rect
            width={33.6}
            height={56}
            x={22.4}
            y={35.269}
            fill="#FBFDFE"
            stroke="currentColor"
            strokeLinecap="round"
            strokeOpacity={0.3}
            strokeWidth={1.92}
            rx={4}
            transform="rotate(-18.875 22.4 35.27)"
        />
        <path fill="currentColor" fillOpacity={0.3} d="m35.012 35.185 9.157-3.13.776 2.27-9.157 3.131z" />
        <rect
            width={33.6}
            height={56}
            x={48.8}
            y={24.4}
            fill="#FBFDFE"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={2.4}
            rx={4}
        />
        <path fill="currentColor" d="M60 28.4h11.2v2.4H60z" />
        <path
            stroke="#E51546"
            strokeLinecap="round"
            strokeWidth={2.4}
            d="M20 21c3.532-3.333 14.89-10 32.07-10C69.252 11 71.5 12.5 82 17.5"
        />
        <path stroke="#E51546" strokeLinecap="round" strokeWidth={2.4} d="m18 15.6.4 7.2 7.6 1.6" />
    </svg>
);
export default SvgMotionControl1;
