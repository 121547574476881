import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHeadTurning = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <g clipPath="url(#Head-Turning_svg__a)">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeOpacity={0.3}
                strokeWidth={2.16}
                d="M41.407 30.004c-12.382-4.311-21.141 1.038-25.315 6.123-1.655 2.017-2.154 4.667-2.206 7.275l-.077 3.888a6.918 6.918 0 0 1-1.36 3.983l-5.276 7.118c-1.358 1.831.019 4.419 2.297 4.315a2.706 2.706 0 0 1 2.789 2.24l.473 2.724.945 4.535c.162.78.4 1.543.708 2.278l.672 1.598a8.52 8.52 0 0 0 6.415 5.098l.701.12c1.065.183 2.145.264 3.225.244l.056-.001a17.716 17.716 0 0 0 5.158-.872l3.954-1.29a25.2 25.2 0 0 0 14.347-11.97l5.657-10.462c1.016-1.878 1.872-3.879 1.922-6.014.154-6.571-3.086-16.752-15.085-20.93Z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeOpacity={0.3}
                strokeWidth={2.16}
                d="M58.843 30.004c12.382-4.311 21.141 1.038 25.314 6.123 1.656 2.017 2.155 4.667 2.207 7.275l.077 3.888a6.918 6.918 0 0 0 1.36 3.983l5.276 7.118c1.358 1.831-.019 4.419-2.297 4.315a2.706 2.706 0 0 0-2.79 2.24l-.472 2.724-.945 4.535c-.162.78-.4 1.543-.708 2.278l-.672 1.598a8.52 8.52 0 0 1-6.415 5.098l-.702.12a17.133 17.133 0 0 1-3.224.244l-.056-.001a17.715 17.715 0 0 1-5.158-.872l-3.955-1.29a25.2 25.2 0 0 1-14.346-11.97L45.68 56.948c-1.016-1.878-1.872-3.879-1.922-6.014-.154-6.571 3.086-16.752 15.084-20.93Z"
            />
            <path
                fill="#FBFDFE"
                d="m61.186 76.942-1.95 1.48a16.73 16.73 0 0 1-9.586 3.396 16.73 16.73 0 0 1-9.587-3.396l-1.95-1.48a24.002 24.002 0 0 1-9.18-15.268l-2.166-13.336c-.343-2.114-.493-4.287.15-6.33 2.065-6.556 8.786-15.622 22.205-15.622 14.377 0 21.19 9.066 23.261 15.622.645 2.042.493 4.216.15 6.33l-2.167 13.335a24.002 24.002 0 0 1-9.18 15.27Z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth={2.7}
                d="M49.122 81.826v0c3.653 0 7.205-1.195 10.115-3.404l1.95-1.48a24.002 24.002 0 0 0 9.179-15.268l2.167-13.336c.343-2.114.495-4.288-.15-6.33-2.07-6.556-8.884-15.622-23.26-15.622-13.42 0-20.141 9.066-22.206 15.622-.643 2.043-.493 4.216-.15 6.33l2.167 13.335a24.002 24.002 0 0 0 9.18 15.27l1.95 1.48a16.73 16.73 0 0 0 10.114 3.403v0"
            />
            <path
                fill="#E51546"
                d="M3.293 15.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L5.414 16l5.657-5.657A1 1 0 1 0 9.657 8.93l-6.364 6.364Zm93.414 1.414a1 1 0 0 0 0-1.414l-6.364-6.364a1 1 0 0 0-1.414 1.414L94.586 16l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM4 17h92v-2H4v2Z"
            />
        </g>
        <defs>
            <clipPath id="Head-Turning_svg__a">
                <path fill="#fff" d="M0 0h100v100H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgHeadTurning;
