import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { boxShadow, color, gradientRight, typographySize, typographyWeight } from '../../constants/Styles';
import { ReactNode } from 'react';
import { IconName } from '../images/IconMap';
import Icons from '../images/Icons';

// Define the button's type
type ButtonType = 'button' | 'submit' | 'reset';

// Define the button's color options
type ButtonColor = 'primary' | 'secondary' | 'accent' | 'simple' | 'quick';

// Define the button's size options
type ButtonSize = 'small' | 'medium' | 'large' | 'quick';

// Define the button's props
export interface ICoreButtonProps {
    $color?: ButtonColor;
    $size?: ButtonSize;
    disabled?: boolean;
    iconSize?: number;
    startIcon?: IconName;
    endIcon?: IconName;
    children: ReactNode;
    target?: string;
    href?: string;
    type?: ButtonType;
    onClick?: (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.KeyboardEvent<HTMLButtonElement>
            | React.TouchEvent<HTMLButtonElement>
    ) => void;
}

// Create the Button component
function Button({
    type = 'button',
    $color = 'primary',
    $size = 'medium',
    disabled = false,
    onClick,
    href,
    target,
    iconSize = 24,
    startIcon,
    endIcon,
    children,
    ...restProps
}: ICoreButtonProps) {
    const commonProps = {
        $color,
        $size,
        disabled,
        target,
    };

    endIcon = target === '_blank' && href?.startsWith('http') ? 'externalLink' : endIcon;

    if (href) {
        return (
            <StyledLink className="core-button" href={href} {...commonProps} {...restProps}>
                {startIcon && <Icons name={startIcon} size={iconSize} />}
                {children}
                {endIcon && <Icons name={endIcon} size={iconSize} />}
            </StyledLink>
        );
    } else {
        return (
            <StyledButton className="core-button" type={type} onClick={onClick} {...commonProps} {...restProps}>
                {startIcon && <Icons name={startIcon} size={iconSize} />}
                {children}
                {endIcon && <Icons name={endIcon} size={iconSize} />}
            </StyledButton>
        );
    }
}

// Define button styles
const buttonBaseStyles = css`
    text-align: center;
    border-radius: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    padding: 0 1rem;
    gap: 0.5rem;
    @media (prefers-reduced-motion: no-preference) {
        transition: all 0.1s ease-out;
        & * {
            transition: ease-out 0.1s;
        }
    }

    transform-origin: center;
    :focus-visible {
        outline: ${color.accent} 3px solid;
    }
`;
const StyledButton = styled.button<ICoreButtonProps>`
    ${buttonBaseStyles}
    ${(props) => props.$color === 'primary' && primaryButtonStyles}
    ${(props) => props.$color === 'secondary' && secondaryButtonStyles}
    ${(props) => props.$color === 'accent' && accentButtonStyles}
    ${(props) => props.$color === 'simple' && simpleButtonStyles}
    ${(props) => props.$size === 'small' && smallButtonStyles}
    ${(props) => props.$color === 'quick' && quickButtonStyles}
    ${(props) => props.$size === 'medium' && mediumButtonStyles}
    ${(props) => props.$size === 'large' && largeButtonStyles}
    ${(props) => props.disabled && disabledButtonStyles} /* Add size-specific styles here */
`;
const StyledLink = styled.a<ICoreButtonProps>`
    ${buttonBaseStyles}
    ${(props) => props.$color === 'primary' && primaryButtonStyles}
    ${(props) => props.$color === 'secondary' && secondaryButtonStyles}
    ${(props) => props.$color === 'accent' && accentButtonStyles}
    ${(props) => props.$color === 'simple' && simpleButtonStyles}
    ${(props) => props.$color === 'quick' && quickButtonStyles}
    ${(props) => props.$size === 'small' && smallButtonStyles}
    ${(props) => props.$size === 'medium' && mediumButtonStyles}
    ${(props) => props.$size === 'large' && largeButtonStyles}
    ${(props) => props.disabled && disabledButtonStyles}
`;

const smallButtonStyles = css`
    font-size: 1rem;
    min-height: 2.5rem;
    padding: 0 1.12rem;
`;

const mediumButtonStyles = css`
    font-size: ${typographySize.button_default};
    min-width: 240px;
    max-width: fit-content;
    padding: 1.12rem;
`;

const largeButtonStyles = css`
    font-size: ${typographySize.button_lg};
    width: 100%;
    padding: 1.12rem;
`;

// Define primary button styles
const primaryButtonStyles = css`
    font-weight: 700;
    background: ${color.dark_primary};
    background-image: linear-gradient(54deg, #062844 4.52%, #4b2b8e 59.43%, #ee486f 125.41%);
    text-decoration: none;
    border: 1px solid ${color.light_secondary};
    color: ${color.bg_default};
    &:hover {
        svg {
            color: ${color.primary};
        }
        background: ${color.dark_primary};
    }
`;

// Define secondary button styles
const secondaryButtonStyles = css`
    font-weight: 700;
    text-decoration: none;
    background: ${color.bg_default};
    border: solid 1px ${color.light_secondary};
    color: ${color.text_default};
    &:hover {
        svg {
            color: ${color.primary};
        }
        color: ${color.bg_default};
        background: ${color.text_default};
    }
`;

const accentButtonStyles = css`
    background: ${color.bg_default};
    color: ${color.text_default};
    text-decoration: none;
    border: transparent 1px solid;
    font-weight: 700;
    &:hover {
        border: ${color.light_secondary} 1px solid;

        background: ${color.text_default};
        color: ${color.bg_default};
        svg {
            color: ${color.primary};
        }
    }
`;

const simpleButtonStyles = css`
    background: ${color.bg_default};
    color: ${color.text_default};
    text-decoration: underline !important;
    text-decoration-color: ${color.text_default};
    text-underline-offset: 0.5rem;
    border: none;
    &:hover {
        text-decoration-color: ${color.bg_default} !important;
        filter: none;
        box-shadow: 0px 0px 0px 1px ${color.text_default};
    }
`;
const quickButtonStyles = css`
    padding: 0.625rem 0.875rem;
    border-radius: 1.875rem;
    background: ${color.bg_default};
    color: ${color.neutral};
    text-decoration: none;
    border: ${color.border} 1px solid;
    font-weight: 400;
    &:hover {
        border: ${color.light_secondary} 1px solid;

        background: ${color.text_default};
        color: ${color.bg_default};
        svg {
            color: ${color.primary};
        }
    }
`;

// Define disabled button styles
const disabledButtonStyles = css`
    cursor: not-allowed;
    background: #ccc;
    color: #666;
    &:hover {
        background: #ccc;
    }
`;

export default Button;
