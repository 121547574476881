import styled from "@emotion/styled";
import AlertsContainer from "../../hooks/AlertsContainer";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Tooltip } from "@mui/material";
import Button from "../buttons/CoreButton";
import IconButton from "../buttons/IconButton";
import Icons from "../images/Icons";
import NotifyError from "../structure/NotifyError";
import { color, colorPercent, typographySize } from "../../constants/Styles";
import { createFromHumanFace } from "../../hooks/expressionDetection/FaceMapping";
import { detectCommandsFromFace } from "../../hooks/expressionDetection/getCommandsFromFace";
import { PermissionState } from "../../models/devices/DevicePermissionState";
import FaceExpression from "../../models/expressionDetection/FaceExpression";
import HumanFace from "../../models/expressionDetection/HumanFace";
import { Trans, useTranslation } from "react-i18next";
import * as humanEsm from "../../models/expressionDetection/human/human.esm";
import ExpressionSettingsModel from "../../models/devices/ExpressionSettingsModel";
import { breakpoint_small } from "../../constants/breakpoints";

const humanConfig = {
  // user configuration for human
  modelBasePath: "https://models.cephable.com/vision/", // load models from cdn
  debug: false,
  filter: { enabled: false },
  face: {
    enabled: true,
    detector: {
      enabled: true,
      rotation: true,
      maxDetected: 1,
      minSize: 50,
      iouThreshold: 0.01,
      minConfidence: 0.2,
    },
    mesh: { enabled: true },
    iris: { enabled: false }, // for now...
    description: { enabled: false },
    emotion: { enabled: false },
  },
  body: { enabled: false }, // for now...
  hand: { enabled: false }, // for now...
  object: { enabled: false },
  gesture: { enabled: false }, // for now...
};
// const drawOptions = {
//     faceLabels: `
//     distance: [distance]cm
//     roll: [roll]° yaw:[yaw]° pitch:[pitch]°`,
// }

const drawOptions = {
  faceLabels: "",
  color: colorPercent.primary_50,
  useDepth: false,
  alpha: 0.5,
};
const defaultExpressionSettings: ExpressionSettingsModel = {
  leanBack: 50,
  leanForward: 50,
  leanLeft: 50,
  leanRight: 50,
  moveUp: 50,
  moveDown: 50,
  headTiltDown: 50,
  headTiltUp: 50,
  headTurnLeft: 50,
  headTurnRight: 50,
  headTiltLeft: 50,
  headTiltRight: 50,
  smiling: 50,
  eyebrowRaise: 50,
  mouthClose: 50,
  mouthOpen: 50,
  mouthPucker: 50,
  isAutoStart: false,
};

const timestamp = { detect: 0, draw: 0, tensors: 0 }; // holds information used to calculate performance and possible memory leaks
const fps = { detect: 0, draw: 0 }; // holds calculated fps information for both detect and screen refresh

let lastFace: HumanFace | null = null;
let lastFaceExpression: FaceExpression | null = null;
let baseline: HumanFace | null = null;
let baselineFaceExpression: FaceExpression | null = null;
let previousFaceCommands: string[] | null = null;
let previousMouseMove: boolean | null = null;
let dom: { video: HTMLVideoElement; canvas: HTMLCanvasElement } | null = null;
let stream: MediaStream | null = null;
let isDrawing =
  localStorage.getItem("isExpressionDrawing") ?? "true" === "true";
let stop = false;
let recordedFaces = [] as HumanFace[];
let isRecordingFaces = false;
let currentSensitivity = 50;
let human;

interface CameraControlsProps {
  onCommandsDetected: (commands: string[]) => void;
  sensitivity?: number,
  useBaselineCountdown: boolean;
}

export interface CameraControlsRef {
  updateBaseline: () => void;
}

const CameraControls = forwardRef<CameraControlsRef, CameraControlsProps>(({
  onCommandsDetected,
  sensitivity,
  useBaselineCountdown,
}, ref) => {
  currentSensitivity = sensitivity ?? 50;
  const { t } = useTranslation();
  const alertContainer = AlertsContainer.useContainer();
  const [isFaceDetected, setIsFaceDetected] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const [countdown, setCountdown] = useState(0);
  const [checkPermissions, setCheckPermissions] = useState<PermissionState>(
    PermissionState.Loading
  );
  const [availableCameraCount, setAvailableCameraCount] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const pauseRef = useRef(isPaused);
  useImperativeHandle(ref, () => ({
    updateBaseline() {
      handleUpdateBaseline();
    }
  }));
  const getExpressionSettings = () => {
    if (currentSensitivity != null) {
      return {
        leanBack: currentSensitivity,
        leanForward: currentSensitivity,
        leanLeft: currentSensitivity,
        leanRight: currentSensitivity,
        moveUp: currentSensitivity,
        moveDown: currentSensitivity,
        headTiltDown: currentSensitivity,
        headTiltUp: currentSensitivity,
        headTurnLeft: currentSensitivity,
        headTurnRight: currentSensitivity,
        headTiltLeft: currentSensitivity,
        headTiltRight: currentSensitivity,
        smiling: currentSensitivity,
        eyebrowRaise: currentSensitivity,
        mouthClose: currentSensitivity,
        mouthOpen: currentSensitivity,
        mouthPucker: currentSensitivity,
        isAutoStart: true,
      };
    }
    return defaultExpressionSettings;
  }

  const options: MediaStreamConstraints = {
    audio: false,
    video: {
      facingMode: "user",
      // @ts-ignore resizeMode is not yet defined in tslib
      resizeMode: "none",
    },
  };


  async function handleNextCamera() {
    // change the stream to the next camera
    const preferredIndex = localStorage.getItem("preferredCameraIndex") ?? "0";
    let videoDevices = await navigator.mediaDevices.enumerateDevices();
    videoDevices = videoDevices.filter((d) => d.kind === "videoinput");
    const nextIndex = (parseInt(preferredIndex) + 1) % videoDevices.length;
    const preferredCameraId = videoDevices[nextIndex].deviceId;
    localStorage.setItem("preferredCameraId", preferredCameraId);
    localStorage.setItem("preferredCameraIndex", nextIndex.toString());
    await webCam(dom, videoDevices[nextIndex].deviceId);
    baseline = null;
    baselineFaceExpression = null;
  }
  async function tryPreferredCamera() {
    const preferredCameraId = localStorage.getItem("preferredCameraId");
    if (preferredCameraId) {
      const videoDevices = await navigator.mediaDevices.enumerateDevices();
      const preferredCamera = videoDevices.find(
        (d) => d.deviceId === preferredCameraId
      );
      if (!preferredCamera) {
        localStorage.removeItem("preferredCameraId");
      } else {
        return preferredCameraId;
      }
    }
    return null;
  }
  async function detectionLoop() {
    // main detection loop
    if (dom != null && !dom.video.paused && !pauseRef.current) {
      await human.detect(dom.video); // actual detection; were not capturing output in a local variable as it can also be reached via human.result

      const newFace = human.result?.face?.[0] as HumanFace;
      if (newFace) {
        setIsFaceDetected(true);
        if (isRecordingFaces) {
          recordedFaces.push(newFace);
        }
        const newFaceExpression = createFromHumanFace(newFace);
        const commandResults = detectCommandsFromFace(
          newFaceExpression,
          baselineFaceExpression,
          getExpressionSettings(),
          previousFaceCommands
        );
        const commands = commandResults?.commands ?? [];

        if (commands?.length > 0) {
          onCommandsDetected(commands);
        } else {
          previousMouseMove = false;
        }
        previousFaceCommands = commandResults?.previousFaceCommands ?? [];
        lastFace = newFace;
        lastFaceExpression = newFaceExpression;
        if (!baseline) {
          baselineFaceExpression = createFromHumanFace(lastFace);
          baseline = lastFace;
        }
      } else {
        isFaceDetected && setIsFaceDetected(false);
      }
      const tensors = human.tf.memory().numTensors; // check current tensor usage for memory leaks
      // if (tensors - timestamp.tensors !== 0) console.log('allocated tensors:', tensors - timestamp.tensors); // printed on start and each time there is a tensor leak
      timestamp.tensors = tensors;
    }
    const now = human.now();
    fps.detect = 1000 / (now - timestamp.detect);
    timestamp.detect = now;
    if (!stop) requestAnimationFrame(detectionLoop); // start new frame immediately


  }
  async function drawLoop() {
    if (dom != null && !dom.video.paused) {
      const interpolated = await human.next(human.result); // smoothen result using last-known results

      await human.draw.canvas(dom.video, dom.canvas); // draw canvas to screen
      if (isDrawing) {
        if (baseline) {
          await human.draw.face(dom.canvas, [baseline], {
            ...drawOptions,
            color: colorPercent.accent_20,
            lineWidth: 2,
            alpha: 0.2,
            useDepth: false,
          });
        }
        await human.draw.all(dom.canvas, interpolated, drawOptions); // draw labels, boxes, lines, etc.
      }

    }
    const now = human.now();
    fps.draw = 1000 / (now - timestamp.draw);
    timestamp.draw = now;
    // requestAnimationFrame(drawLoop); // refresh at screen refresh rate
    if (!stop) setTimeout(drawLoop, 30); // use to slow down refresh from max refresh rate to target of 30 fps
  }
  async function webCam(
    dom: { video: HTMLVideoElement; canvas: HTMLCanvasElement },
    cameraId?: string
  ) {
    // initialize webcam
    const preferredCameraId = cameraId ?? (await tryPreferredCamera());
    if (preferredCameraId) {
      options.video = { deviceId: { exact: preferredCameraId } };
    }


    stream = await navigator.mediaDevices.getUserMedia(options);
    const ready = new Promise((resolve) => {
      dom.video.onloadeddata = () => resolve(true);
    });


    dom.video.srcObject = stream;
    dom.video.play();

    await ready;
    dom.canvas.width = dom.video.videoWidth;
    dom.canvas.height = dom.video.videoHeight;
    const track: MediaStreamTrack = stream.getVideoTracks()[0];
  }
  async function checkMediaPermissions() {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setCheckPermissions(PermissionState.Approved);
    } catch (e) {
      setCheckPermissions(PermissionState.Denied);
    }
  }

  function initializeHuman() {
    human.init().then(() => {
      // initialize human
      human.load().then(() => {
        // load models
        human.warmup().then(() => {
          // warmup models
          webCam(dom).then(() => {
            // initialize webcam
            detectionLoop().then(() => {
              // start detection loop
              drawLoop().then(() => {
                // start screen refresh loop
                setIsReady(true);
              });
            });
          }).catch((e) => {
            console.log("error initializing webcam", e)
          });
        });
      });
    });
  }

  useEffect(() => {
    human = new (humanEsm as any).Human(humanConfig); // create instance of human with overrides from user configuration
  }, []);
  useEffect(() => {
    if (isReady && checkPermissions === PermissionState.Approved) {
      isFaceDetected
        ? alertContainer.showSuccess(t("common:camera.detected.face"))
        : alertContainer.showError(t("common:camera.detected.noFace"));
    }
  }, [isFaceDetected, isReady]);

  useEffect(() => {
    checkMediaPermissions();
    if (checkPermissions === PermissionState.Approved) {
      // @ts-ignore resizeMode is not yet defined in tslib
      navigator.mediaDevices.enumerateDevices().then((videoDevices) => {
        setAvailableCameraCount(
          videoDevices?.filter((d) => d.kind === "videoinput")?.length ?? 0
        );
      });
      stop = false;
      dom = {
        // grab instances of dom objects so we dont have to look them up later
        video: document.getElementById("video") as HTMLVideoElement,
        canvas: document.getElementById("canvas") as HTMLCanvasElement,
      };

      initializeHuman();
      return () => {
        stop = true;
        // stop camera and human
        dom?.video?.pause();
        if (stream) stream.getTracks().forEach((track) => track.stop());

        lastFace = null;
        lastFaceExpression = null;
        baselineFaceExpression = null;
        baseline = null;
        dom = null;
        stream = null;
      };
    } else {
      console.log("no permissions");
    }
  }, [checkPermissions]);

  const updateBaseline = () => {
    if (lastFace) {
      baselineFaceExpression = lastFaceExpression;
      baseline = lastFace;
      alertContainer.showSuccess(t("common:camera.baseline.updated"));
    }

  }
  const handleUpdateBaseline = async () => {
    if (useBaselineCountdown) {
      setCountdown(3);
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            updateBaseline();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      updateBaseline();
    }

  };

  const handleToggleDraw = () => {
    localStorage.setItem("isExpressionDrawing", isDrawing ? "true" : "false");
    isDrawing = !isDrawing;
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
    pauseRef.current = !pauseRef.current;
    if (pauseRef.current) {
      alertContainer.showSuccess(t("common:camera.controls.paused"));
    }
  };

  return (
    <ControlsContainer>
      <ContentContainer>
        {checkPermissions === PermissionState.Denied ? (
          <NotifyError errorAlert={t("common:camera.permission.no")}>
            <Trans>{t("common:camera.permission.help")}</Trans>
          </NotifyError>
        ) : (
          <>
            {!isReady && (
              <LoadingLabel>{t("common:camera.loading")}</LoadingLabel>
            )}
          </>
        )}
        <VideoCanvas id="canvas"></VideoCanvas>
        <HiddenVideo id="video" playsInline></HiddenVideo>
        {countdown > 0 && <CountdownLabel>{countdown}</CountdownLabel>}

      </ContentContainer>
      {isReady && (
        <BottomVideoButtonBar>
          <Tooltip title={t("common:camera.baseline.tooltip")}>
            <IconButton onClick={handleUpdateBaseline}>
              <Icons
                name="sync"
                ariaLabel={t("common:camera.baseline.update")}
                size={32}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              isPaused
                ? t("common:camera.controls.resume")
                : t("common:camera.controls.pause")
            }
          >
            <IconButton onClick={togglePause}>
              {isPaused ? (
                <Icons name="play" ariaLabel={t("common:play")} size={32} />
              ) : (
                <Icons name="pause" ariaLabel={t("common:pause")} size={32} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              isDrawing
                ? t("common:camera.controls.linesOff")
                : t("common:camera.controls.linesOn")
            }
          >
            <IconButton onClick={handleToggleDraw}>
              <Icons
                name="expression"
                ariaLabel={t("common:camera.controls.linesToggle")}
                size={32}
              />
            </IconButton>
          </Tooltip>
          {availableCameraCount > 0 && (
            <Tooltip title={t("common:camera.switch.nextLong")}>
              <IconButton onClick={handleNextCamera}>
                <Icons
                  name="camera"
                  ariaLabel={t("common:camera.switch.next")}
                  size={32}
                />
              </IconButton>
            </Tooltip>
          )}
        </BottomVideoButtonBar>
      )}
    </ControlsContainer>
  );
});

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${breakpoint_small} {
    flex-direction: row;
    gap: 16px;
    margin-top: 0;
  }
`;

const BottomVideoButtonBar = styled.div`
  display: flex;
  align-items: center;

  gap: 8px;
  z-index: 100;

  ${breakpoint_small} {
    max-width: 50%;
    flex-wrap: wrap;
  }
`;
const CountdownLabel = styled.p`
  color: ${color.bg_default};
  text-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  font-size: 8rem;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height:100%;
  z-index: 3;

`;
const LoadingLabel = styled.p`
  font-size: ${typographySize.font_xlg};
  text-align: center;
  margin: 128px 32px;
`;
const VideoCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
  transform: scaleX(-1);

  ${breakpoint_small} {
    position: absolute;
    width: 50vw;
    height: calc(50vh - 64px);
    scale: 0.5;
    margin-top: -20%; // we scale down the image so we need to bump it back up
  }
`;

const HiddenVideo = styled.video`
  display: none;
`;
const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  ${breakpoint_small} {
    position: inherit;
  }
`;

export default CameraControls;
