import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEyebrowRaise = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <path
            fill="#FBFDFE"
            d="m63.321 77.626-2.384 1.81a20.461 20.461 0 0 1-11.726 4.152 20.462 20.462 0 0 1-11.725-4.153l-2.385-1.81a29.356 29.356 0 0 1-11.227-18.674l-2.65-16.311c-.42-2.585-.604-5.243.183-7.741 2.526-8.02 10.747-19.107 27.159-19.107 17.584 0 25.917 11.088 28.45 19.107.789 2.497.603 5.156.183 7.741l-2.65 16.31A29.357 29.357 0 0 1 63.32 77.627Z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M48.566 17.442c-15.5 0-23.212 10.42-25.584 17.953-.668 2.12-.537 4.464-.128 6.98l2.65 16.31A27.706 27.706 0 0 0 36.1 76.31l2.384 1.81a18.81 18.81 0 0 0 10.727 3.816 18.81 18.81 0 0 0 10.727-3.816l2.385-1.81a27.705 27.705 0 0 0 10.595-17.625l2.65-16.31c.41-2.517.543-4.861-.126-6.98-2.363-7.48-10.157-17.953-26.876-17.953Zm1.771 67.736a22.112 22.112 0 0 0 11.598-4.428l2.384-1.81a31.008 31.008 0 0 0 11.86-19.725l2.65-16.31c.43-2.653.67-5.628-.238-8.504-2.704-8.557-11.577-20.26-30.025-20.26-17.324 0-26.055 11.756-28.734 20.262-.906 2.875-.67 5.848-.238 8.502l2.65 16.31a31.007 31.007 0 0 0 11.86 19.725l2.383 1.81c3.374 2.56 7.4 4.091 11.598 4.429.152.046.313.07.48.07.216 0 .431-.002.646-.009.215.007.43.01.646.01.167 0 .328-.025.48-.072Z"
            clipRule="evenodd"
        />
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="M38.956 36.628c-1.327.051-2.902.893-4.966 2.958a1.651 1.651 0 0 1-2.336-2.336c2.217-2.216 4.556-3.82 7.174-3.922 2.69-.104 5.114 1.387 7.452 3.985a1.651 1.651 0 1 1-2.455 2.21c-2.064-2.295-3.615-2.944-4.87-2.895ZM58.525 36.628c-1.327.051-2.902.893-4.966 2.958a1.651 1.651 0 0 1-2.335-2.336c2.216-2.216 4.555-3.82 7.173-3.922 2.69-.104 5.114 1.387 7.452 3.985a1.651 1.651 0 0 1-2.454 2.21c-2.065-2.295-3.616-2.944-4.87-2.895Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgEyebrowRaise;
