import Button from "../buttons/CoreButton";
import Icons, { IIconsProps } from "../images/Icons";
import SrOnlyText from "../text/SrOnlyText";
import { BodySmall, TitleSmall } from "../text/Typography";
import useReduceMotion from "../../hooks/useReducedMotion";
import { ICoreCardProps } from "../../models/components/ICoreCardProps";
import faceControls from "../animation/faceControls.json";
import headMovement from "../animation/headMovement.json";
import motionControls from "../animation/motionControls.json";
import virtualButtons from "../animation/virtualButtons.json";
import voiceControls from "../animation/voiceControls.json";
import { useLottie } from "lottie-react";
import { useTranslation } from "react-i18next";
import {
  ButtonWrap,
  CardSmall,
  IconWrap,
  StatusChip,
  TitleContext,
  WrapCardText,
} from "./CommonCardStyles";
/**
 * This is a card that is used for the explore page
 * It is opinionated and has specific language based on the availability of the peripherals.
 */

interface ICardInputExploreProps extends IIconsProps, ICoreCardProps {
  available?: boolean;
  requirement?: string;
  animate?: "voice" | "head" | "expression" | "virtualButton" | "motion";
  onClick: () => void;
}

const CardInputExplore: React.FC<ICardInputExploreProps> = ({
  title,
  available,
  animate,
  requirement,
  onClick,
  description,
  name,
  size = 100,
  children,
  ...restProps
}) => {
  const { t } = useTranslation();
  const isReducedMotion = useReduceMotion();

  const animationData = {
    head: headMovement,
    voice: voiceControls,
    expression: faceControls,
    virtualButton: virtualButtons,
    motion: motionControls,
  };
  const style = {
    height: 90,
  };
  const options = {
    animationData: animationData[animate],
    loop: false,
    autoplay: true,
  };
  const Lottie = useLottie(options, style);

  return (
    <>
      <CardSmall
        onMouseEnter={() => Lottie.goToAndPlay(0)}
        onMouseLeave={() => Lottie.stop()}
      >
        {available && (
          <StatusChip>{t("common:peripherals.available")}</StatusChip>
        )}
        <IconWrap>
          {animate && !isReducedMotion ? (
            Lottie.View
          ) : (
            <Icons name={name} size={size} {...restProps} />
          )}
        </IconWrap>
        <WrapCardText>
          <TitleSmall>{title}</TitleSmall>
          <TitleContext>{requirement}</TitleContext>
          <BodySmall>{description}</BodySmall>
          {children}
          <ButtonWrap>
            <Button $size="small" onClick={onClick}>
              {available ? (
                <>{t("common:input.try", "Try input", { title })}</>
              ) : (
                <>
                  {t("common:learnMore")} <SrOnlyText>{title}</SrOnlyText>
                </>
              )}
            </Button>
          </ButtonWrap>
        </WrapCardText>
      </CardSmall>
    </>
  );
};

export default CardInputExplore;
