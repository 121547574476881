import { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentDialog from "../../../components/dialog/ContentDialog";
import CardStack from "../../../components/card/CardStack";
import Button from "../../../components/buttons/CoreButton";
import HelpScreenshot from "./HelpScreenshot";
import MobileHelpVideo from "./MobileHelpVideo";

interface ButtonHelpDialogProps {
    open: boolean;
    onClose: () => void;
}
const GameButtonHelpDialog: React.FC<ButtonHelpDialogProps> = ({
    open,
    onClose,
}) => {
    const { t } = useTranslation();
   
    return (
        <ContentDialog
            title={t('common:2048.hotkeys.help.title')}
            open={open}
            onClose={onClose}>


            <CardStack
                title={t('common:2048.hotkeys.help.howToPlay.title')}
                description={t('common:2048.hotkeys.help.howToPlay.description')}>
               <MobileHelpVideo src="/media/try-buttons.mp4" loop autoPlay aria-label={t('common:2048.hotkeys.help.howToPlay.videoAlt')}/>
            </CardStack>
            <CardStack
                title={t('common:2048.hotkeys.help.hotkeys.title')}
                description={t('common:2048.hotkeys.help.hotkeys.description')}>
               <HelpScreenshot src="/media/mobile-buttons-1.jpeg" alt={t('common:2048.hotkeys.help.hotkeys.alt')}/>
            </CardStack>

            <CardStack
                title={t('common:2048.hotkeys.help.ready.title')}
                description={t('common:2048.hotkeys.help.ready.description')}>
               <Button onClick={onClose} $color="primary">{t('common:2048.hotkeys.help.ready.start')}</Button>
            </CardStack>
            <CardStack
                title={t('common:2048.download.title')}
                description={t('common:2048.download.description')}>
                <HelpScreenshot src="/media/how-cephable-works.gif" alt={t('common:2048.download.alt')} />
                <Button href="https://cephable.com/downloads" target="_blank" $color="primary">{t('common:2048.download.download')}</Button>
            </CardStack>
        </ContentDialog>
    );
}

export default GameButtonHelpDialog;