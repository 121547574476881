export const color_primary = '#EE486F';
export const color_dark_primary = '#062844';
export const color_dark_semi_transparent = 'rgba(6, 40, 68, 0.9)';
export const color_secondary = '#36368B';
export const color_primary_50_percent = 'rgba(227, 22, 91, 0.5)';
export const color_accent = '#6DB9E7';
export const color_accent_50_percent = 'rgba(110, 235, 255, 0.5)';
export const color_accent_20_percent = 'rgba(110, 235, 255, 0.2)';
export const color_text_default = '#062844';
export const color_text_link = '#062844';
export const color_error = '#dc3545';

export const color_gradient_primary_full =
    'linear-gradient(30deg, #062844 0%, #693B83 33%, #A4417A 66%, #EE486F 100%);';
export const color_gradient_primary_full_angle =
    'linear-gradient(135deg, #062844 0%, #693B83 33%, #A4417A 66%, #EE486F 100%);';
export const color_gradient_primary_left = 'linear-gradient(30deg, #062844 0%, #693B83 100%);';
export const color_gradient_primary_mid = 'linear-gradient(30deg, #693B83 0%, #A4417A 100%);';
export const color_gradient_primary_right = 'linear-gradient(30deg, #A4417A 0%, #EE486F 100%);';

// create inverted versions of each gradient
export const color_gradient_primary_full_inverted =
    'linear-gradient(30deg, #EE486F 0%, #A4417A 33%, #693B83 66%, #062844 100%);';
export const color_gradient_primary_left_inverted = 'linear-gradient(30deg, #693B83 0%, #062844 100%);';
export const color_gradient_primary_mid_inverted = 'linear-gradient(30deg, #A4417A 0%, #693B83 100%);';
export const color_gradient_primary_right_inverted = 'linear-gradient(30deg, #EE486F 0%, #A4417A 100%);';

export const color_gradient_secondary_full =
    'linear-gradient(30deg, #062844 0%, #49558D 25%, #6B6CB1 50%, #6C82C0 75%, #6DB9E7 100%);';
export const color_gradient_secondary_left = 'linear-gradient(30deg, #062844 0%, #49558D 100%);';
export const color_gradient_secondary_mid = 'linear-gradient(30deg, #49558D 0%, #6B6CB1 100%);';
export const color_gradient_secondary_right = 'linear-gradient(30deg, #6B6CB1 0%, #6C82C0 100%);';

// inverted versions
export const color_gradient_secondary_full_inverted =
    'linear-gradient(30deg, #6DB9E7 0%, #6C82C0 25%, #6B6CB1 50%, #49558D 75%, #062844 100%);';
export const color_gradient_secondary_left_inverted = 'linear-gradient(30deg, #49558D 0%, #062844 100%);';
export const color_gradient_secondary_mid_inverted = 'linear-gradient(30deg, #6B6CB1 0%, #49558D 100%);';
export const color_gradient_secondary_right_inverted = 'linear-gradient(30deg, #6C82C0 0%, #6B6CB1 100%);';
