import * as React from 'react';
import type { SVGProps } from 'react';
const SvgKeyF1 = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 400 400" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M77.974 32.072C50.09 34.552 26.957 53.788 18.171 81.8l-2.155 6.872v230.469l2.155 6.872c7.744 24.69 25.529 41.905 50.06 48.456l6.378 1.703h250.782l6.378-1.703c24.531-6.551 42.316-23.766 50.06-48.456l2.155-6.872V90.234l-1.767-6.64c-7.655-28.772-31.59-48.921-61.228-51.545-10.462-.926-232.582-.905-243.015.023m248.175 17.546c19.117 4.05 35.054 19.598 40.03 39.054 2.132 8.333 2.157 166.725.028 174.609-5.316 19.687-21.496 35.38-40.426 39.208-8.909 1.801-246.374 1.478-253.125-.345-19.241-5.195-33.667-19.621-38.863-38.863-2.129-7.884-2.104-166.276.028-174.609 4.95-19.352 20.782-34.925 39.673-39.021 7.735-1.678 244.745-1.708 252.655-.033M120.313 176.172v57.422h16.406v-53.125h52.344V167.188h-52.344v-34.375h59.375V118.75h-75.781v57.422m111.718-55.578c-15.064 4.101-25 17.715-25 34.258v5.304H220.904l.891-5.989c2.364-15.897 9.688-22.815 23.24-21.954 20.859 1.325 26.233 27.07 8.646 41.428-1.81 1.477-9.45 6.762-16.979 11.744-23.978 15.866-32.796 28.083-32.796 45.441v2.768h74.219V220.313h-28.906c-15.899 0-28.906-.215-28.906-.477 0-5.617 8.804-14.088 25.461-24.496 27.887-17.427 35.561-28.708 33.063-48.605-2.527-20.127-24.277-32.274-46.806-26.141M46.824 309.005c7.405 4.79 15.411 8.088 23.878 9.835 10.906 2.252 247.69 2.252 258.596 0 12.703-2.621 24.905-9.045 34.021-17.91l4.65-4.522-.006 4.726c-.035 27.405-12.435 46.778-35.513 55.483l-6.278 2.367-123.828.23c-137.644.255-128.85.568-140.235-4.983-18.689-9.111-28.553-25.164-29.8-48.495l-.511-9.555 4.767 4.635c2.621 2.55 7.238 6.235 10.259 8.189"
        />
    </svg>
);
export default SvgKeyF1;
