import { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentDialog from "../../../components/dialog/ContentDialog";
import CardStack from "../../../components/card/CardStack";
import Button from "../../../components/buttons/CoreButton";
import HelpScreenshot from "./HelpScreenshot";
import MobileHelpVideo from "./MobileHelpVideo";
import { BodySmall, TitleExtraSmall, TitleSmall } from "../../../components/text/Typography";

interface VoiceHelpDialogProps {
    open: boolean;
    onClose: () => void;
}
const GameVoiceHelpDialog: React.FC<VoiceHelpDialogProps> = ({
    open,
    onClose,
}) => {
    const { t } = useTranslation();
   
    return (
        <ContentDialog
            title={t('common:2048.voice.help.title')}
            open={open}
            onClose={onClose}>


            <CardStack
                title={t('common:2048.voice.help.howToPlay.title')}
                description={t('common:2048.voice.help.howToPlay.description')}>
               <MobileHelpVideo src="/media/try-voice.mp4" loop autoPlay aria-label={t('common:2048.voice.help.howToPlay.videoAlt')}/>
            </CardStack>
            <CardStack
                title={t('common:2048.voice.help.troubleshooting.title')}
                description={t('common:2048.voice.help.troubleshooting.description')}>
                    <TitleExtraSmall>{t('common:2048.voice.help.troubleshooting.permissionErrorTitle')}</TitleExtraSmall>
                    <BodySmall>{t('common:2048.voice.help.troubleshooting.permissionErrorDescription')}</BodySmall>
                    <TitleExtraSmall>{t('common:2048.voice.help.troubleshooting.noDeviceErrorTitle')}</TitleExtraSmall>
                    <BodySmall>{t('common:2048.voice.help.troubleshooting.noDeviceErrorDescription')}</BodySmall>
                    <TitleExtraSmall>{t('common:2048.voice.help.troubleshooting.noVoiceErrorTitle')}</TitleExtraSmall>
                    <BodySmall>{t('common:2048.voice.help.troubleshooting.noVoiceErrorDescription')}</BodySmall>
                    <TitleExtraSmall>{t('common:2048.voice.help.troubleshooting.sensitivityTitle')}</TitleExtraSmall>
                    <BodySmall>{t('common:2048.voice.help.troubleshooting.sensitivityDescription')}</BodySmall>

            </CardStack>
            <CardStack
                title={t('common:2048.voice.help.voice.title')}
                description={t('common:2048.voice.help.voice.description')}>
               <HelpScreenshot src="/media/desktop-voice.png" alt={t('common:2048.voice.help.voice.alt')}/>
            </CardStack>

            <CardStack
                title={t('common:2048.voice.help.ready.title')}
                description={t('common:2048.voice.help.ready.description')}>
               <Button onClick={onClose} $color="primary">{t('common:2048.voice.help.ready.start')}</Button>
            </CardStack>
            <CardStack
                title={t('common:2048.download.title')}
                description={t('common:2048.download.description')}>
                <HelpScreenshot src="/media/how-cephable-works.gif" alt={t('common:2048.download.alt')} />
                <Button href="https://cephable.com/downloads" target="_blank" $color="primary">{t('common:2048.download.download')}</Button>
            </CardStack>
        </ContentDialog>
    );
}

export default GameVoiceHelpDialog;