import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHeadMovement1 = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <path
            fill="currentColor"
            fillOpacity={0.3}
            fillRule="evenodd"
            d="m62.192 82.689-1.328 1.008a18.905 18.905 0 0 1-10.833 3.837 18.897 18.897 0 0 1-3.248-.386c-6.507.83-13.09-.84-18.417-4.678L15.98 73.545c-1.922-1.384-3.588-2.863-4.568-4.749-3.392-6.523-5.152-18.608 5.884-29.643 4.497-4.498 9.332-6.676 13.896-7.416a24.636 24.636 0 0 1 3.499-2.74c-6.086.058-12.916 2.224-19.121 8.43-11.94 11.94-10.1 25.231-6.324 32.495 1.252 2.407 3.303 4.16 5.307 5.604L26.94 84.45a28.343 28.343 0 0 0 20.414 5.084l2.74-.375a20.125 20.125 0 0 0 10.34-4.636 1.22 1.22 0 0 0 .315-.229c.141-.14.28-.283.416-.428.144-.136.287-.274.428-.415a1.22 1.22 0 0 0 .228-.316c.126-.147.25-.296.37-.447Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M49.434 26.421c-14.32 0-21.445 9.627-23.637 16.586-.617 1.96-.496 4.125-.118 6.45l2.448 15.069a25.597 25.597 0 0 0 9.79 16.283l2.203 1.672a17.38 17.38 0 0 0 9.91 3.526 17.38 17.38 0 0 0 9.911-3.526l2.203-1.672a25.597 25.597 0 0 0 9.79-16.283l2.448-15.07c.378-2.325.501-4.49-.117-6.447-2.183-6.912-9.384-16.588-24.83-16.588Zm1.637 62.582a20.43 20.43 0 0 0 10.715-4.091l2.203-1.672a28.648 28.648 0 0 0 10.956-18.225l2.449-15.07c.398-2.45.62-5.198-.22-7.855-2.497-7.907-10.695-18.72-27.74-18.72-16.005 0-24.072 10.863-26.547 18.72-.837 2.657-.618 5.404-.22 7.856l2.449 15.07A28.648 28.648 0 0 0 36.072 83.24l2.203 1.672a20.43 20.43 0 0 0 10.715 4.09c.14.044.29.067.444.067.2 0 .398-.003.597-.01.198.007.397.01.596.01.154 0 .304-.023.444-.066Z"
            clipRule="evenodd"
        />
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="m4.71 24.817-.248-4.476a1.526 1.526 0 1 0-3.046.17l.461 8.311 8.686 1.829a1.525 1.525 0 1 0 .629-2.986l-4.134-.87c5.588-5.253 20.43-14.37 42.322-14.37 21.696 0 37.422 9.415 43.394 14.395l-4.016.845a1.525 1.525 0 1 0 .629 2.986l8.686-1.829.462-8.312a1.526 1.526 0 1 0-3.047-.169l-.254 4.567C89.145 19.62 72.48 9.374 49.38 9.374c-22.972 0-38.676 9.664-44.67 15.443Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillOpacity={0.3}
            fillRule="evenodd"
            d="M53.6 87.079a18.91 18.91 0 0 1-3.57.455 18.906 18.906 0 0 1-9.306-2.793 20.125 20.125 0 0 0 10.08 4.418l2.74.375a28.343 28.343 0 0 0 20.413-5.084l12.387-8.924c2.004-1.444 4.056-3.197 5.308-5.604 3.776-7.264 5.616-20.556-6.324-32.495-6.59-6.59-13.882-8.624-20.24-8.416a24.95 24.95 0 0 1 3.446 2.567c4.901.541 10.184 2.69 15.068 7.575 11.036 11.035 9.276 23.12 5.884 29.643-.98 1.886-2.646 3.365-4.568 4.75L72.53 82.47a25.902 25.902 0 0 1-18.657 4.646l-.275-.037Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgHeadMovement1;
