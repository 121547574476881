// constants for keys
export const ENABLED_ACCESS_TOKEN_STORE = "ENABLED_ACCESS_TOKEN";
export const ENABLED_REFRESH_TOKEN_STORE = "ENABLED_REFRESH_TOKEN";
export const ENABLED_EMAIL_STORE = "ENABLED_USER";
export const ENABLED_ACCESS_EXPIRATION = "ENABLED_ACCESS_EXPIRATION";

export function createUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
