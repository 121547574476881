import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDictationPause = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={100}
        fill="currentColor"
        viewBox="0 0 256 256"
        {...props}>
        <defs>
            <clipPath id="dictation-pause_svg__a">
                <path
                    fill="none"
                    d="M0 256V0h256v187.392c0-5.222-3.994-9.216-9.216-9.216h-12.288c-5.222 0-9.216 3.994-9.216 9.216 0-5.222-3.994-9.216-9.216-9.216h-12.288c-5.222 0-9.216 3.994-9.216 9.216v55.296c0 5.222 3.994 9.216 9.216 9.216h12.288c5.222 0 9.216-3.994 9.216-9.216 0 5.222 3.994 9.216 9.216 9.216h12.288c5.222 0 9.216-3.994 9.216-9.216V256z"
                />
            </clipPath>
        </defs>
        <g strokeMiterlimit={10} fontFamily="none" fontSize="none" fontWeight="none" textAnchor="none">
            <g
                fill="none"
                clipPath="url(#dictation-pause_svg__a)"
                style={{
                    mixBlendMode: 'normal',
                }}>
                <path
                    fill="currentColor"
                    d="M189.12 14.56c0 15.84-3.7 22.84-8.48 26.72-4.78 3.88-12.06 5.1-20.32 6.24-8.26 1.14-17.48 2.2-25.12 8.32-6.34 5.08-10.72 13.48-12 26.08H20.48C9.22 81.92 0 91.14 0 102.4v102.4c0 11.26 9.22 20.48 20.48 20.48h215.04c11.26 0 20.48-9.22 20.48-20.48V102.4c0-11.26-9.22-20.48-20.48-20.48H133.6c1.1-9.92 4.18-15.02 8-18.08 4.76-3.82 11.88-4.94 20.16-6.08 8.28-1.14 17.64-2.26 25.28-8.48 7.64-6.22 12.32-17.18 12.32-34.72zM20.48 92.16h215.04c5.72 0 10.24 4.52 10.24 10.24v102.4c0 5.72-4.52 10.24-10.24 10.24H20.48c-5.72 0-10.24-4.52-10.24-10.24V102.4c0-5.72 4.52-10.24 10.24-10.24zm5.12 20.48v20.48h20.48v-20.48zm30.72 0v20.48H76.8v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zM25.6 143.36v20.48h35.84v-20.48zm46.08 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48zm30.72 0v20.48h35.84v-20.48zM25.6 174.08v20.48h20.48v-20.48zm30.72 0v20.48H76.8v-20.48zm30.72 0v20.48h81.92v-20.48zm92.16 0v20.48h20.48v-20.48zm30.72 0v20.48h20.48v-20.48z"
                />
            </g>
            <path
                d="M5 0H1C.4 0 0 .4 0 1v18c0 .6.4 1 1 1h4c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM4 18H2V2h2zM15 0h-4c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h4c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-1 18h-2V2h2z"
                style={{
                    mixBlendMode: 'normal',
                }}
                transform="translate(200.704 184.32)scale(3.072)"
            />
        </g>
    </svg>
);
export default SvgDictationPause;
