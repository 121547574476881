import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOpenCloseMouth = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <path
            fill="#FBFDFE"
            d="m64.11 77.485-2.385 1.81A20.462 20.462 0 0 1 50 83.448a20.462 20.462 0 0 1-11.726-4.153l-2.384-1.81A29.356 29.356 0 0 1 24.662 58.81l-2.65-16.31c-.42-2.585-.603-5.244.183-7.742 2.526-8.019 10.747-19.107 27.159-19.107 17.584 0 25.917 11.088 28.45 19.107.79 2.497.603 5.157.183 7.742l-2.65 16.31a29.356 29.356 0 0 1-11.228 18.675Z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M49.354 17.302c-15.5 0-23.211 10.42-25.584 17.953-.668 2.12-.537 4.464-.128 6.98l2.65 16.31A27.705 27.705 0 0 0 36.888 76.17l2.384 1.81A18.81 18.81 0 0 0 50 81.796a18.81 18.81 0 0 0 10.727-3.816l2.384-1.81a27.705 27.705 0 0 0 10.596-17.625l2.65-16.31c.41-2.517.542-4.861-.127-6.98-2.363-7.48-10.157-17.953-26.876-17.953Zm1.772 67.736a22.113 22.113 0 0 0 11.597-4.428l2.385-1.81a31.007 31.007 0 0 0 11.858-19.725l2.65-16.31c.432-2.653.671-5.628-.237-8.504C76.676 25.703 67.803 14 49.354 14 32.03 14 23.299 25.757 20.62 34.262c-.905 2.876-.669 5.849-.238 8.502l2.65 16.311A31.007 31.007 0 0 0 34.893 78.8l2.384 1.81c3.373 2.56 7.4 4.091 11.597 4.428.152.047.314.072.48.072.216 0 .432-.004.646-.01.215.006.43.01.646.01.167 0 .329-.025.48-.072Z"
            clipRule="evenodd"
        />
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="M44.485 65.156c-1.396.837-1.885 1.764-1.885 2.476s.49 1.638 1.885 2.476c1.362.817 3.351 1.376 5.637 1.376 2.285 0 4.274-.559 5.636-1.376 1.396-.838 1.886-1.764 1.886-2.476s-.49-1.639-1.886-2.476c-1.362-.818-3.351-1.377-5.636-1.377-2.286 0-4.275.56-5.637 1.377Zm-1.7-2.832c1.958-1.174 4.556-1.847 7.337-1.847 2.78 0 5.378.673 7.336 1.847 1.924 1.155 3.488 2.98 3.488 5.308s-1.564 4.153-3.488 5.307c-1.958 1.175-4.555 1.848-7.336 1.848-2.781 0-5.379-.673-7.336-1.848-1.925-1.154-3.489-2.98-3.489-5.307 0-2.328 1.564-4.153 3.489-5.308Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgOpenCloseMouth;
