import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHeadUpAndDown = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <g opacity={0.9}>
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeOpacity={0.3}
                strokeWidth={2.16}
                d="M49.872 72.44v0a16.73 16.73 0 0 0 10.115-3.404l1.95-1.48a24.002 24.002 0 0 0 9.179-15.269l2.167-13.335c.343-2.114.495-4.288-.15-6.33C71.064 26.066 64.25 17 49.873 17c-13.42 0-20.141 9.066-22.206 15.622-.643 2.042-.493 4.216-.15 6.33l2.167 13.335a24.002 24.002 0 0 0 9.18 15.27l1.95 1.479a16.729 16.729 0 0 0 10.114 3.404v0"
            />
            <path
                fill="#FBFDFE"
                d="m38.856 77.942 1.95 1.48a16.73 16.73 0 0 0 9.587 3.396 16.73 16.73 0 0 0 9.587-3.396l1.95-1.48a24.002 24.002 0 0 0 9.179-15.268l2.166-13.336c.344-2.114.494-4.287-.15-6.33-2.064-6.556-8.786-15.622-22.204-15.622-14.377 0-21.19 9.066-23.262 15.622-.645 2.042-.492 4.216-.15 6.33l2.168 13.335a24.002 24.002 0 0 0 9.18 15.27Z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth={2.7}
                d="M50.92 82.826v0a16.729 16.729 0 0 1-10.114-3.404l-1.95-1.48a24.002 24.002 0 0 1-9.18-15.268L27.51 49.338c-.344-2.114-.496-4.288.15-6.33 2.07-6.556 8.884-15.622 23.26-15.622 13.42 0 20.14 9.066 22.206 15.622.643 2.043.493 4.216.15 6.33l-2.167 13.335a24.002 24.002 0 0 1-9.18 15.27l-1.95 1.48a16.73 16.73 0 0 1-10.114 3.403v0"
            />
        </g>
        <path
            stroke="#E51546"
            strokeLinecap="round"
            strokeWidth={3}
            d="M8 78.116v-47M3 32.884l4.864-4.367 5.136 4.6M92 28v47M87 73.232l4.864 4.367L97 73"
        />
    </svg>
);
export default SvgHeadUpAndDown;
