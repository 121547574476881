import styled from '@emotion/styled';
import { CardItem } from '../text/CardText';
import { ChipSmall } from '../text/Typography';
import { color } from '../../constants/Styles';

export const CardBorder = styled.div`
    border-radius: 0.375rem;
    border: 1px solid ${color.border};
`;

export const CardStackWrap = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.2rem;
    align-self: stretch;
    padding: 0.25rem 0;
    > svg {
        padding-top: 1rem;
    }
    &:not(:last-of-type) {
        border-bottom: 1px solid ${color.border};
    }
`;

export const SimpleCardStackWrap = styled.div`
    display: flex;
    gap: 0.5rem;
    &:not(:last-of-type) {
        border-bottom: 1px solid ${color.border};
    }
`;

export const CardSmall = styled.div`
    position: relative;
    width: 16rem;
    min-height: 16rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 0.375rem;
    border: 1px solid ${color.border};
`;

export const CardMedium = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.25rem;
    gap: 0.5rem;
    border-radius: 0.375rem;
    width: 24.625rem;
    max-width: 100vw;
    border: 1px solid ${color.border};
    background: ${color.bg_default};
`;

export const IconWrap = styled.div`
    padding: 1.25rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    border-bottom: 1px solid ${color.border};
`;

export const WrapCardText = styled.div`
    flex-basis: 100%;
    padding: 1.25rem 1.25rem 1.75rem 1.25rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2rem;
    align-self: stretch;
`;

export const ButtonWrap = styled.div`
    justify-self: space-between;
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-end;
    align-items: stretch;
`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
`;

export const ProfileTitleWrap = styled.div`
    margin-bottom: 0.5rem;
`;

export const StatusChip = styled(ChipSmall)`
    position: absolute;
    top: -0.8rem;
    left: 50%;
    transform: translateX(-50%);
`;

export const FeatureItem = CardItem.withComponent('li');

export const FeaturesWrap = styled.ul`
    padding: 10px;
    columns: 2;
`;
