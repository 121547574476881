import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVirtualButton = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M30 22.85A5.85 5.85 0 0 1 35.85 17h28.8a5.85 5.85 0 0 1 5.85 5.85v54a5.85 5.85 0 0 1-5.85 5.85h-28.8A5.85 5.85 0 0 1 30 76.85v-54Zm5.85-3.15a3.15 3.15 0 0 0-3.15 3.15v54A3.15 3.15 0 0 0 35.85 80h28.8a3.15 3.15 0 0 0 3.15-3.15v-54a3.15 3.15 0 0 0-3.15-3.15h-28.8Z"
            clipRule="evenodd"
        />
        <path
            fill="#E51546"
            d="M36.75 33.65a1.8 1.8 0 0 1 1.8-1.8h8.1a1.8 1.8 0 0 1 1.8 1.8v8.1a1.8 1.8 0 0 1-1.8 1.8h-8.1a1.8 1.8 0 0 1-1.8-1.8v-8.1ZM36.75 48.05a1.8 1.8 0 0 1 1.8-1.8h8.1a1.8 1.8 0 0 1 1.8 1.8v8.1a1.8 1.8 0 0 1-1.8 1.8h-8.1a1.8 1.8 0 0 1-1.8-1.8v-8.1ZM36.75 62.45a1.8 1.8 0 0 1 1.8-1.8h8.1a1.8 1.8 0 0 1 1.8 1.8v8.1a1.8 1.8 0 0 1-1.8 1.8h-8.1a1.8 1.8 0 0 1-1.8-1.8v-8.1ZM52.05 33.65a1.8 1.8 0 0 1 1.8-1.8h8.1a1.8 1.8 0 0 1 1.8 1.8v8.1a1.8 1.8 0 0 1-1.8 1.8h-8.1a1.8 1.8 0 0 1-1.8-1.8v-8.1ZM52.05 48.05a1.8 1.8 0 0 1 1.8-1.8h8.1a1.8 1.8 0 0 1 1.8 1.8v8.1a1.8 1.8 0 0 1-1.8 1.8h-8.1a1.8 1.8 0 0 1-1.8-1.8v-8.1ZM52.05 62.45a1.8 1.8 0 0 1 1.8-1.8h8.1a1.8 1.8 0 0 1 1.8 1.8v8.1a1.8 1.8 0 0 1-1.8 1.8h-8.1a1.8 1.8 0 0 1-1.8-1.8v-8.1Z"
        />
        <path fill="currentColor" d="M43.95 22.85h12.6v2.7h-12.6v-2.7Z" />
    </svg>
);
export default SvgVirtualButton;
