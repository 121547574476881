import { useEffect, useState } from "react";

const useReduceMotion = () => {
  const [isReducedMotion, setIsReducedMotion] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
    const handleReduceMotionChange = () => {
      setIsReducedMotion(mediaQuery.matches);
    };
    handleReduceMotionChange();
    mediaQuery.addEventListener("change", handleReduceMotionChange);

    return () => {
      mediaQuery.removeEventListener("change", handleReduceMotionChange);
    };
  }, []);

  return isReducedMotion;
};

export default useReduceMotion;
