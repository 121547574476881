import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSwitchControl1 = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <circle cx={50} cy={50} r={32.5} stroke="#000" strokeWidth={3} />
        <circle cx={50} cy={50} r={42.5} stroke="#000" strokeWidth={3} opacity={0.1} />
        <circle cx={50} cy={50} r={25} fill="#E51546" />
        <path
            fill="#FBFDFE"
            d="M74.813 67.203v6.89A15.313 15.313 0 0 1 59.5 89.407c-8.456 0-10.682-4.594-17.862-17.226a3.828 3.828 0 0 1 6.63-3.828l3.576 5.742V53.422a3.828 3.828 0 1 1 7.656 0V64.14a3.828 3.828 0 1 1 7.656 0v3.062a3.828 3.828 0 1 1 7.656 0Z"
        />
        <path
            fill="#E51546"
            d="M70.984 61.844a5.333 5.333 0 0 0-2.555.649 5.359 5.359 0 0 0-7.398-3.195v-5.876a5.36 5.36 0 0 0-10.719 0v15.312l-.73-1.173a5.363 5.363 0 0 0-9.274 5.384l.894 1.576c6.586 11.61 9.311 16.417 18.298 16.417a16.863 16.863 0 0 0 16.844-16.844v-6.89a5.36 5.36 0 0 0-5.36-5.36Zm2.297 12.25A13.797 13.797 0 0 1 59.5 87.875c-7.203 0-9.157-3.445-15.634-14.868l-.898-1.583v-.01a2.297 2.297 0 0 1 .837-3.137 2.273 2.273 0 0 1 1.148-.306 2.297 2.297 0 0 1 2.02 1.192l3.573 5.742a1.53 1.53 0 0 0 2.829-.811V53.422a2.297 2.297 0 0 1 4.594 0v13.016a1.531 1.531 0 1 0 3.062 0V64.14a2.297 2.297 0 0 1 4.594 0v3.828a1.531 1.531 0 0 0 3.062 0v-.766a2.297 2.297 0 0 1 4.594 0v6.89Z"
        />
    </svg>
);
export default SvgSwitchControl1;
