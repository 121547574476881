import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

/**
 * Note: This is a custom hook that is used to store a reference to the state.
 * When updating the state from the ref, you will need to use cloneDeep to create a new reference.
 * Without doing so, the state will not update and any useEffects that depend on the state will not run.
 * For examples of this, please see the ProfilesContainer.ts file.
 */

const isFunction = <S>(setStateAction: SetStateAction<S>): setStateAction is (prevState: S) => S =>
    typeof setStateAction === 'function';

type ReadOnlyRefObject<T> = {
    readonly current: T;
};

type UseStateRef = {
    <S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>, ReadOnlyRefObject<S>];
    <S = undefined>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>, ReadOnlyRefObject<S | undefined>];
};
//@ts-ignore
const useStateRef: UseStateRef = <S>(initialState?: S | (() => S)) => {
    const [state, setState] = useState(initialState);
    const ref = useRef(state);

    const dispatch: typeof setState = useCallback((setStateAction: any) => {
        ref.current = isFunction(setStateAction) ? setStateAction(ref.current) : setStateAction;

        setState(ref.current);
    }, []);

    return [state, dispatch, ref];
};

export default useStateRef;
