import styled from '@emotion/styled';
import SrOnlyText from '../text/SrOnlyText';
import Button, { ICoreButtonProps } from './CoreButton';
import { IconName } from '../images/IconMap';
export interface IIconsButtonProps extends Omit<ICoreButtonProps, 'children'> {
    label: string;
    startIcon: IconName;
    circle?: boolean;
}

function IconsButton({ label, endIcon, circle, $color = 'secondary', $size, ...restProps }: IIconsButtonProps) {
    return (
        <StyledButton $color={$color} $size="small" $borderRadius={circle} {...restProps}>
            <SrOnlyText>{label}</SrOnlyText>
        </StyledButton>
    );
}
const StyledButton = styled(Button)<{ $borderRadius: boolean }>`
    height: min-content;
    gap: 0;
    padding: 0;
    ${(props) => (props.$borderRadius ? 'border-radius: 50%;' : '')}
    svg {
        padding: 0.5rem;
    }
`;
export default IconsButton