import { commandExecutionTimes, commandThrottleMilliseconds } from '../../constants/ExpressionThresholds';
import ExpressionSettingsModel from '../../models/devices/ExpressionSettingsModel';
import FaceExpression from '../../models/expressionDetection/FaceExpression';
import {
    isSmiling,
    isLeftEyebrowRaised,
    isRightEyebrowRaised,
    isMouthOpen,
    isTiltUp,
    isLeaningBack,
    isLeaningForward,
    isLeaningLeft,
    isLeaningRight,
    isMovingUp,
    isMovingDown,
    isTiltLeft,
    isTiltRight,
    isTiltDown,
    isTurnLeft,
    isTurnRight,
} from './ExpressionCommandChecks';
import { getMouseMoveCommand } from './MouseCommandChecks';
import {
    ExpressionHeadCenter,
    ExpressionHeadLeanBack,
    ExpressionHeadLeanForward,
    ExpressionHeadLeanLeft,
    ExpressionHeadLeanRight,
    ExpressionHeadMoveDown,
    ExpressionHeadMoveUp,
    ExpressionHeadTiltBack,
    ExpressionHeadTiltForward,
    ExpressionHeadTiltLeft,
    ExpressionHeadTiltRight,
    ExpressionHeadTurnLeft,
    ExpressionHeadTurnRight,
} from '../../constants/controls/HeadControls';
import {
    ExpressionEyebrowsRaised,
    ExpressionSmile,
    ExpressionMouthOpened,
    ExpressionStoppedSmile,
    ExpressionMouthClosed,
} from '../../constants/controls/FaceControls';
export interface IDetectCommandsFromFace {
    commands: string[];
    previousFaceCommands: string[];
}

const excludeForHeadCentered = [
    ExpressionHeadLeanBack,
    ExpressionHeadLeanForward,
    ExpressionHeadLeanLeft,
    ExpressionHeadLeanRight,
    ExpressionHeadMoveUp,
    ExpressionHeadMoveDown,
    ExpressionHeadTiltLeft,
    ExpressionHeadTiltRight,
    ExpressionHeadTiltForward,
    ExpressionHeadTiltBack,
    ExpressionHeadTurnLeft,
    ExpressionHeadTurnRight,
    ExpressionHeadCenter,
];

const checkWithCommandExclusion = (rawCommands: string[], exclusionCommands: string[]) => {
    return !rawCommands.some((element) => exclusionCommands.includes(element));
};

const checkEdgeCases = (rawCommands: string[]) => {
    let newCommands = rawCommands;
    //if user is tilting head up --> remove smile, eyebrows raised
    if (rawCommands.includes(ExpressionHeadTiltBack)) {
        newCommands = newCommands.filter((command) => command !== ExpressionEyebrowsRaised);
    }
    // if user is smiling --> remove eyebrow rasied, mouth open
    if (rawCommands.includes(ExpressionSmile)) {
        newCommands = newCommands.filter((command) => command !== ExpressionMouthOpened);
    }
    // if user is mouth open --> remove eyebrow raised
    if (rawCommands.includes(ExpressionMouthOpened)) {
        newCommands = newCommands.filter((command) => command !== ExpressionEyebrowsRaised);
    }
    return newCommands;
};

export const detectCommandsFromFace = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionSettings: ExpressionSettingsModel,
    previousFaceCommands?: string[]
): IDetectCommandsFromFace => {
    if (face == null) {
        console.log('NO FACE');
        return { commands: [], previousFaceCommands: [] };
    }

    if (previousFaceCommands == null) {
        console.log('NO PREVIOUS FACE');
        //seed previousFaceCommands with mouth closed, smile stopped, and head centered to avoid call on initial load
        return {
            commands: [],
            previousFaceCommands: [ExpressionStoppedSmile, ExpressionHeadCenter, ExpressionMouthClosed],
        };
    }

    if (baselineFace == null) {
        baselineFace = face;
    }

    const commands: string[] = [];
    const rawCommands: string[] = [];

    function tryAddCommand(command: string): void {
        const throttle = commandThrottleMilliseconds[command];
        if (throttle) {
            const lastExecution = commandExecutionTimes[command];
            if (lastExecution != null && Date.now() - (lastExecution as any) <= throttle) {
                // console.log('throttle');
                return;
            }
        }
        commands.push(command);
    }

    if (isSmiling(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionSmile);
    }
    if (!isSmiling(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionStoppedSmile);
    }
    if (
        isLeftEyebrowRaised(face, baselineFace, expressionSettings) &&
        isRightEyebrowRaised(face, baselineFace, expressionSettings)
    ) {
        rawCommands.push(ExpressionEyebrowsRaised);
    }
    if (isMouthOpen(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionMouthOpened);
    }
    if (isTiltUp(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadTiltBack);
    }
    if (isLeaningBack(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadLeanBack);
    }
    if (isLeaningForward(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadLeanForward);
    }
    if (isLeaningLeft(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadLeanLeft);
    }
    if (isLeaningRight(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadLeanRight);
    }
    if (isMovingUp(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadMoveUp);
    }
    if (isMovingDown(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadMoveDown);
    }
    if (isTiltLeft(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadTiltLeft);
    }
    if (isTiltRight(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadTiltRight);
    }
    if (isTiltDown(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadTiltForward);
    }
    if (isTurnLeft(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadTurnLeft);
    }
    if (isTurnRight(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionHeadTurnRight);
    }
    // TODO: Should mouth close be exclusive to mouth open and smile?
    if (!isMouthOpen(face, baselineFace, expressionSettings)) {
        rawCommands.push(ExpressionMouthClosed);
    }

    // special case for head centered checking for exclusions on current commands
    checkWithCommandExclusion(rawCommands, excludeForHeadCentered) ? rawCommands.push(ExpressionHeadCenter) : null;

    // check for specicial cases like closed mouth unles previous mouth is open ✔️
    const checkedCommands = checkEdgeCases(rawCommands);

    // don't add commands that were already added in the previous frame
    for (let command of checkedCommands) {
        if (!previousFaceCommands?.includes(command)) {
            tryAddCommand(command);
            commandExecutionTimes[command] = Date.now();
        }
    }

    // console.log('COMMANDS:', commands, 'RAW COMMANDS:', rawCommands, 'PREVIOUS COMMANDS:', previousFaceCommands);

    // mouse movements
    let mouseMoveCommand = getMouseMoveCommand(face, baselineFace, commands, expressionSettings);
    if (mouseMoveCommand) tryAddCommand(mouseMoveCommand);

    return { commands: commands, previousFaceCommands: rawCommands };
};
