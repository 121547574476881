import styled from '@emotion/styled';
import { IconName } from '../images/IconMap';
import Icons, { IIconsProps } from '../images/Icons';
import { Tag } from '../text/ContentTypography';
import { BodySmall, TitleMedium, TitleSmall } from '../text/Typography';
import { ICoreCardProps } from '../../models/components/ICoreCardProps';
import { CardStackWrap, WrapCardText } from './CommonCardStyles';

interface ICardStackProps extends Omit<IIconsProps, 'name'>, ICoreCardProps {
    name?: IconName;
    tag?: string;
}

const CardStack: React.FC<ICardStackProps> = ({ title, description, name, size = 70, tag, children, ...restProps }) => {
    return (
        <CardStackWrap>
            {name && <Icons name={name} size={size} {...restProps} />}
            <WrapCardText>
                {tag && <CustomTag>{tag}</CustomTag>}
                <TitleMedium>{title}</TitleMedium>
                {description && <BodySmall>{description}</BodySmall>}
                {children}
            </WrapCardText>
        </CardStackWrap>
    );
};

const CustomTag = styled(Tag)`
    margin: 0;
`;

export default CardStack;
