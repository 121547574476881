import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSwitchSimple = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" viewBox="0 0 20 20" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10 17.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm0 1.5a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
            clipRule="evenodd"
        />
        <circle cx={10} cy={10} r={5} fill="currentColor" />
    </svg>
);
export default SvgSwitchSimple;
