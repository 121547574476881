import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTapPlay = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <circle cx={50} cy={50} r={26.5} stroke="#000" strokeWidth={3} />
        <path fill="#000" d="m61 50-16.5 9.526V40.474L61 50Z" />
        <path
            fill="#FBFDFE"
            d="M83.125 67.031v5.906A13.125 13.125 0 0 1 70 86.064c-7.248 0-9.156-3.938-15.31-14.766a3.281 3.281 0 0 1 5.683-3.281l3.065 4.921V55.22a3.281 3.281 0 1 1 6.562 0v9.187a3.281 3.281 0 0 1 6.563 0v2.625a3.281 3.281 0 0 1 6.562 0Z"
        />
        <path
            fill="#E51546"
            d="M79.844 62.438c-.765-.001-1.518.19-2.19.556a4.594 4.594 0 0 0-6.341-2.739V55.22a4.594 4.594 0 1 0-9.188 0v13.125l-.627-1.006a4.596 4.596 0 0 0-7.949 4.615l.767 1.35C59.96 83.255 62.297 87.375 70 87.375a14.454 14.454 0 0 0 14.438-14.437V67.03a4.594 4.594 0 0 0-4.594-4.594Zm1.969 10.5A11.826 11.826 0 0 1 70 84.75c-6.174 0-7.849-2.953-13.4-12.744l-.77-1.357v-.008a1.969 1.969 0 0 1 .717-2.69c.299-.173.639-.263.984-.262a1.969 1.969 0 0 1 1.731 1.022l3.063 4.922a1.313 1.313 0 0 0 2.425-.695v-17.72a1.969 1.969 0 1 1 3.938 0v11.157a1.313 1.313 0 0 0 2.624 0v-1.969a1.969 1.969 0 1 1 3.938 0v3.281a1.313 1.313 0 0 0 2.625 0v-.656a1.969 1.969 0 1 1 3.938 0v5.906Z"
        />
    </svg>
);
export default SvgTapPlay;
