import DetectionRange from "../models/expressionDetection/DetectionRange";
import {
  ExpressionEyebrowsRaised,
  ExpressionLeftEyeClosed,
  ExpressionLeftEyeOpened,
  ExpressionRightEyeClosed,
  ExpressionRightEyeOpened,
  ExpressionSmile,
  ExpressionStoppedSmile,
} from "./controls/FaceControls";

// TODO: find the paper on human movement abilities to note here for ranges
export const headTurnLeftThresholdRange: DetectionRange = { min: 2, max: 50 };
export const headTurnRightThresholdRange: DetectionRange = { min: 2, max: 50 };
export const headTiltLeftThresholdRange: DetectionRange = { min: 2, max: 45 };
export const headTiltRightThresholdRange: DetectionRange = { min: 2, max: 45 };
export const headTiltBackThresholdRange: DetectionRange = { min: 2, max: 30 };
export const headTiltForwardThresholdRange: DetectionRange = {
  min: 2,
  max: 30,
};

// position changes - relative to face box size and position in frame by percentage
// TODO: detect if the facebox used is the actual face box or the greater bounding box
export const headLeanLeftThresholdRange: DetectionRange = { min: 1.5, max: 8 };
export const headLeanRightThresholdRange: DetectionRange = { min: 1.5, max: 8 };
export const headLeanBackThresholdRange: DetectionRange = { min: 1.5, max: 3 };
export const headLeanForwardThresholdRange: DetectionRange = {
  min: 1.5,
  max: 3,
};
export const headMoveUpThresholdRange: DetectionRange = { min: 1.5, max: 8 };
export const headMoveDownThresholdRange: DetectionRange = { min: 1.5, max: 8 };

export const eyebrowRaiseThresholdRange: DetectionRange = {
  min: 0.005,
  max: 0.06,
}; // relative raise amount
export const mouthOpenThresholdRange: DetectionRange = { min: 0.01, max: 0.6 }; // relative width of the mouth to the height of the center inner lip points
export const smileThresholdRange: DetectionRange = { min: 0.01, max: 0.25 }; // relative ratio of width of mouth to width of eyes change versus base (bigger is more smile)
export const mouthPuckerThresholdRange: DetectionRange = {
  min: 0.01,
  max: 0.1,
}; // relative ratio of width of mouth to width of nose change versus base (smaller is more pucker)

export const commandExecutionTimes: { [key: string]: number } = {};
export const commandThrottleMilliseconds: { [key: string]: number } = {
  [ExpressionEyebrowsRaised]: 750,
  [ExpressionLeftEyeClosed]: 750,
  [ExpressionLeftEyeOpened]: 750,
  [ExpressionRightEyeClosed]: 750,
  [ExpressionRightEyeOpened]: 750,
  [ExpressionSmile]: 500,
  [ExpressionStoppedSmile]: 500,
};
