import { useState } from 'react';
import { createContainer } from 'unstated-next';

function useAlertsContainer() {
    const [error, setError] = useState(null as string);
    const [success, setSuccess] = useState(null as string);
    const [warning, setWarning] = useState(null as string);

    const showError = (error: string, timeout: number = 5000) => {
        setError(error);
        setTimeout(() => {
            setError(null);
        }, timeout);
    };
    const showSuccess = (success: string, timeout: number = 5000) => {
        setSuccess(success);
        setTimeout(() => {
            setSuccess(null);
        }, timeout);
    };

    const showWarning = (warning: string, timeout: number = 5000) => {
        setWarning(warning);
        setTimeout(() => {
            setWarning(null);
        }, timeout);
    };
    return {
        showSuccess,
        showError,
        showWarning,
        success,
        error,
        warning,
    };
}

const AlertsContainer = createContainer(useAlertsContainer);
export default AlertsContainer;
