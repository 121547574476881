import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSvgCephy = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
        <defs>
            <linearGradient
                id="svg-cephy_svg__linear-gradient"
                x1={4.82}
                x2={282.2}
                y1={407.51}
                y2={247.37}
                gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#062844" />
                <stop offset={0.34} stopColor="#693b82" />
                <stop offset={0.68} stopColor="#a3417a" />
                <stop offset={1} stopColor="#ed486f" />
            </linearGradient>
            <filter id="svg-cephy_svg__drop-shadow-5" filterUnits="userSpaceOnUse">
                <feOffset dy={10} />
                <feGaussianBlur result="blur" stdDeviation={16} />
                <feFlood floodColor="#ed486f" floodOpacity={0.75} />
                <feComposite in2="blur" operator="in" />
                <feComposite in="SourceGraphic" />
            </filter>
            <style>
                {
                    '.svg-cephy_svg__cls-4{fill:#fff}.svg-cephy_svg__cls-5{fill:#141526;mix-blend-mode:multiply;opacity:.2}'
                }
            </style>
        </defs>
        <g
            style={{
                fill: 'none',
                isolation: 'isolate',
            }}>
            <g
                id="svg-cephy_svg__Layer_1"
                style={{
                    filter: 'url(#svg-cephy_svg__drop-shadow-5)',
                    fill: 'none',
                }}>
                <path
                    d="M319.91 419.59c-14.31 0-33.03-10.47-50.06-28.01-10.08-10.37-15.94-19.86-19.85-26.31-3.91 6.45-9.77 15.93-19.85 26.31-17.04 17.54-35.75 28.01-50.07 28.01-10.87 0-19.47-6.08-22.45-15.87-2.5-8.23.52-14.87 5.39-20.64-9.53 2.81-20.54 4.62-33.22 4.62h-.86c-16.93-.11-27.35-3.21-33.78-10.04-4.19-4.45-6.23-10.1-5.89-16.36.73-13.84 12.67-18.78 23.19-21.01-16.35-6.01-30.87-17.39-43.37-34.03-9.09-13.12-8.6-22.74-6.64-28.56 2.21-6.59 7.37-11.68 14.17-14.02 2.08-.76 4.28-1.15 6.53-1.15 1.2 0 2.36.1 3.49.3-1.1-2.94-1.81-5.9-2.08-8.87-.76-8.09 1.79-15.76 6.99-21.02 3.83-3.9 9.45-6.14 15.39-6.14 8.19 0 15.5 4.12 20.04 11.31 9.9 15.68 21.42 18.93 30.67 21.54 3.67 1.04 8.05 2.28 11.76 4.7-.47-11.87-4.6-31.51-12.68-65.74-5.53-23.44-1.94-58.75 18.57-84.79 11.92-15.14 34.44-33.22 73.96-33.41h.72c39.85 0 62.55 18.1 74.57 33.28 20.61 26.04 24.24 61.41 18.69 84.91-8.09 34.23-12.22 53.88-12.68 65.74 3.71-2.42 8.09-3.66 11.76-4.7 9.26-2.61 20.78-5.87 30.67-21.54 4.54-7.19 11.85-11.31 20.04-11.31 5.94 0 11.56 2.24 15.4 6.15 5.18 5.26 7.73 12.92 6.97 21.01-.28 2.97-.98 5.93-2.08 8.87 1.13-.19 2.3-.3 3.5-.3 2.25 0 4.45.39 6.53 1.15 6.8 2.34 11.95 7.43 14.17 14.02 1.96 5.83 2.46 15.44-6.32 28.13-12.71 16.93-27.32 28.41-43.71 34.46 10.52 2.23 22.47 7.17 23.22 21.02.34 6.25-1.7 11.91-5.89 16.36-6.43 6.83-16.85 9.93-33.78 10.04h-.86c-12.68 0-23.69-1.8-33.23-4.62 4.88 5.77 7.9 12.41 5.4 20.64-2.98 9.79-11.58 15.87-22.45 15.87Z"
                    className="svg-cephy_svg__cls-4"
                />
                <path
                    d="M418.78 276.51c-6.93-2.73-17.21 10.65-33.08 18.9-14.71 7.66-29.99 8.79-45.62 2.87-31.31-11.86-26.87-31.73-10.09-102.8 8.53-36.13-8.4-101.46-80.01-101.46h-.65c-71.05.33-87.86 65.44-79.35 101.46 16.78 71.07 21.22 90.94-10.09 102.8-15.63 5.92-30.91 4.79-45.62-2.87-15.87-8.26-26.15-21.63-33.08-18.9-5.81 1.91-10.15 8.67-1.22 21.58 40.62 54.06 88.48 24.86 91.4 32.18 2.32 5.34-26.67 19.11-47.86 22-12.03 1.64-20.33 3.68-20.66 9.77-.49 9.16 10.82 11.95 26.16 12.05 56.47.36 74.38-38.5 81.13-34.58 4.07 1.98 2.26 7.41-3.32 16.82-14.47 24.39-39.17 33.63-36.18 43.44 3.91 12.84 27.49 5.22 49.73-17.67 19.49-20.06 20.91-35.61 29.61-35.61s10.12 15.55 29.61 35.61c22.24 22.89 45.82 30.51 49.73 17.67 2.98-9.81-21.72-19.06-36.18-43.44-5.59-9.41-7.4-14.83-3.32-16.82 6.76-3.92 24.67 34.94 81.13 34.58 15.34-.1 26.65-2.88 26.16-12.05-.33-6.1-8.62-8.13-20.66-9.77-21.19-2.89-50.18-16.66-47.86-22 2.93-7.32 50.78 21.89 91.4-32.18 8.93-12.91 4.59-19.67-1.22-21.58Zm-135.01-7.34c-6.04 11-18.83 18.63-33.78 18.63s-27.74-7.63-33.78-18.63c-1.27-2.32.61-5.03 3.57-5.03 0 0 14.59 6.53 30.22 6.53s30.22-6.53 30.22-6.53c2.95 0 4.84 2.71 3.57 5.03Zm52.51 8.89c1.89-7.85 28.72-1.81 48.22-32.68 4.15-6.57 11.54-5.61 14.23-2.88 5.97 6.05 5.08 21.17-15.85 35.65-14.63 10.88-32.21 12.18-41.32 7.91-6.93-3.22-5.27-8-5.27-8Zm-172.59 0c-1.89-7.85-28.72-1.81-48.22-32.68-4.15-6.57-11.54-5.61-14.23-2.88-5.97 6.05-5.08 21.17 15.85 35.65 14.63 10.88 32.21 12.18 41.32 7.91 6.93-3.22 5.27-8 5.27-8Z"
                    style={{
                        fill: 'url(#svg-cephy_svg__linear-gradient)',
                    }}
                />
                <path
                    d="M110.52 306.85c15.19 8.49 40.3 10.26 56.26 5.29 32.75-10.19 46.72-36.6 29.87-113.5-10.6-48.37 8.19-100.6 78.54-101.43-7.53-2-15.89-3.13-25.2-3.13h-.65c-71.05.33-87.86 65.44-79.35 101.46 16.78 71.07 21.22 90.94-10.09 102.8-15.63 5.92-30.91 4.79-45.62-2.87-15.87-8.26-26.15-21.63-33.08-18.9-1.39.46-2.68 1.2-3.74 2.22 7.07 7.46 17.66 19.46 33.06 28.07Z"
                    className="svg-cephy_svg__cls-5"
                />
                <path
                    d="M306.48 192.79c3.47-31.52-15.82-60.49-47.29-66.62-4.67-1-7.76-5.72-6.21-10.36 4.14-10.64 15.94-2.43 22.86.5 19.91 9.82 34.44 29.94 36.69 52.02.98 8.55.78 16.95-.97 25.44-.29 1.4-1.66 2.3-3.06 2.02-1.4-.28-2.26-1.63-2.04-2.99Z"
                    className="svg-cephy_svg__cls-5"
                />
                <path
                    d="M210.76 240.42c-7.64 0-13.83-7.71-13.83-17.22 0-9.51 6.19-17.22 13.83-17.22 4.95 0 9.26 3.26 11.7 8.12-1.52-1.41-3.38-2.27-5.42-2.27-5.04 0-9.13 5.09-9.13 11.36s4.09 11.36 9.13 11.36c2.04 0 3.9-.86 5.42-2.27-2.44 4.86-6.75 8.12-11.7 8.12ZM291.76 240.42c-7.64 0-13.83-7.71-13.83-17.22s6.19-17.22 13.83-17.22c4.68 0 8.79 2.91 11.3 7.33-1.31-.91-2.8-1.48-4.41-1.48-5.04 0-9.13 5.09-9.13 11.36s4.09 11.36 9.13 11.36c1.61 0 3.1-.56 4.41-1.48-2.5 4.42-6.62 7.33-11.3 7.33Z"
                    className="svg-cephy_svg__cls-4"
                />
            </g>
        </g>
    </svg>
);
export default SvgSvgCephy;
