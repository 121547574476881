import { keyframes } from '@emotion/react';

// Colors
export const color = {
    primary: '#EE486F', // pink
    primary_updated: '#e51546', // accessible pink
    dark_primary: '#062844', // dark blue
    secondary: '#36368B', // purple
    light_secondary: '#B0B0E4', // light purple
    accent: '#6DB9E7', // light blue
    accent_2: '#F4AE10', // yellow
    text_default: '#062844',
    text_link: '#062844',
    error: '#dc3545',
    bg_default: '#ffffff',
    // new colors
    neutral: '#565C61', // medium gray
    neutral_accent_1: '#FBFDFE',
    neutral_accent_2: '#F6F6FA',
    success: '#14761A',
    light_success: '#e1e6e1',
    border: '#cccccc',
};

//Transparency
export const colorPercent = {
    accent_50: 'rgba(110, 235, 255, 0.5)',
    accent_20: 'rgba(110, 235, 255, 0.2)',
    primary_50: 'rgba(227, 22, 91, 0.5)',
    dark_90: 'rgba(6, 40, 68, 0.9)',
    dark_semi_transparent: 'rgba(6, 40, 68, 0.9)',
};

// Gradients
export const gradientsFull = {
    hero: 'linear-gradient(26deg, #062844 30%, #49558d 70.66%, #6b6cb1 110.51%, #6c82c0 150.36%, #6db9e7 186.13%)',
    primary: 'linear-gradient(30deg, #062844 0%, #693B83 33%, #A4417A 66%, #EE486F 100%);',
    primary_angle: 'linear-gradient(135deg, #062844 0%, #693B83 33%, #A4417A 66%, #EE486F 100%);',
    primary_inverted: 'linear-gradient(30deg, #EE486F 0%, #A4417A 33%, #693B83 66%, #062844 100%);',
    secondary: 'linear-gradient(30deg, #062844 0%, #49558D 25%, #6B6CB1 50%, #6C82C0 75%, #6DB9E7 100%);',
    secondary_inverted: 'linear-gradient(30deg, #6DB9E7 0%, #6C82C0 25%, #6B6CB1 50%, #49558D 75%, #062844 100%);',
};

export const gradientLeft = {
    primary: 'linear-gradient(30deg, #062844 0%, #693B83 100%);',
    primary_inverted: 'linear-gradient(30deg, #693B83 0%, #062844 100%);',
    secondary: 'linear-gradient(30deg, #062844 0%, #49558D 100%);',
    secondary_inverted: 'linear-gradient(30deg, #49558D 0%, #062844 100%);',
};

export const gradientMid = {
    primary: 'linear-gradient(30deg, #693B83 0%, #A4417A 100%);',
    primary_inverted: 'linear-gradient(30deg, #A4417A 0%, #693B83 100%);',
    secondary: 'linear-gradient(30deg, #49558D 0%, #6B6CB1 100%);',
    secondary_inverted: 'linear-gradient(30deg, #6B6CB1 0%, #49558D 100%);',
};

export const gradientRight = {
    primary: 'linear-gradient(30deg, #A4417A 0%, #EE486F 100%);',
    primary_inverted: 'linear-gradient(30deg, #EE486F 0%, #A4417A 100%);',
    secondary: 'linear-gradient(30deg, #6B6CB1 0%, #6C82C0 100%);',
    secondary_inverted: 'linear-gradient(30deg, #6C82C0 0%, #6B6CB1 100%);',
};

// Typography
export const typographySize = {
    font_xs: '0.62rem', // 10px
    font_sm: '0.80rem', // 13px
    font_md: '1rem', // 16px
    font_lg: '1.25rem', // 20px
    font_xlg: '1.563rem', // 25px
    font_2xlg: '1.953rem', // 31px
    font_3xlg: '2.441rem', // 39px
    font_4xlg: '3.052rem', // 49px
    default: '1rem', // 16px
    text_default: '1rem', // 16px
    caption: '0.8rem', // 13px
    button_default: '1rem', // 16px
    button_hover: '1.1rem', // 17.6px
    button_lg: '1.25rem', // 20px
    button_lg_hover: '1.35rem', // 21.6px
    button_sm: '0.8rem', // 13px
    button_sm_hover: '0.9rem', // 14.4px
    heading_1: '1.563rem', // 25px
    heading_2: '1.953rem', // 31px
    card_title: '1.953rem', // 31px
    card_subtitle: '1.25rem', // 20px
    menu_card: '1rem', // 20px
    step: '1.25rem', // 20px
    step_seperator: '1.563rem', // 20px
};

export const typographyWeight = {
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    default: '400',
    text_default: '400',
    button_default: '400',
    button_bold: '600',
    card_title: '400',
    card_subtitle: '300',
    menu_card: '400',
};

export const typographyLineHeight = {
    // using standard 1.5 multiplier
    font_xs: '0.930rem', // 15px
    font_sm: '1.200rem', // 19px
    font_md: '1.500rem', // 24px
    font_lg: '1.875rem', // 30px
    font_xlg: '2.344rem', // 37px
    font_2xlg: '2.930rem', // 47px
    font_3xlg: '3.661rem', // 58px
    font_4xlg: '4.578rem', // 73px
    text_default: '1.5rem', // 24px
};

// Transitions
export const transition = {
    default: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
    button: `all 0.1s ease-in-out;`,
};

// Border Radius
export const borderRadius = {
    sm: '0.25rem', // 4px
    md: '0.5rem', // 8px
    default: '0.62rem', // 10px
    lg: '2rem', // 32px
    round: '50%',
};

// Box Shadows
export const boxShadow = {
    default: `0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);`,
    hover: `0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);`,
};

export const textureSvg = `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="1095" height="2164" viewBox="0 0 1095 2164" fill="none"><path opacity="0.1" d="M457.684 1500.32C427.564 1256.88 363.423 1054.11 325.82 871.89C286.698 669.335 239.351 449.24 399.125 295.785C515.961 180.441 714.417 190.481 856.597 257.274C1007.97 338.515 1092.09 469.391 994.562 733.466C1068.45 636.091 1106.37 515.177 1091.93 410.33C1045.97 77.7569 778.061 -1.68068 594.512 0.870846C418.992 3.40439 200.036 114.728 96.5716 329.053C25.8917 475.564 -3.03559 682.462 71.7142 1057.36C163.323 1516.37 125.759 1834.89 8.88889 2087.43C7.06729 2091.18 5.96644 2095.28 3.85654 2098.88C-1.55431 2108.55 -1.09116 2117.86 16.5831 2121.46C19.0256 2121.94 21.5422 2122.63 24.0588 2123.31C160.492 2157.2 297.61 2169.14 431.78 2161.12C435.615 2145.17 501.825 1856.07 457.824 1500.03L457.684 1500.32Z" fill="url(%23paint0_linear_2388_1132)"/><defs><linearGradient id="paint0_linear_2388_1132" x1="459.422" y1="41.4754" x2="192.952" y2="633.478" gradientUnits="userSpaceOnUse"><stop stop-color="%236DB9E7"/><stop offset="0.07" stop-color="%236CA2D7"/><stop offset="0.16" stop-color="%236B8AC6"/><stop offset="0.26" stop-color="%236B79BA"/><stop offset="0.37" stop-color="%236B6FB3"/><stop offset="0.5" stop-color="%236B6CB1"/><stop offset="1" stop-color="%23062844"/></linearGradient></defs></svg>')`;

export const animateSlideUp = keyframes`
  0% {
    transform: translateY(10%);
    opacity: 0;
    overflow: hidden;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const animateDarkBg = keyframes`
    0% {
        background-color: rgba(0, 0, 0, 0.0);
    }
    100% {
        background-color: '#062844' ;
    }
`;

export const animateOpacity = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;
