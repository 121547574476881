import { useContext, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentDialog from "../../../components/dialog/ContentDialog";
import CardStack from "../../../components/card/CardStack";
import Button from "../../../components/buttons/CoreButton";
import { ContentTitleSmall, ContentTitleThin } from "../../../components/text/ContentTypography";
import { GameContext } from "../context/game-context";
import { color } from "../../../constants/Styles";
import styled from "@emotion/styled";
import { BodyLarge } from "../../../components/text/Typography";
import HelpScreenshot from "./HelpScreenshot";

interface EndGameDialogProps {
    open: boolean;
    onClose: () => void;
    inputType: string
}
const EndGameDialog: React.FC<EndGameDialogProps> = ({
    open,
    onClose,
    inputType
}) => {
    const { t } = useTranslation();
    const { score } = useContext(GameContext);
    const canShare = navigator.share !== undefined;
    const handleShare = () => {
        let text = t("common:2048.endGame.share.text", "", { score });
        switch(inputType) {
            case "voice":
                text = t("common:2048.endGame.share.textVoice", "", { score });
                break;
            case "head":
                text = t("common:2048.endGame.share.textHead", "", { score });
                break;
        }
        navigator.share({
            url: "https://try.cephable.com",
            text,
            title:t("common:2048.endGame.share.title")
        })
    }
    return (
        <ContentDialog
            title={t('common:2048.endGame.title')}
            open={open}
            onClose={onClose}>


            <CardStack
                title={t('common:2048.endGame.score.title')}
                description={t('common:2048.endGame.score.description')}>
                <ScoreContainer>

                    <BodyLarge>{t("common:2048.score")}</BodyLarge>
                    <FinalScoreLabel>{score}</FinalScoreLabel>
                    {canShare && <Button $color="primary" onClick={handleShare}>{t('common:2048.shareYourScore')}</Button>}
                    <Button $color="primary" onClick={onClose}>{t('common:2048.playAgain')}</Button>

                </ScoreContainer>
            </CardStack>
            <CardStack
                title={t('common:2048.download.title')}
                description={t('common:2048.download.description')}>
                <HelpScreenshot src="/media/how-cephable-works.gif" alt={t('common:2048.download.alt')} />
                <Button href="https://cephable.com/downloads" target="_blank" $color="primary">{t('common:2048.download.download')}</Button>
            </CardStack>
        </ContentDialog>
    );
}

const ScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`
const FinalScoreLabel = styled.p`
    font-weight: 700;
    font-size: 3rem; // 32px
    color: ${color.text_default};
    text-align: center;
    margin: 0 0 2rem 0;
`

export default EndGameDialog;