import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLeanFrontToBack = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <path
            fill="#fff"
            d="m65.164 45.724-1.322 1.01a11.015 11.015 0 0 1-6.342 2.26 11.015 11.015 0 0 1-6.342-2.26l-1.322-1.01a15.93 15.93 0 0 1-6.05-10.117l-1.276-7.921c-.339-2.098-.504-4.275.284-6.248C44.488 17.194 48.915 12 57.15 12c8.824 0 13.347 5.195 15.055 9.438.793 1.972.624 4.15.285 6.248l-1.277 7.92a15.93 15.93 0 0 1-6.05 10.118Z"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeOpacity={0.3}
            strokeWidth={2.16}
            d="M57.15 49v0c2.42 0 4.77-.796 6.692-2.266l1.322-1.01a15.93 15.93 0 0 0 6.05-10.117l1.276-7.921c.339-2.098.508-4.276-.285-6.248C70.497 17.195 65.975 12 57.15 12c-8.235 0-12.662 5.194-14.356 9.438-.788 1.973-.623 4.15-.284 6.248l1.277 7.92a15.93 15.93 0 0 0 6.05 10.118l1.321 1.01A11.015 11.015 0 0 0 57.85 49v0"
        />
        <path
            fill="#fff"
            d="m56.177 63.81-1.712 1.322a13.752 13.752 0 0 1-7.965 2.861 13.752 13.752 0 0 1-7.965-2.86l-1.712-1.322a20.09 20.09 0 0 1-7.56-12.734L27.5 40.042c-.332-2.079-.484-4.22.19-6.214C29.558 28.31 35.156 21 46.06 21c11.685 0 17.374 7.31 19.25 12.828.677 1.993.522 4.135.19 6.214l-1.763 11.035a20.09 20.09 0 0 1-7.56 12.734Z"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeOpacity={0.3}
            strokeWidth={2.16}
            d="M46.06 68v0c3.042 0 5.998-1.008 8.405-2.868l1.712-1.321a20.09 20.09 0 0 0 7.56-12.734L65.5 40.042c.332-2.079.487-4.221-.19-6.214C63.433 28.31 57.744 21 46.06 21c-10.905 0-16.503 7.31-18.37 12.828-.674 1.994-.522 4.135-.19 6.214l1.763 11.035a20.09 20.09 0 0 0 7.56 12.734l1.712 1.321A13.752 13.752 0 0 0 46.94 68v0"
        />
        <path
            fill="#FBFDFE"
            d="m45.936 82.556-1.95 1.48a16.73 16.73 0 0 1-9.586 3.396 16.73 16.73 0 0 1-9.587-3.396l-1.95-1.48a24.002 24.002 0 0 1-9.179-15.269l-2.167-13.335c-.343-2.114-.493-4.288.15-6.33C13.732 41.066 20.454 32 33.872 32c14.377 0 21.19 9.066 23.262 15.622.645 2.042.492 4.216.149 6.33l-2.167 13.335a24.002 24.002 0 0 1-9.18 15.27Z"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={2.7}
            d="M33.872 87.44v0a16.73 16.73 0 0 0 10.115-3.404l1.95-1.48a24.002 24.002 0 0 0 9.179-15.269l2.167-13.335c.343-2.114.495-4.288-.15-6.33C55.064 41.066 48.25 32 33.874 32c-13.42 0-20.141 9.066-22.206 15.622-.643 2.042-.493 4.216-.15 6.33l2.167 13.335a24.002 24.002 0 0 0 9.18 15.27l1.95 1.479a16.73 16.73 0 0 0 10.114 3.404v0"
        />
        <path
            fill="#E51546"
            d="M67.072 82.874a1 1 0 0 0 1.302.554l8.348-3.363a1 1 0 0 0-.747-1.855l-7.421 2.989-2.989-7.42a1 1 0 0 0-1.855.746l3.362 8.349Zm23.356-51.248a1 1 0 0 0-1.302-.554l-8.348 3.363a1 1 0 0 0 .747 1.855l7.421-2.989 2.989 7.42a1 1 0 0 0 1.855-.746l-3.362-8.349ZM68.92 82.892l21.5-50.5-1.84-.784-21.5 50.5 1.84.784Z"
        />
    </svg>
);
export default SvgLeanFrontToBack;
