import FaceExpression from '../../models/expressionDetection/FaceExpression';
import HumanFace, { DPoint } from '../../models/expressionDetection/HumanFace';
import Point from '../../models/expressionDetection/Point';

const mapDPointToPoints = (dpoints: DPoint[]): Point[] => {
    return dpoints.map((dpoint) => ({ x: dpoint[0], y: dpoint[1], z: dpoint[2] }));
};

export const rad2deg = (theta: number) => Math.round((theta * 180) / Math.PI);
export const createFromHumanFace = (humanFace: HumanFace): FaceExpression => {
    return {
        leftEyebrowPoints: [
            ...mapDPointToPoints(humanFace.annotations.leftEyebrowUpper),
            ...mapDPointToPoints(humanFace.annotations.leftEyebrowLower),
        ],
        rightEyebrowPoints: [
            ...mapDPointToPoints(humanFace.annotations.rightEyebrowUpper),
            ...mapDPointToPoints(humanFace.annotations.rightEyebrowLower),
        ],
        leftEyePoints: [
            ...mapDPointToPoints(humanFace.annotations.leftEyeUpper0),
            ...mapDPointToPoints(humanFace.annotations.leftEyeUpper1),
            ...mapDPointToPoints(humanFace.annotations.leftEyeUpper2),
            ...mapDPointToPoints(humanFace.annotations.leftEyeLower0),
            ...mapDPointToPoints(humanFace.annotations.leftEyeLower1),
            ...mapDPointToPoints(humanFace.annotations.leftEyeLower2),
        ],
        rightEyePoints: [
            ...mapDPointToPoints(humanFace.annotations.rightEyeUpper0),
            ...mapDPointToPoints(humanFace.annotations.rightEyeUpper1),
            ...mapDPointToPoints(humanFace.annotations.rightEyeUpper2),
            ...mapDPointToPoints(humanFace.annotations.rightEyeLower0),
            ...mapDPointToPoints(humanFace.annotations.rightEyeLower1),
            ...mapDPointToPoints(humanFace.annotations.rightEyeLower2),
        ],
        nosePoints: [
            ...mapDPointToPoints(humanFace.annotations.noseTip),
            ...mapDPointToPoints(humanFace.annotations.noseBottom),
            ...mapDPointToPoints(humanFace.annotations.noseLeftCorner),
            ...mapDPointToPoints(humanFace.annotations.noseRightCorner),
        ],
        // rightPupilPoints: mapDPointToPoints(humanFace.annotations.rightEyeIris), // TODO: handle eye tracking
        // leftPupilPoints: mapDPointToPoints(humanFace.annotations.leftEyeIris),
        innerLipPoints: [
            ...mapDPointToPoints(humanFace.annotations.lipsUpperInner),
            ...mapDPointToPoints(humanFace.annotations.lipsLowerInner),
        ],
        outerLipPoints: [
            ...mapDPointToPoints(humanFace.annotations.lipsUpperOuter),
            ...mapDPointToPoints(humanFace.annotations.lipsLowerOuter),
        ],
        medianLinePoints: mapDPointToPoints(humanFace.annotations.silhouette),
        boundingBox: { x: humanFace.box[0], y: humanFace.box[1], width: humanFace.box[2], height: humanFace.box[3] },
        pitchDegrees: rad2deg(humanFace.rotation?.angle?.pitch ?? 0),
        yawDegrees: rad2deg(humanFace.rotation?.angle?.yaw ?? 0),
        rollDegrees: rad2deg(humanFace.rotation?.angle?.roll ?? 0),
    };
};
