import styled from '@emotion/styled';
import { color, typographySize } from '../../constants/Styles';
import { useTranslation } from 'react-i18next';
import { IconName } from '../images/IconMap';
import Icons from '../images/Icons';
import { PageTitle } from '../text/ContentTypography';

export interface INotifyErrorProps {
    icon?: IconName;
    errorAlert: string;
    errorTitle?: string;
    children?: React.ReactNode;
}

function NotifyError({ icon = 'tools', errorAlert, errorTitle, children }: INotifyErrorProps) {
    const { t } = useTranslation();
    errorTitle = errorTitle ? errorTitle : t('common:peripherals.error');
    return (
        <PermissionWrap>
            <PermissionChip>
                <Icons name={icon} />
                {errorAlert}
            </PermissionChip>
            <PageTitle>{errorTitle}</PageTitle>
            {children}
        </PermissionWrap>
    );
}

export const PermissionWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    gap: 1rem;
    text-align: center;
`;

export const PermissionChip = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-size: ${typographySize.font_sm};
    background-color: ${color.error}30;
    padding: 8px;
    border-radius: 10px;
    border: 2px solid ${color.error};
`;

export default NotifyError;
