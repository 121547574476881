import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHeadMovement = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <path
            fill="currentColor"
            fillOpacity={0.3}
            fillRule="evenodd"
            d="m60.786 78.88-1.175.892a16.729 16.729 0 0 1-9.587 3.396c-.97-.03-1.932-.146-2.874-.341a22.922 22.922 0 0 1-16.298-4.14L19.89 70.789c-1.7-1.225-3.175-2.534-4.043-4.203-3.001-5.773-4.56-16.467 5.207-26.233 3.98-3.98 8.259-5.908 12.298-6.562a21.803 21.803 0 0 1 3.096-2.425c-5.385.051-11.43 1.968-16.921 7.46C8.96 49.392 10.588 61.154 13.93 67.582c1.107 2.13 2.923 3.682 4.697 4.96l10.961 7.897a25.082 25.082 0 0 0 18.066 4.5l2.425-.333a17.81 17.81 0 0 0 9.15-4.103c.1-.05.196-.118.28-.202.124-.124.247-.25.367-.378.128-.12.255-.243.38-.368.083-.084.15-.179.201-.28.111-.13.22-.262.328-.395Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M49.496 29.086c-12.673 0-18.978 8.52-20.917 14.678-.546 1.733-.44 3.65-.105 5.707l2.167 13.336a22.652 22.652 0 0 0 8.663 14.41l1.95 1.48a15.38 15.38 0 0 0 8.77 3.12 15.38 15.38 0 0 0 8.77-3.12l1.95-1.48a22.653 22.653 0 0 0 8.664-14.41l2.166-13.336c.335-2.058.443-3.974-.104-5.706-1.931-6.116-8.303-14.679-21.974-14.679Zm1.449 55.382a18.079 18.079 0 0 0 9.482-3.62l1.95-1.48a25.352 25.352 0 0 0 9.696-16.128l2.167-13.336c.352-2.168.548-4.6-.195-6.952-2.21-6.997-9.465-16.566-24.549-16.566-14.164 0-21.303 9.613-23.493 16.567-.74 2.35-.547 4.781-.194 6.951l2.167 13.336a25.352 25.352 0 0 0 9.696 16.128l1.95 1.48a18.079 18.079 0 0 0 9.481 3.62c.125.038.257.058.393.058.176 0 .352-.002.528-.008.176.006.352.008.528.008.137 0 .269-.02.393-.058Z"
            clipRule="evenodd"
        />
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="m9.918 27.667-.22-3.962a1.35 1.35 0 0 0-2.696.15l.409 7.356 7.686 1.618a1.35 1.35 0 1 0 .556-2.642l-3.658-.77C16.941 24.768 30.075 16.7 49.448 16.7c19.2 0 33.117 8.332 38.402 12.739l-3.553.748a1.35 1.35 0 0 0 .556 2.642l7.686-1.618.409-7.356a1.35 1.35 0 0 0-2.696-.15l-.224 4.042C84.638 23.068 69.89 14 49.448 14c-20.329 0-34.226 8.552-39.53 13.667Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillOpacity={0.3}
            fillRule="evenodd"
            d="M53.182 82.765a16.73 16.73 0 0 1-3.158.403 16.73 16.73 0 0 1-8.236-2.471 17.81 17.81 0 0 0 8.92 3.91l2.425.332a25.082 25.082 0 0 0 18.065-4.5l10.962-7.897c1.773-1.278 3.59-2.83 4.697-4.96 3.342-6.428 4.97-18.19-5.596-28.756-5.831-5.832-12.286-7.632-17.913-7.448a22.078 22.078 0 0 1 3.05 2.271c4.338.48 9.013 2.381 13.335 6.704 9.767 9.766 8.209 20.46 5.207 26.233-.867 1.669-2.342 2.978-4.043 4.203l-10.962 7.897a22.922 22.922 0 0 1-16.51 4.112l-.243-.033Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgHeadMovement;
