import { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentDialog from "../../../components/dialog/ContentDialog";
import CardStack from "../../../components/card/CardStack";
import Button from "../../../components/buttons/CoreButton";
import MobileHelpVideo from "./MobileHelpVideo";
import HelpScreenshot from "./HelpScreenshot";
import { BodySmall, TitleExtraSmall } from "../../../components/text/Typography";

interface CameraHelpDialogProps {
    open: boolean;
    onClose: () => void;
}
const GameCameraHelpDialog: React.FC<CameraHelpDialogProps> = ({
    open,
    onClose,
}) => {
    const { t } = useTranslation();
   
    return (
        <ContentDialog
            title={t('common:2048.head.help.title')}
            open={open}
            onClose={onClose}>


            <CardStack
                title={t('common:2048.head.help.howToPlay.title')}
                description={t('common:2048.head.help.howToPlay.description')}>
               <MobileHelpVideo src="/media/try-camera.mp4" loop autoPlay aria-label={t('common:2048.head.help.howToPlay.videoAlt')}/>
            </CardStack>
            <CardStack
                title={t('common:2048.head.help.troubleshooting.title')}
                description={t('common:2048.head.help.troubleshooting.description')}>
                <TitleExtraSmall>{t('common:2048.head.help.troubleshooting.permissionErrorTitle')}</TitleExtraSmall>
                <BodySmall>{t('common:2048.head.help.troubleshooting.permissionErrorDescription')}</BodySmall>
                <TitleExtraSmall>{t('common:2048.head.help.troubleshooting.noDeviceErrorTitle')}</TitleExtraSmall>
                <BodySmall>{t('common:2048.head.help.troubleshooting.noDeviceErrorDescription')}</BodySmall>
                <TitleExtraSmall>{t('common:2048.head.help.troubleshooting.noFaceErrorTitle')}</TitleExtraSmall>
                <BodySmall>{t('common:2048.head.help.troubleshooting.noFaceErrorDescription')}</BodySmall>
                <TitleExtraSmall>{t('common:2048.head.help.troubleshooting.sensitivityTitle')}</TitleExtraSmall>
                <BodySmall>{t('common:2048.head.help.troubleshooting.sensitivityDescription')}</BodySmall>
            </CardStack>
            <CardStack
                title={t('common:2048.head.help.camera.title')}
                description={t('common:2048.head.help.camera.description')}>
               <HelpScreenshot src="/media/desktop-camera.png" alt={t('common:2048.head.help.camera.alt')}/>
            </CardStack>

            <CardStack
                title={t('common:2048.head.help.ready.title')}
                description={t('common:2048.head.help.ready.description')}>
               <Button onClick={onClose} $color="primary">{t('common:2048.head.help.ready.start')}</Button>
            </CardStack>
            <CardStack
                title={t('common:2048.download.title')}
                description={t('common:2048.download.description')}>
                <HelpScreenshot src="/media/how-cephable-works.gif" alt={t('common:2048.download.alt')} />
                <Button href="https://cephable.com/downloads" target="_blank" $color="primary">{t('common:2048.download.download')}</Button>
            </CardStack>
        </ContentDialog>
    );
}

export default GameCameraHelpDialog;