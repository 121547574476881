import { useState } from "react";
import ContentDialog from "../dialog/ContentDialog";
import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardStack from "../card/CardStack";
import FieldLabel from "../text/FieldLabel";
import { SensitivitySlider, SliderPercentage } from "../field/CommonFieldStyles";

interface CameraSettingsDialogProps {
    settings: CameraSettings;
    open: boolean;
    onClose: () => void;
    onConfirm: (settings: CameraSettings) => void;
}
export interface CameraSettings {
    sensitivity: number;
}
const CameraSettingsDialog: React.FC<CameraSettingsDialogProps> = ({
    settings,
    open,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation();
    const [localSettings, setSettings] = useState<CameraSettings>({ sensitivity: settings.sensitivity ?? 50 });
    const handleConfirm = () => {
        onConfirm(localSettings);
        localStorage.setItem('cameraSettings', JSON.stringify(localSettings));
        onClose();
    }
    return (
        <ContentDialog
            title={t('common:camera.settings.title')}
            open={open}
            onClose={onClose}
            onConfirm={handleConfirm}>

            <CardStack
                title={t('common:camera.sensitivity')}
                description={t('common:camera.settings.description')}>
                <SensitivitySlider
                    aria-labelledby="tilt"
                    value={localSettings?.sensitivity ?? 50}
                    onChange={(e, v) =>
                        setSettings({ ...localSettings, sensitivity: v as number })
                    }
                    min={0}
                    max={100}
                />
                <SliderPercentage>{localSettings?.sensitivity ?? 50}%</SliderPercentage>
            </CardStack>

        </ContentDialog>
    );
}

export default CameraSettingsDialog;