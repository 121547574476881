import { useState } from "react";
import ContentDialog from "../dialog/ContentDialog";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardStack from "../card/CardStack";
import FieldLabel from "../text/FieldLabel";
import { SensitivitySlider, SliderPercentage } from "../field/CommonFieldStyles";

interface VirtualButtonSettingsDialogProps {
    settings: VirtualButtonSettings;
    open: boolean;
    onClose: () => void;
    onConfirm: (settings: VirtualButtonSettings) => void;
}
export interface VirtualButtonSettings {
    buttonSize: "small" | "medium" | "large";
}
const VirtualButtonSettingsDialog: React.FC<VirtualButtonSettingsDialogProps> = ({
    settings,
    open,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation();
    const [localSettings, setSettings] = useState<VirtualButtonSettings>({ buttonSize: settings.buttonSize ?? "medium" });
    const handleConfirm = () => {
        onConfirm(localSettings);
        localStorage.setItem('virtualButtonSettings', JSON.stringify(localSettings));
        onClose();
    }
    return (
        <ContentDialog
            title={t('common:hotkey.settings.title')}
            open={open}
            onClose={onClose}
            onConfirm={handleConfirm}>

            <CardStack
                title={t('common:hotkey.settings.buttonSize')}
                description={t('common:hotkey.settings.description')}>
                <FormControl fullWidth>
                    <InputLabel id={'size-select'}>
                        {t('common:hotkey.settings.buttonSize')}
                    </InputLabel>
                    <Select
                        labelId={'size-select'}
                        value={localSettings.buttonSize}
                        label={t('common:hotkey.settings.buttonSize')}
                        onChange={(e) =>
                            setSettings({ ...localSettings, buttonSize: e.target.value as "small" | "medium" | "large" })
                        }>
                        <MenuItem value={'small'}>{t('common:hotkey.settings.small')}</MenuItem>
                        <MenuItem value={'medium'}>{t('common:hotkey.settings.medium')}</MenuItem>
                        <MenuItem value={'large'}>{t('common:hotkey.settings.large')}</MenuItem>
                    </Select>
                </FormControl>
            </CardStack>

        </ContentDialog>
    );
}

export default VirtualButtonSettingsDialog;