import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVector = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={505} height={581} fill="none" {...props}>
        <path
            fill="url(#Vector_svg__a)"
            d="M457.684 1500.32c-30.12-243.44-94.261-446.21-131.864-628.43-39.122-202.555-86.469-422.65 73.305-576.105 116.836-115.344 315.292-105.303 457.472-38.511 151.373 81.241 235.493 212.117 137.965 476.192 73.888-97.375 111.808-218.289 97.368-323.135C1045.97 77.757 778.061-1.681 594.512.871c-175.52 2.533-394.476 113.857-497.94 328.182-70.68 146.511-99.608 353.409-24.858 728.307 91.609 459.01 54.045 777.53-62.825 1030.07-1.822 3.75-2.923 7.85-5.032 11.45-5.411 9.67-4.948 18.98 12.726 22.58 2.443.48 4.96 1.17 7.476 1.85 136.433 33.89 273.551 45.83 407.721 37.81 3.835-15.95 70.045-305.05 26.044-661.09l-.14.29Z"
            opacity={0.1}
        />
        <defs>
            <linearGradient
                id="Vector_svg__a"
                x1={459.422}
                x2={192.952}
                y1={41.475}
                y2={633.478}
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#6DB9E7" />
                <stop offset={0.07} stopColor="#6CA2D7" />
                <stop offset={0.16} stopColor="#6B8AC6" />
                <stop offset={0.26} stopColor="#6B79BA" />
                <stop offset={0.37} stopColor="#6B6FB3" />
                <stop offset={0.5} stopColor="#6B6CB1" />
                <stop offset={1} stopColor="#062844" />
            </linearGradient>
        </defs>
    </svg>
);
export default SvgVector;
