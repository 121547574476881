import styled from "@emotion/styled";
import { color } from "../../constants/Styles";

export const CardStackLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  padding: 0 0 0 2rem;
`;

export const CardStretchLayout = styled.div`
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem;
  margin: 0 auto;
  & > * {
    flex: 1 1 auto;
  }
`;
export const CardSmallGrid = styled.div`
  max-width: 100vw;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-column-gap: 1rem;
  margin: 1rem auto;
  & > * {
    display: block;
    min-width: 100%;
  }
`;

export const CardMediumGrid = styled.div`
  max-width: 100vw;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-column-gap: 1rem;
  margin: 1rem 2rem;
  justify-items: center;
  & > * {
    display: block;
    min-width: 100%;
  }
`;
