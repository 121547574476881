import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMicrophone = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" viewBox="0 0 20 18" {...props}>
        <path
            fill="currentcolor"
            d="M10 13.75A3.754 3.754 0 0 0 13.75 10V5a3.75 3.75 0 0 0-7.5 0v5A3.754 3.754 0 0 0 10 13.75ZM7.5 5a2.5 2.5 0 1 1 5 0v5a2.5 2.5 0 0 1-5 0V5Zm3.125 11.219v1.906a.624.624 0 1 1-1.25 0v-1.906A6.259 6.259 0 0 1 3.75 10 .625.625 0 0 1 5 10a5 5 0 1 0 10 0 .625.625 0 1 1 1.25 0 6.259 6.259 0 0 1-5.625 6.219Z"
        />
    </svg>
);
export default SvgMicrophone;
