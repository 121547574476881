import styled from '@emotion/styled';
import { FormGroup as MuiFormGroup, Switch as MuiSwitch, Slider } from '@mui/material';
import { DisplaySmall } from '../text/Typography';
import { color } from '../../constants/Styles';

export const SensitivitySlider = styled(Slider)`
    margin: 0 16px;
`;

export const Switch = styled(MuiSwitch)`
    :focus-within {
        outline: 3px solid ${color.accent};
    }
`;

export const SliderPercentage = styled(DisplaySmall)`
    text-align: center;
    margin: 0 0 16px 0;
`.withComponent('p');

export const Align = styled.div`
    width: 100%;
    padding: 1rem 0;
`;

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    gap: 0.5rem;
    & label {
        align-self: flex-start;
    }
`;

export const FormGroup = styled(MuiFormGroup)`
    gap: 0.5rem;
`;
