import ExpressionSettingsModel from '../../models/devices/ExpressionSettingsModel';
import FaceExpression from '../../models/expressionDetection/FaceExpression';
import {
    isTiltUp,
    isTiltDown,
    isTiltLeft,
    isTiltRight,
    isTurnLeft,
    isTurnRight,
    isLeaningLeft,
    isLeaningRight,
    isMovingDown,
    isMovingUp,
} from './ExpressionCommandChecks';
import { MouseMoveControl } from '../../constants/controls/MouseMoveControl';
import {
    ExpressionHeadLeanLeft,
    ExpressionHeadLeanRight,
    ExpressionHeadMoveDown,
    ExpressionHeadMoveUp,
    ExpressionHeadTiltBack,
    ExpressionHeadTiltForward,
    ExpressionHeadTiltLeft,
    ExpressionHeadTiltRight,
    ExpressionHeadTurnLeft,
    ExpressionHeadTurnRight,
} from '../../constants/controls/HeadControls';

// TODO: Update to use rawCommands
export const isMouseMovementDetected = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    expressionMovement: string,
    expressionSettings: ExpressionSettingsModel
): boolean => {
    if (face == null || expressionMovement == null) return false;

    switch (expressionMovement) {
        case ExpressionHeadTiltBack:
            return isTiltUp(face, baselineFace, expressionSettings);
        case ExpressionHeadTiltForward:
            return isTiltDown(face, baselineFace, expressionSettings);
        case ExpressionHeadTiltLeft:
            return isTiltLeft(face, baselineFace, expressionSettings);
        case ExpressionHeadTiltRight:
            return isTiltRight(face, baselineFace, expressionSettings);
        case ExpressionHeadTurnLeft:
            return isTurnLeft(face, baselineFace, expressionSettings);
        case ExpressionHeadTurnRight:
            return isTurnRight(face, baselineFace, expressionSettings);
        case ExpressionHeadLeanLeft:
            return isLeaningLeft(face, baselineFace, expressionSettings);
        case ExpressionHeadLeanRight:
            return isLeaningRight(face, baselineFace, expressionSettings);
        case ExpressionHeadMoveDown:
            return isMovingDown(face, baselineFace, expressionSettings);
        case ExpressionHeadMoveUp:
            return isMovingUp(face, baselineFace, expressionSettings);
        default:
            return false;
    }
};

export const getMouseMoveCommand = (
    face: FaceExpression,
    baselineFace: FaceExpression,
    currentCommands: string[],
    expressionSettings: ExpressionSettingsModel
) => {
    // TODO: handle scroll change
    // TODO: current commands have throttle and check previous face. We shouldn't here.
    // temp: just move 10 at a time if past threshold - should apply distance from trigger to move more
    if (expressionSettings == null) return null;

    let isUp = false;
    let isDown = false;
    let isLeft = false;
    let isRight = false;
    if (
        expressionSettings.mouseMoveDownExpression &&
        isMouseMovementDetected(face, baselineFace, expressionSettings.mouseMoveDownExpression, expressionSettings)
    )
        // && currentCommands.Contains(expressionSettings.MouseMoveDownExpression))
        isDown = true;
    if (
        expressionSettings.mouseMoveUpExpression &&
        isMouseMovementDetected(face, baselineFace, expressionSettings.mouseMoveUpExpression, expressionSettings)
    )
        isUp = true;
    if (
        expressionSettings.mouseMoveLeftExpression &&
        isMouseMovementDetected(face, baselineFace, expressionSettings.mouseMoveLeftExpression, expressionSettings)
    )
        isLeft = true;
    if (
        expressionSettings.mouseMoveRightExpression &&
        isMouseMovementDetected(face, baselineFace, expressionSettings.mouseMoveRightExpression, expressionSettings)
    )
        isRight = true;

    if (!isUp && !isDown && !isLeft && !isRight) return null;

    let yVal = isUp ? -10 : isDown ? 10 : 0;
    let xVal = isLeft ? -10 : isRight ? 10 : 0;
    return MouseMoveControl(xVal, yVal, 0);
};
