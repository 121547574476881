import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExpression = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" viewBox="0 0 90 100" {...props}>
        <path
            fill="#B6B7BD"
            fillRule="evenodd"
            d="M8.555 37.947C10.52 31.708 16.908 23.08 29.73 23.08c3.41 0 6.37.528 8.927 1.416.89-.45 1.834-.85 2.837-1.193-3.226-1.468-7.12-2.383-11.764-2.383-14.015 0-21.07 9.503-23.235 16.378-.721 2.29-.537 4.668-.186 6.827L8.476 57.46a25.082 25.082 0 0 0 9.593 15.956l1.95 1.48a17.809 17.809 0 0 0 9.37 3.568c.108.036.222.055.341.055.176 0 .352-.003.528-.008.176.005.352.008.528.008.119 0 .233-.02.34-.055a17.81 17.81 0 0 0 9.372-3.569l.662-.503a16.878 16.878 0 0 1-.489-.357l-1.306-.992-.173.132a15.648 15.648 0 0 1-8.934 3.175 15.649 15.649 0 0 1-8.934-3.175l-1.95-1.48a22.922 22.922 0 0 1-8.766-14.582L8.441 43.78c-.336-2.069-.451-4.037.114-5.832Z"
            clipRule="evenodd"
        />
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="M32.204 65.008c-4.015.533-7.459-.92-9.514-2.528a1.35 1.35 0 0 0-1.664 2.126c2.74 2.144 7.424 4.005 12.743 2.876a24.013 24.013 0 0 1-1.565-2.474Z"
            clipRule="evenodd"
        />
        <path
            fill="#B6B7BD"
            fillRule="evenodd"
            d="M61.481 24.491c2.411-.885 5.184-1.411 8.349-1.411 13.811 0 20.272 8.664 22.231 14.868.567 1.793.45 3.761.114 5.83l-2.167 13.336a22.922 22.922 0 0 1-8.767 14.582l-1.95 1.48a15.65 15.65 0 0 1-8.933 3.175 15.65 15.65 0 0 1-8.934-3.175l-.223-.17-1.356 1.03c-.145.11-.29.216-.438.32l.711.54a17.81 17.81 0 0 0 9.371 3.57c.107.035.222.054.34.054.177 0 .353-.003.529-.008.175.005.351.008.528.008.119 0 .233-.02.34-.055a17.81 17.81 0 0 0 9.371-3.569l1.95-1.48a25.082 25.082 0 0 0 9.593-15.955l2.167-13.336c.35-2.158.537-4.538-.186-6.828C91.94 30.388 84.772 20.92 69.83 20.92c-4.393 0-8.103.934-11.192 2.428 1.005.33 1.952.715 2.843 1.143Z"
            clipRule="evenodd"
        />
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="M68.124 65.343h9.984a1.35 1.35 0 1 0 0-2.7h-8.659c-.385.928-.828 1.83-1.325 2.7Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M49.858 23.35c-12.673 0-18.978 8.519-20.918 14.678-.546 1.733-.439 3.65-.105 5.707l2.167 13.336a22.652 22.652 0 0 0 8.664 14.41l1.95 1.48a15.38 15.38 0 0 0 8.77 3.12 15.38 15.38 0 0 0 8.77-3.12l1.95-1.48a22.652 22.652 0 0 0 8.663-14.41l2.167-13.336c.334-2.058.443-3.974-.104-5.706C69.9 31.913 63.528 23.35 49.858 23.35Zm1.449 55.382a18.079 18.079 0 0 0 9.482-3.62l1.95-1.48a25.352 25.352 0 0 0 9.695-16.128l2.167-13.336c.353-2.168.549-4.6-.194-6.952-2.21-6.997-9.465-16.566-24.549-16.566-14.164 0-21.303 9.613-23.493 16.567-.74 2.35-.547 4.781-.194 6.951l2.166 13.336a25.352 25.352 0 0 0 9.696 16.128l1.95 1.48a18.079 18.079 0 0 0 9.482 3.62c.124.038.256.058.393.058.176 0 .352-.003.528-.008.175.005.351.008.528.008.136 0 .268-.02.393-.058Z"
            clipRule="evenodd"
        />
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="M45.75 62.476c-1.142.684-1.542 1.442-1.542 2.024 0 .582.4 1.34 1.541 2.024 1.114.669 2.74 1.126 4.609 1.126 1.868 0 3.495-.457 4.609-1.126 1.14-.684 1.54-1.442 1.54-2.024 0-.582-.4-1.34-1.54-2.024-1.114-.669-2.74-1.126-4.61-1.126-1.867 0-3.494.457-4.608 1.126Zm-1.39-2.316c1.6-.96 3.724-1.51 5.998-1.51s4.397.55 5.998 1.51c1.573.945 2.852 2.437 2.852 4.34s-1.279 3.395-2.852 4.34c-1.6.96-3.724 1.51-5.998 1.51s-4.397-.55-5.998-1.51c-1.574-.945-2.852-2.437-2.852-4.34s1.278-3.395 2.852-4.34Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgExpression;
