import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSmile = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
        <path
            fill="#FBFDFE"
            d="m64.266 77.858-2.41 1.83a20.688 20.688 0 0 1-11.857 4.199 20.689 20.689 0 0 1-11.855-4.2l-2.411-1.83A29.682 29.682 0 0 1 24.38 58.976L21.7 42.483c-.424-2.614-.61-5.302.186-7.828 2.553-8.108 10.866-19.32 27.46-19.32 17.78 0 26.207 11.212 28.768 19.32.797 2.525.61 5.214.184 7.828l-2.68 16.492a29.683 29.683 0 0 1-11.352 18.883Z"
        />
        <path
            fill="#000"
            fillRule="evenodd"
            d="M49.347 16.671c-15.857 0-23.758 10.67-26.187 18.386-.7 2.22-.557 4.654-.141 7.212l2.68 16.492A28.347 28.347 0 0 0 36.54 76.794l2.411 1.83A19.354 19.354 0 0 0 50 82.551a19.354 19.354 0 0 0 11.049-3.927l2.41-1.83A28.347 28.347 0 0 0 74.3 58.76l2.68-16.492c.416-2.56.56-4.993-.14-7.211-2.423-7.673-10.413-18.387-27.493-18.387Zm1.726 68.494a22.024 22.024 0 0 0 11.59-4.413l2.41-1.83a31.02 31.02 0 0 0 11.864-19.733l2.68-16.492c.433-2.668.665-5.612-.23-8.444C76.69 25.71 67.826 14 49.347 14c-17.332 0-26.058 11.753-28.735 20.254-.892 2.831-.663 5.774-.23 8.443l2.68 16.492a31.02 31.02 0 0 0 11.863 19.733l2.411 1.83a22.024 22.024 0 0 0 11.59 4.413c.132.044.273.068.42.068.219 0 .436-.003.654-.01.217.007.434.01.652.01.147 0 .289-.024.421-.068Z"
            clipRule="evenodd"
        />
        <path
            fill="#EE486F"
            fillRule="evenodd"
            d="M62.69 65.765c.523.76.33 1.8-.43 2.322-9.637 6.626-18.975 3.62-23.678-.061a1.67 1.67 0 1 1 2.058-2.63c3.83 2.998 11.559 5.557 19.73-.06a1.67 1.67 0 0 1 2.32.43Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgSmile;
