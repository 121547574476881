import styled from '@emotion/styled';
import { color } from '../../constants/Styles';

/**
 * Typography.tsx
 * This file contains the bare minimum typography settings.
 * All core typography settings should be defined here.
 * This is raw typography, no padding or margins should be defined here.
 * For extended typography for content see ContentTypography.tsx
 */

// DISPLAY
export const DisplayLarge = styled.h1`
    font-weight: 900;
    font-size: 3.375rem; // 54px
    color: ${color.text_default};
    line-height: 3.875rem; // 62px
    letter-spacing: -0.02rem;
`;
export const DisplayMedium = styled.h2`
    font-weight: 900;
    font-size: 2.875rem; // 46px
    color: ${color.text_default};
    line-height: 3.25rem; // 52px
`;
export const DisplaySmall = styled.h3`
    font-weight: 900;
    font-size: 2.25rem; // 36px
    color: ${color.text_default};
    line-height: 2.625rem; // 42px
`;

// TITLE
export const TitleLarge = styled.h1`
    font-weight: 700;
    font-size: 2rem; // 32px
    color: ${color.text_default};
    line-height: 2.625rem; // 42px
`;
export const TitleMedium = styled.h2`
    font-weight: 700;
    font-size: 1.75rem; // 28px
    color: ${color.text_default};
    line-height: 2rem; // 32px
`;
export const TitleSmall = styled.h3`
    font-weight: 700;
    font-size: 1.5rem; // 24px
    color: ${color.text_default};
    line-height: 1.75rem; // 28px
    margin: 0 0 1rem 0;
`;
export const TitleExtraSmall = styled.h4`
    font-weight: 500;
    font-size: 1.2rem; 
    color: ${color.text_default};
    line-height: 1.5rem; 
    margin: 0 0 1rem 0;
`;
export const TitleDisplay = styled.h3`
    font-weight: 700;
    font-size: 1.25rem; // 18px
    color: ${color.text_default};
    line-height: 1.625rem; // 26px
    letter-spacing: -0.02rem;
`;

// LABEL
export const LabelLarge = styled.p`
    font-weight: 500;
    font-size: 1.125rem; // 18px
    color: ${color.neutral};
    line-height: 1.5rem; // 24px
`;
export const LabelMedium = styled.p`
    font-weight: 500;
    font-size: 1rem; // 16px
    color: ${color.neutral};
    line-height: 1.313rem; // 21px
`;
export const LabelSmall = styled.p`
    font-weight: 500;
    font-size: 0.875rem; // 14px
    color: ${color.neutral};
    line-height: 1.125rem; // 18px
`;

// BODY
export const BodyLarge = styled.p`
    font-weight: 400;
    font-size: 1.313rem; // 21px
    color: ${color.neutral};
    line-height: 2rem; // 32px
`;
export const BodyMedium = styled.p`
    font-weight: 400;
    font-size: 1.125rem; // 18px
    color: ${color.neutral};
    line-height: 1.625rem; // 26px
`;
export const BodySmall = styled.p`
    font-weight: 400;
    font-size: 1rem; // 16px
    color: ${color.neutral};
    line-height: 1.313rem; // 21px
    margin: 0 0 1rem 0;
`;

// CHIP
export const ChipSmall = styled(LabelSmall)`
    color: ${color.success};
    border: 1px solid ${color.success};
    background-color: #e1e6e1;
    border-radius: 0.375rem;
    padding: 0.25rem 0.5rem;
`;
export const ChipMedium = styled(LabelSmall)`
    color: ${color.success};
    border: 1px solid ${color.success};
    background-color: #e1e6e1;
    border-radius: 0.375rem;
    padding: 0.25rem 0.5rem;
`;
