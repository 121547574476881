import styled from "@emotion/styled";
import CameraControls, { CameraControlsRef } from "../../components/controls/CameraControls";
import Board from "./components/board";
import Score from "./components/score";
import GameProvider, {
  GameContext,
  MoveDirection,
} from "./context/game-context";
import "./styles/globals.css";
import "./styles/index.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import {
  ExpressionHeadTiltBack,
  ExpressionHeadTiltForward,
  ExpressionHeadTurnLeft,
  ExpressionHeadTurnRight,
} from "../../constants/controls/HeadControls";
import { useParams } from "react-router-dom";
import VoiceControls from "../../components/controls/VoiceControls";
import VirtualButtonControls from "../../components/controls/VirtualButtonControls";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/text/ContentTypography";
import { BodyLarge, BodyMedium, TitleMedium } from "../../components/text/Typography";
import GeneralPageTemplate from "../../components/structure/app/GeneralPageTemplate";
import { breakpoint_large, breakpoint_medium, breakpoint_small } from "../../constants/breakpoints";
import VoiceSettingsDialog, { VoiceSettings } from "../../components/controls/VoiceSettingsDialog";
import CameraSettingsDialog, { CameraSettings } from "../../components/controls/CameraSettingsDialog";
import VirtualButtonSettingsDialog, { VirtualButtonSettings } from "../../components/controls/VirtualButtonSettingsDialog";
import GameButtonHelpDialog from "./components/GameButtonHelpDialog";
import GameVoiceHelpDialog from "./components/GameVoiceHelpDialog";
import GameCameraHelpDialog from "./components/GameCameraHelpDialog";
import EndGameDialog from "./components/EndGameDialog";

const GamePage2048 = () => {
  const { inputType } = useParams();

  return (
    <GameProvider>
      <InnerPage inputType={inputType} />
    </GameProvider>
  );
};

const InnerPage: React.FC<{ inputType: string }> = ({ inputType }) => {
  const { moveTiles, startGame, isGameOver } = useContext(GameContext);
  const [voiceSetttings, setVoiceSettings] = useState<VoiceSettings>(
    localStorage.getItem("voiceSettings")
      ? JSON.parse(localStorage.getItem("voiceSettings"))
      : { isOptimistic: false });
  const [cameraSettings, setCameraSettings] = useState<CameraSettings>(
    localStorage.getItem("cameraSettings")
      ? JSON.parse(localStorage.getItem("cameraSettings"))
      : { sensitivity: 50 });
  const [virtualButtonSettings, setVirtualButtonSettings] = useState<VirtualButtonSettings>(
    localStorage.getItem("virtualButtonSettings")
      ? JSON.parse(localStorage.getItem("virtualButtonSettings"))
      : { buttonSize: "medium" });
  const { t } = useTranslation();
  const [showHelp, setShowHelp] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showGameOver, setShowGameOver] = useState(false);
  const cameraRef = useRef<CameraControlsRef>();

  const handleCameraCommand = (commands: string[]) => {
    for (const command of commands) {
      switch (command) {
        case ExpressionHeadTiltBack:
          moveTiles("move_up");
          break;
        case ExpressionHeadTiltForward:
          moveTiles("move_down");
          break;
        case ExpressionHeadTurnLeft:
          moveTiles("move_left");
          break;
        case ExpressionHeadTurnRight:
          moveTiles("move_right");
          break;
      }
    }
  };
  const handleVoiceCommand = (command: string) => {
    switch (command) {
      case "up":
        moveTiles("move_up");
        break;
      case "down":
        moveTiles("move_down");
        break;
      case "left":
        moveTiles("move_left");
        break;
      case "right":
        moveTiles("move_right");
        break;
      case "restart":
        startGame();
        break;
    }
  };
  const handleHotkeyClick = (hotkey: { command: string }) => {
    moveTiles(hotkey.command as MoveDirection);
  };
  const handleHelp = () => {
    setShowHelp(!showHelp);
  };
  const handleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleRestartGame = () => {
    setShowGameOver(false);
    startGame();
  }

  useEffect(() => {
    // if we haven't seen the help section for voice, show it
    if (!localStorage.getItem(`${inputType}2048Help`)) {
      setShowHelp(true);
      localStorage.setItem(`${inputType}2048Help`, "true");
    }
  }, []);

  useEffect(() => {
    if (isGameOver) {
      console.log('game over')
      setShowGameOver(true);
    }
  }, [isGameOver])

  const renderInnerOptionsDialog = () => {
    switch (inputType) {
      case "voice":
        return (
          <VoiceSettingsDialog
            settings={voiceSetttings}
            open={true}
            onClose={() => setShowOptions(false)}
            onConfirm={(settings) => {
              setVoiceSettings(settings)
              setShowOptions(false)
            }}
          />
        );
      case "head":
        return (
          <CameraSettingsDialog
            settings={cameraSettings}
            open={true}
            onClose={() => setShowOptions(false)}
            onConfirm={(settings) => {
              setCameraSettings(settings);
              setShowOptions(false);
            }}
          />
        );
      case "buttons":
        return (
          <VirtualButtonSettingsDialog
            settings={virtualButtonSettings}
            open={true}
            onClose={() => setShowOptions(false)}
            onConfirm={(settings) => {
              setVirtualButtonSettings(settings);
              setShowOptions(false);
            }}
          />);
      default:
        return null;
    }
  }

  const renderInnerHelpDialog = () => {
    switch (inputType) {
      case "voice":
        return <GameVoiceHelpDialog onClose={() => setShowHelp(false)} open />;
      case "head":
        return <GameCameraHelpDialog onClose={() => {
          setShowHelp(false);
          cameraRef.current?.updateBaseline();
        }} open />;
      case "buttons":
        return <GameButtonHelpDialog onClose={() => setShowHelp(false)} open />;
      default:
        return null;
    }
  }



  return (
    <GeneralPageTemplate
      title={t("common:2048.title")}
      items={[
        { title: "Help", onClick: handleHelp },
        { title: "Options", onClick: handleOptions },
      ]}
    >
      <Score />
      <GamePage>
        <HelpContainer>
          {inputType === "head" && (
            <>
              <TitleMedium>{t("common:2048.head.title")}</TitleMedium>
              <BodyMedium>{t("common:2048.head.description")}</BodyMedium>
            </>
          )}
          {inputType === "voice" && (
            <>
              <TitleMedium>{t("common:2048.voice.title")}</TitleMedium>
              <BodyMedium>{t("common:2048.voice.description")}</BodyMedium>
            </>
          )}
          {inputType === "buttons" && (
            <>
              <TitleMedium>{t("common:2048.hotkeys.title")}</TitleMedium>
              <BodyMedium>{t("common:2048.hotkeys.description")}</BodyMedium>
            </>
          )}
        </HelpContainer>
        <Board />
        <ControlsContainer>
          {inputType === "head" && (
            <CameraControls ref={cameraRef} onCommandsDetected={handleCameraCommand} sensitivity={cameraSettings.sensitivity} useBaselineCountdown/>
          )}
          {inputType === "voice" && (
            <VoiceControls
              commands={["up", "down", "left", "right", "restart"]}
              onCommandDetected={handleVoiceCommand}
              isOptimisticVoiceModel={voiceSetttings.isOptimistic}
            />
          )}
          {inputType == "buttons" && (
            <VirtualButtonControls
              hotkeys={[
                {
                  displayName: "Up",
                  command: "move_up",
                },
                {
                  displayName: "Down",
                  command: "move_down",
                },
                {
                  displayName: "Left",
                  command: "move_left",
                },
                {
                  displayName: "Right",
                  command: "move_right",
                },
              ]}
              onHotkeyClick={handleHotkeyClick}
              buttonSize={virtualButtonSettings.buttonSize}
            />
          )}
        </ControlsContainer>
      </GamePage>

      {showOptions && renderInnerOptionsDialog()}
      {showHelp && renderInnerHelpDialog()}
      {showGameOver && <EndGameDialog onClose={handleRestartGame} inputType={inputType} open />}
    </GeneralPageTemplate>
  );
};

const HelpContainer = styled.div`
  width: 25%;
  min-width: 300px;
 ${breakpoint_small} {
    width: 0%;
    display: none;
  }
  @media (max-width: 1080px) {
    display: none;
  }
`;

const GamePage = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  > * {
    flex: 1;
    margin: 16px;
  }
`;

const ControlsContainer = styled.div`
  width: 25%;
  min-width: 200px;
  height: 480px;

  @media (max-width: 748px)  {
    margin-top: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom:60px;
  }
`;

export default GamePage2048;
