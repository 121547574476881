import Toolbar, { IToolbarItem } from "./Toolbar";
import ToolbarPageContainer from "./ToolbarPageContainer";
import Footer from "./Footer";

export interface IGeneralPageTemplateProps {
  children: React.ReactNode;
  title: string;
  items?: IToolbarItem[];
  onBack?: () => boolean;
  footerItems?: IToolbarItem[];
}
/**
 * This template is used for various pages .
 * It provides a toolbar and a container for the page content.
 */

function GeneralPageTemplate({
  children,
  title,
  items = [],
  footerItems = [],
  onBack,
}: IGeneralPageTemplateProps) {
  return (
    <>
      <Toolbar items={items} title={title} withBackButton onBack={onBack} />
      <ToolbarPageContainer>{children}</ToolbarPageContainer>
      <Footer items={footerItems} />
    </>
  );
}

export default GeneralPageTemplate;
