import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMotionControl = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" viewBox="0 0 100 100" {...props}>
        <path fill="currentColor" fillOpacity={0.3} d="m23.528 38.31 7.865-2.689.667 1.95-7.865 2.69-.667-1.95Z" />
        <path
            fill="currentColor"
            fillOpacity={0.3}
            fillRule="evenodd"
            d="m48.63 77 19.197 6.563a4.26 4.26 0 0 0 5.41-2.653L86.572 41.9a4.26 4.26 0 0 0-2.653-5.409L63.353 29.46c.393.556.63 1.232.646 1.964l19.388 6.628a2.611 2.611 0 0 1 1.626 3.316l-13.337 39.01a2.611 2.611 0 0 1-3.315 1.626L53.727 77h-5.098Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillOpacity={0.3}
            fillRule="evenodd"
            d="M35.498 29.716A3.431 3.431 0 0 0 35 31.5v.129L16.213 38.05a2.611 2.611 0 0 0-1.626 3.316l13.337 39.01a2.611 2.611 0 0 0 3.316 1.626L45.873 77h5.098l-19.198 6.563a4.26 4.26 0 0 1-5.41-2.653L13.028 41.9a4.26 4.26 0 0 1 2.653-5.409l19.818-6.776Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M34.34 32.483c0-2.467 2-4.467 4.466-4.467h21.988c2.467 0 4.466 2 4.466 4.467V73.71c0 2.466-2 4.466-4.466 4.466H38.806c-2.466 0-4.466-2-4.466-4.466V32.483Zm4.466-2.405a2.405 2.405 0 0 0-2.405 2.405V73.71a2.405 2.405 0 0 0 2.405 2.404h21.988a2.405 2.405 0 0 0 2.405-2.404V32.483a2.405 2.405 0 0 0-2.405-2.405H38.806Z"
            clipRule="evenodd"
        />
        <path fill="currentColor" d="M44.99 32.483h9.62v2.06h-9.62v-2.06Z" />
        <path
            fill="#E51546"
            fillRule="evenodd"
            d="m9.918 27.667-.22-3.962a1.35 1.35 0 0 0-2.696.15l.409 7.356 7.686 1.618a1.35 1.35 0 1 0 .556-2.642l-3.658-.77C16.941 24.768 30.075 16.7 49.448 16.7c19.2 0 33.117 8.332 38.402 12.739l-3.553.748a1.35 1.35 0 0 0 .556 2.642l7.686-1.618.409-7.356a1.35 1.35 0 0 0-2.696-.15l-.224 4.042C84.638 23.068 69.89 14 49.448 14c-20.329 0-34.226 8.552-39.53 13.667Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgMotionControl;
