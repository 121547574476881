import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPreview = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" viewBox="0 0 20 20" {...props}>
        <path
            fill="currentColor"
            d="M10 13.91c1.136 0 2.102-.399 2.898-1.194.795-.795 1.193-1.761 1.193-2.898 0-1.136-.398-2.102-1.193-2.898-.796-.795-1.762-1.193-2.898-1.193-1.136 0-2.102.398-2.898 1.193-.795.796-1.193 1.762-1.193 2.898 0 1.136.398 2.103 1.193 2.898.796.795 1.762 1.193 2.898 1.193Zm0-1.637a2.367 2.367 0 0 1-1.739-.716 2.367 2.367 0 0 1-.716-1.739c0-.682.24-1.261.716-1.738A2.367 2.367 0 0 1 10 7.364c.682 0 1.261.238 1.739.716.477.477.715 1.056.715 1.738 0 .682-.238 1.261-.715 1.739a2.367 2.367 0 0 1-1.739.716Zm0 4.363c-2.212 0-4.227-.617-6.045-1.852C2.136 13.55.818 11.894 0 9.818c.818-2.076 2.136-3.73 3.955-4.966C5.773 3.617 7.788 3 10 3c2.212 0 4.227.617 6.046 1.852 1.818 1.235 3.136 2.89 3.954 4.966-.818 2.076-2.136 3.731-3.954 4.966-1.819 1.235-3.834 1.852-6.046 1.852Zm0-1.818c1.712 0 3.284-.45 4.716-1.352A8.885 8.885 0 0 0 18 9.818a8.885 8.885 0 0 0-3.284-3.648A8.677 8.677 0 0 0 10 4.818c-1.712 0-3.284.45-4.716 1.352A8.884 8.884 0 0 0 2 9.818a8.884 8.884 0 0 0 3.284 3.648A8.677 8.677 0 0 0 10 14.818Z"
        />
    </svg>
);
export default SvgPreview;
