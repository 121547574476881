import {
  AddRow,
  Ai,
  Airbnb,
  Alert,
  Amazon,
  AmazonMusic,
  AndroidOs,
  AndroidTablet,
  AppStore,
  AppleFitness,
  AppleLogo,
  AppleNews,
  ApplePay,
  AppleTv,
  AppleWatch,
  AtlassianConfluence,
  Atm,
  Audible,
  Automation,
  Back,
  Bing,
  Bluetooth2,
  Bug,
  CallOfDutyBlackOps3,
  CallOfDutyMobile,
  Camera,
  CameraSwitch,
  Cancel,
  Cancel2,
  Chat,
  Check,
  Chrome,
  Chromebook,
  Circle,
  Close,
  Cnn,
  Code,
  ComputerMouse,
  ConnectionOffline,
  ConnectionStatusOn,
  Contacts,
  CounterStrike,
  CreateOrder,
  Delete,
  DeleteRow,
  Details,
  DeviceManager,
  DictationPause,
  DictationPlay,
  Discord,
  DisneyMovies,
  DockingStation,
  DoomLogo,
  DownLeftArrow,
  DownRightArrow,
  DragListDown,
  DragListUp,
  Duplicate,
  Easy,
  Edit,
  EpicGames,
  Error,
  Expression,
  ExternalLink,
  Eye,
  EyebrowRaise,
  FaceExpression,
  Facebook,
  FacebookMessenger,
  Facetime,
  Files,
  FindIphone,
  FireTv,
  Fortnite,
  Futures,
  Github,
  Gmail,
  GmailLogo,
  Google,
  GoogleCalendar,
  GoogleClassroom,
  GoogleDocs,
  GoogleDrawing,
  GoogleDrive,
  GoogleEarth,
  GoogleForms,
  GoogleMeet,
  GooglePixel6,
  GooglePlay,
  GooglePlayMusic,
  GooglePlus,
  GoogleSheets,
  GoogleSlides,
  Grammarly,
  HboMax,
  HeadMovement,
  HeadMovement1,
  HeadTurning,
  HeadUpAndDown,
  Health,
  Hide,
  Home,
  Hub,
  Hulu,
  Icloud,
  Idea,
  Imac,
  Imovie,
  InsertRow,
  InsertRowAbove,
  Instagram,
  IosLogo,
  IosPhotos,
  Ipad,
  IpadHomeScreen,
  Iphone,
  Itunes,
  Jira,
  Joystick,
  Key0,
  Key1,
  Key2,
  Key3,
  Key4,
  Key5,
  Key6,
  Key7,
  Key8,
  Key9,
  KeyA,
  KeyAlt,
  KeyAltgr,
  KeyAsterisk,
  KeyB,
  KeyBackspace,
  KeyC,
  KeyCapsLock,
  KeyCapslockText,
  KeyCmd,
  KeyCoinsButton,
  KeyCommand,
  KeyControl,
  KeyCtrl,
  KeyD,
  KeyDelete,
  KeyDelete2,
  KeyDot,
  KeyE,
  KeyEndButton,
  KeyEnter,
  KeyEnterMac,
  KeyEqual,
  KeyEsc,
  KeyEscMac,
  KeyF,
  KeyF1,
  KeyF10,
  KeyF11,
  KeyF12,
  KeyF2,
  KeyF3,
  KeyF4,
  KeyF5,
  KeyF6,
  KeyF7,
  KeyF8,
  KeyF9,
  KeyFn,
  KeyFunctionMac,
  KeyG,
  KeyH,
  KeyHashtag,
  KeyHomeButton,
  KeyI,
  KeyJ,
  KeyK,
  KeyL,
  KeyLeftAngleParentheses,
  KeyM,
  KeyMinus,
  KeyN,
  KeyO,
  KeyOption,
  KeyP,
  KeyPageDownButton,
  KeyPageUpButton,
  KeyPlus,
  KeyQ,
  KeyR,
  KeyRightAngleParentheses,
  KeyS,
  KeySolidus,
  KeySpace,
  KeyT,
  KeyTab,
  KeyU,
  KeyV,
  KeyW,
  KeyWindows,
  KeyX,
  KeyY,
  KeyZ,
  Keyboard,
  Launchpad,
  Layers,
  LeanFrontToBack,
  Library,
  Linkedin,
  Logo,
  Logo2KLogo,
  MacbookProM1,
  Mail,
  Mastercard,
  Messages,
  Microphone,
  MicrosoftEdge,
  MicrosoftExcel,
  MicrosoftOnenote,
  MicrosoftOnenote2,
  MicrosoftOutlook,
  MicrosoftPowerpoint,
  MicrosoftTeams2019,
  MicrosoftToDoApp,
  MicrosoftWord,
  Money,
  More,
  Motherboard,
  MotionControl,
  MotionControl1,
  MotionControl2,
  Mouse,
  MouseLeftClick,
  MouseMiddleClick,
  MouseRightClick,
  MouseScrolling,
  Move,
  MoveDown,
  MoveUp,
  MultipleDevices,
  Music,
  NaturalUserInterface1,
  NaturalUserInterface2,
  Netflix,
  NewYorkTimes,
  NintendoSwitchLogo,
  Nod,
  Notes,
  Octopus,
  Office365,
  OpenCloseMouth,
  PandoraApp,
  Pause,
  Pause2,
  Paypal,
  Phone,
  Play,
  Playstation,
  Playstore,
  Plus,
  PlusLight,
  PowerBi,
  Preferences,
  Preview,
  Process,
  Ps4,
  PurchaseOrder,
  Read,
  Reminders,
  Repeat,
  ReplicateRows,
  RockstarGames,
  Safari,
  SanAndreas,
  Save,
  Search,
  SelfServiceKiosk,
  Sensitivity,
  Settings,
  ShakePhone,
  Share,
  Shazam,
  Shift,
  Shift2,
  ShiftMac,
  Showtime,
  SignalApp,
  Siri,
  Slack,
  SmartWatch,
  Smile,
  Soundcloud,
  Sparkles,
  Speaker,
  Speedometer,
  Spotify,
  SquaredMenu,
  Ssd,
  Stop,
  SvgCephy,
  SwitchControl,
  SwitchControl1,
  SwitchControl2,
  SwitchSimple,
  Symantec,
  Sync,
  TabKey2,
  TabMac,
  TapPause,
  TapPlay,
  TapStop,
  Tiktok,
  Timer,
  Todoist,
  Tools,
  Touchpad,
  Trello,
  Tripadvisor,
  Tv,
  Twitch,
  Twitterx,
  Uber,
  Unity,
  UnrealEngine,
  Usb2,
  UsbC,
  UsbOff,
  User,
  UserGroups,
  Vector,
  VirtualButton,
  VirtualReality,
  VisualStudio,
  Voice,
  VoiceControl,
  VoiceMemos,
  Warning,
  Waze,
  Weather,
  Wechat,
  Wellsfargo,
  Whatsapp,
  WiFi,
  Windows11,
  Xfinity,
  Yelp,
  Youtube,
  Zoom,
} from "./icons/all/index";

interface IconMap {
  [key: string]: JSX.Element;
}

export type IconName =
  | "addRow"
  | "ai"
  | "airbnb"
  | "alert"
  | "amazon"
  | "amazonMusic"
  | "androidOs"
  | "androidTablet"
  | "appStore"
  | "appleFitness"
  | "appleLogo"
  | "appleNews"
  | "applePay"
  | "appleTv"
  | "appleWatch"
  | "atlassianConfluence"
  | "atm"
  | "audible"
  | "automation"
  | "back"
  | "bing"
  | "bluetooth2"
  | "bug"
  | "callOfDutyBlackOps3"
  | "callOfDutyMobile"
  | "camera"
  | "cameraSwitch"
  | "cancel"
  | "cancel2"
  | "chat"
  | "check"
  | "chrome"
  | "chromebook"
  | "circle"
  | "close"
  | "cnn"
  | "code"
  | "computerMouse"
  | "connectionOffline"
  | "connectionStatusOn"
  | "contacts"
  | "counterStrike"
  | "createOrder"
  | "delete"
  | "deleteRow"
  | "details"
  | "deviceManager"
  | "dictationPause"
  | "dictationPlay"
  | "discord"
  | "disneyMovies"
  | "dockingStation"
  | "doomLogo"
  | "downLeftArrow"
  | "downRightArrow"
  | "dragListDown"
  | "dragListUp"
  | "duplicate"
  | "easy"
  | "edit"
  | "epicGames"
  | "error"
  | "expression"
  | "externalLink"
  | "eye"
  | "eyebrowRaise"
  | "faceExpression"
  | "facebook"
  | "facebookMessenger"
  | "facetime"
  | "files"
  | "findIphone"
  | "fireTv"
  | "fortnite"
  | "futures"
  | "github"
  | "gmail"
  | "gmailLogo"
  | "google"
  | "googleCalendar"
  | "googleClassroom"
  | "googleDocs"
  | "googleDrawing"
  | "googleDrive"
  | "googleEarth"
  | "googleForms"
  | "googleMeet"
  | "googlePixel6"
  | "googlePlay"
  | "googlePlayMusic"
  | "googlePlus"
  | "googleSheets"
  | "googleSlides"
  | "grammarly"
  | "hboMax"
  | "headMovement"
  | "headMovement1"
  | "headTurning"
  | "headUpAndDown"
  | "health"
  | "hide"
  | "home"
  | "hub"
  | "hulu"
  | "icloud"
  | "idea"
  | "imac"
  | "imovie"
  | "insertRow"
  | "insertRowAbove"
  | "instagram"
  | "iosLogo"
  | "iosPhotos"
  | "ipad"
  | "ipadHomeScreen"
  | "iphone"
  | "itunes"
  | "jira"
  | "joystick"
  | "key0"
  | "key1"
  | "key2"
  | "key3"
  | "key4"
  | "key5"
  | "key6"
  | "key7"
  | "key8"
  | "key9"
  | "keyA"
  | "keyAlt"
  | "keyAltgr"
  | "keyAsterisk"
  | "keyB"
  | "keyBackspace"
  | "keyC"
  | "keyCapsLock"
  | "keyCapslockText"
  | "keyCmd"
  | "keyCoinsButton"
  | "keyCommand"
  | "keyControl"
  | "keyCtrl"
  | "keyD"
  | "keyDelete"
  | "keyDelete2"
  | "keyDot"
  | "keyE"
  | "keyEndButton"
  | "keyEnter"
  | "keyEnterMac"
  | "keyEqual"
  | "keyEsc"
  | "keyEscMac"
  | "keyF"
  | "keyF1"
  | "keyF10"
  | "keyF11"
  | "keyF12"
  | "keyF2"
  | "keyF3"
  | "keyF4"
  | "keyF5"
  | "keyF6"
  | "keyF7"
  | "keyF8"
  | "keyF9"
  | "keyFn"
  | "keyFunctionMac"
  | "keyG"
  | "keyH"
  | "keyHashtag"
  | "keyHomeButton"
  | "keyI"
  | "keyJ"
  | "keyK"
  | "keyL"
  | "keyLeftAngleParentheses"
  | "keyM"
  | "keyMinus"
  | "keyN"
  | "keyO"
  | "keyOption"
  | "keyP"
  | "keyPageDownButton"
  | "keyPageUpButton"
  | "keyPlus"
  | "keyQ"
  | "keyR"
  | "keyRightAngleParentheses"
  | "keyS"
  | "keySolidus"
  | "keySpace"
  | "keyT"
  | "keyTab"
  | "keyU"
  | "keyV"
  | "keyW"
  | "keyWindows"
  | "keyX"
  | "keyY"
  | "keyZ"
  | "keyboard"
  | "launchpad"
  | "layers"
  | "leanFrontToBack"
  | "library"
  | "linkedin"
  | "logo"
  | "logo2KLogo"
  | "macbookProM1"
  | "mail"
  | "mastercard"
  | "messages"
  | "microphone"
  | "microsoftEdge"
  | "microsoftExcel"
  | "microsoftOnenote"
  | "microsoftOnenote2"
  | "microsoftOutlook"
  | "microsoftPowerpoint"
  | "microsoftTeams2019"
  | "microsoftToDoApp"
  | "microsoftWord"
  | "money"
  | "more"
  | "motherboard"
  | "motionControl"
  | "motionControl1"
  | "motionControl2"
  | "mouse"
  | "mouseLeftClick"
  | "mouseMiddleClick"
  | "mouseRightClick"
  | "mouseScrolling"
  | "move"
  | "moveDown"
  | "moveUp"
  | "multipleDevices"
  | "music"
  | "naturalUserInterface1"
  | "naturalUserInterface2"
  | "netflix"
  | "newYorkTimes"
  | "nintendoSwitchLogo"
  | "nod"
  | "notes"
  | "octopus"
  | "office365"
  | "openCloseMouth"
  | "pandoraApp"
  | "pause"
  | "pause2"
  | "paypal"
  | "phone"
  | "play"
  | "playstation"
  | "playstore"
  | "plus"
  | "plusLight"
  | "powerBi"
  | "preferences"
  | "preview"
  | "process"
  | "ps4"
  | "purchaseOrder"
  | "read"
  | "reminders"
  | "repeat"
  | "replicateRows"
  | "rockstarGames"
  | "safari"
  | "sanAndreas"
  | "save"
  | "search"
  | "selfServiceKiosk"
  | "sensitivity"
  | "settings"
  | "shakePhone"
  | "share"
  | "shazam"
  | "shift"
  | "shift2"
  | "shiftMac"
  | "showtime"
  | "signalApp"
  | "siri"
  | "slack"
  | "smartWatch"
  | "smile"
  | "soundcloud"
  | "sparkles"
  | "speaker"
  | "speedometer"
  | "spotify"
  | "squaredMenu"
  | "ssd"
  | "stop"
  | "svgCephy"
  | "switchControl"
  | "switchControl1"
  | "switchControl2"
  | "switchSimple"
  | "symantec"
  | "sync"
  | "tabKey2"
  | "tabMac"
  | "tapPause"
  | "tapPlay"
  | "tapStop"
  | "tiktok"
  | "timer"
  | "todoist"
  | "tools"
  | "touchpad"
  | "trello"
  | "tripadvisor"
  | "tv"
  | "twitch"
  | "twitterx"
  | "uber"
  | "unity"
  | "unrealEngine"
  | "usb2"
  | "usbC"
  | "usbOff"
  | "user"
  | "userGroups"
  | "vector"
  | "virtualButton"
  | "virtualReality"
  | "visualStudio"
  | "voice"
  | "voiceControl"
  | "voiceMemos"
  | "warning"
  | "waze"
  | "weather"
  | "wechat"
  | "wellsfargo"
  | "whatsapp"
  | "wiFi"
  | "windows11"
  | "xfinity"
  | "yelp"
  | "youtube"
  | "zoom";

export const iconMap: IconMap = {
  addRow: <AddRow />,
  ai: <Ai />,
  airbnb: <Airbnb />,
  alert: <Alert />,
  amazon: <Amazon />,
  amazonMusic: <AmazonMusic />,
  androidOs: <AndroidOs />,
  androidTablet: <AndroidTablet />,
  appStore: <AppStore />,
  appleFitness: <AppleFitness />,
  appleLogo: <AppleLogo />,
  appleNews: <AppleNews />,
  applePay: <ApplePay />,
  appleTv: <AppleTv />,
  appleWatch: <AppleWatch />,
  atlassianConfluence: <AtlassianConfluence />,
  atm: <Atm />,
  audible: <Audible />,
  automation: <Automation />,
  back: <Back />,
  bing: <Bing />,
  bluetooth2: <Bluetooth2 />,
  bug: <Bug />,
  callOfDutyBlackOps3: <CallOfDutyBlackOps3 />,
  callOfDutyMobile: <CallOfDutyMobile />,
  camera: <Camera />,
  cameraSwitch: <CameraSwitch />,
  cancel: <Cancel />,
  cancel2: <Cancel2 />,
  chat: <Chat />,
  check: <Check />,
  chrome: <Chrome />,
  chromebook: <Chromebook />,
  circle: <Circle />,
  cnn: <Cnn />,
  close: <Close />,
  code: <Code />,
  computerMouse: <ComputerMouse />,
  connectionOffline: <ConnectionOffline />,
  connectionStatusOn: <ConnectionStatusOn />,
  contacts: <Contacts />,
  counterStrike: <CounterStrike />,
  createOrder: <CreateOrder />,
  delete: <Delete />,
  deleteRow: <DeleteRow />,
  details: <Details />,
  deviceManager: <DeviceManager />,
  dictationPause: <DictationPause />,
  dictationPlay: <DictationPlay />,
  discord: <Discord />,
  disneyMovies: <DisneyMovies />,
  dockingStation: <DockingStation />,
  doomLogo: <DoomLogo />,
  downLeftArrow: <DownLeftArrow />,
  downRightArrow: <DownRightArrow />,
  dragListDown: <DragListDown />,
  dragListUp: <DragListUp />,
  duplicate: <Duplicate />,
  easy: <Easy />,
  edit: <Edit />,
  epicGames: <EpicGames />,
  error: <Error />,
  expression: <Expression />,
  externalLink: <ExternalLink />,
  eye: <Eye />,
  eyebrowRaise: <EyebrowRaise />,
  faceExpression: <FaceExpression />,
  facebook: <Facebook />,
  facebookMessenger: <FacebookMessenger />,
  facetime: <Facetime />,
  files: <Files />,
  findIphone: <FindIphone />,
  fireTv: <FireTv />,
  fortnite: <Fortnite />,
  futures: <Futures />,
  github: <Github />,
  gmail: <Gmail />,
  gmailLogo: <GmailLogo />,
  google: <Google />,
  googleCalendar: <GoogleCalendar />,
  googleClassroom: <GoogleClassroom />,
  googleDocs: <GoogleDocs />,
  googleDrawing: <GoogleDrawing />,
  googleDrive: <GoogleDrive />,
  googleEarth: <GoogleEarth />,
  googleForms: <GoogleForms />,
  googleMeet: <GoogleMeet />,
  googlePixel6: <GooglePixel6 />,
  googlePlay: <GooglePlay />,
  googlePlayMusic: <GooglePlayMusic />,
  googlePlus: <GooglePlus />,
  googleSheets: <GoogleSheets />,
  googleSlides: <GoogleSlides />,
  grammarly: <Grammarly />,
  hboMax: <HboMax />,
  headMovement: <HeadMovement />,
  headMovement1: <HeadMovement1 />,
  headTurning: <HeadTurning />,
  headUpAndDown: <HeadUpAndDown />,
  health: <Health />,
  hide: <Hide />,
  home: <Home />,
  hub: <Hub />,
  hulu: <Hulu />,
  icloud: <Icloud />,
  idea: <Idea />,
  imac: <Imac />,
  imovie: <Imovie />,
  insertRow: <InsertRow />,
  insertRowAbove: <InsertRowAbove />,
  instagram: <Instagram />,
  iosLogo: <IosLogo />,
  iosPhotos: <IosPhotos />,
  ipad: <Ipad />,
  ipadHomeScreen: <IpadHomeScreen />,
  iphone: <Iphone />,
  itunes: <Itunes />,
  jira: <Jira />,
  joystick: <Joystick />,
  key0: <Key0 />,
  key1: <Key1 />,
  key2: <Key2 />,
  key3: <Key3 />,
  key4: <Key4 />,
  key5: <Key5 />,
  key6: <Key6 />,
  key7: <Key7 />,
  key8: <Key8 />,
  key9: <Key9 />,
  keyA: <KeyA />,
  keyAlt: <KeyAlt />,
  keyAltgr: <KeyAltgr />,
  keyAsterisk: <KeyAsterisk />,
  keyB: <KeyB />,
  keyBackspace: <KeyBackspace />,
  keyC: <KeyC />,
  keyCapsLock: <KeyCapsLock />,
  keyCapslockText: <KeyCapslockText />,
  keyCmd: <KeyCmd />,
  keyCoinsButton: <KeyCoinsButton />,
  keyCommand: <KeyCommand />,
  keyControl: <KeyControl />,
  keyCtrl: <KeyCtrl />,
  keyD: <KeyD />,
  keyDelete: <KeyDelete />,
  keyDelete2: <KeyDelete2 />,
  keyDot: <KeyDot />,
  keyE: <KeyE />,
  keyEndButton: <KeyEndButton />,
  keyEnter: <KeyEnter />,
  keyEnterMac: <KeyEnterMac />,
  keyEqual: <KeyEqual />,
  keyEsc: <KeyEsc />,
  keyEscMac: <KeyEscMac />,
  keyF: <KeyF />,
  keyF1: <KeyF1 />,
  keyF10: <KeyF10 />,
  keyF11: <KeyF11 />,
  keyF12: <KeyF12 />,
  keyF2: <KeyF2 />,
  keyF3: <KeyF3 />,
  keyF4: <KeyF4 />,
  keyF5: <KeyF5 />,
  keyF6: <KeyF6 />,
  keyF7: <KeyF7 />,
  keyF8: <KeyF8 />,
  keyF9: <KeyF9 />,
  keyFn: <KeyFn />,
  keyFunctionMac: <KeyFunctionMac />,
  keyG: <KeyG />,
  keyH: <KeyH />,
  keyHashtag: <KeyHashtag />,
  keyHomeButton: <KeyHomeButton />,
  keyI: <KeyI />,
  keyJ: <KeyJ />,
  keyK: <KeyK />,
  keyL: <KeyL />,
  keyLeftAngleParentheses: <KeyLeftAngleParentheses />,
  keyM: <KeyM />,
  keyMinus: <KeyMinus />,
  keyN: <KeyN />,
  keyO: <KeyO />,
  keyOption: <KeyOption />,
  keyP: <KeyP />,
  keyPageDownButton: <KeyPageDownButton />,
  keyPageUpButton: <KeyPageUpButton />,
  keyPlus: <KeyPlus />,
  keyQ: <KeyQ />,
  keyR: <KeyR />,
  keyRightAngleParentheses: <KeyRightAngleParentheses />,
  keyS: <KeyS />,
  keySolidus: <KeySolidus />,
  keySpace: <KeySpace />,
  keyT: <KeyT />,
  keyTab: <KeyTab />,
  keyU: <KeyU />,
  keyV: <KeyV />,
  keyW: <KeyW />,
  keyWindows: <KeyWindows />,
  keyX: <KeyX />,
  keyY: <KeyY />,
  keyZ: <KeyZ />,
  keyboard: <Keyboard />,
  launchpad: <Launchpad />,
  layers: <Layers />,
  leanFrontToBack: <LeanFrontToBack />,
  library: <Library />,
  linkedin: <Linkedin />,
  logo: <Logo />,
  logo2KLogo: <Logo2KLogo />,
  macbookProM1: <MacbookProM1 />,
  mail: <Mail />,
  mastercard: <Mastercard />,
  messages: <Messages />,
  microphone: <Microphone />,
  microsoftEdge: <MicrosoftEdge />,
  microsoftExcel: <MicrosoftExcel />,
  microsoftOnenote: <MicrosoftOnenote />,
  microsoftOnenote2: <MicrosoftOnenote2 />,
  microsoftOutlook: <MicrosoftOutlook />,
  microsoftPowerpoint: <MicrosoftPowerpoint />,
  microsoftTeams2019: <MicrosoftTeams2019 />,
  microsoftToDoApp: <MicrosoftToDoApp />,
  microsoftWord: <MicrosoftWord />,
  money: <Money />,
  more: <More />,
  motherboard: <Motherboard />,
  motionControl: <MotionControl />,
  motionControl1: <MotionControl1 />,
  motionControl2: <MotionControl2 />,
  mouse: <Mouse />,
  mouseLeftClick: <MouseLeftClick />,
  mouseMiddleClick: <MouseMiddleClick />,
  mouseRightClick: <MouseRightClick />,
  mouseScrolling: <MouseScrolling />,
  move: <Move />,
  moveDown: <MoveDown />,
  moveUp: <MoveUp />,
  multipleDevices: <MultipleDevices />,
  music: <Music />,
  naturalUserInterface1: <NaturalUserInterface1 />,
  naturalUserInterface2: <NaturalUserInterface2 />,
  netflix: <Netflix />,
  newYorkTimes: <NewYorkTimes />,
  nintendoSwitchLogo: <NintendoSwitchLogo />,
  nod: <Nod />,
  notes: <Notes />,
  octopus: <Octopus />,
  office365: <Office365 />,
  openCloseMouth: <OpenCloseMouth />,
  pandoraApp: <PandoraApp />,
  pause: <Pause />,
  pause2: <Pause2 />,
  paypal: <Paypal />,
  phone: <Phone />,
  play: <Play />,
  playstation: <Playstation />,
  playstore: <Playstore />,
  plus: <Plus />,
  plusLight: <PlusLight />,
  powerBi: <PowerBi />,
  preferences: <Preferences />,
  preview: <Preview />,
  process: <Process />,
  ps4: <Ps4 />,
  purchaseOrder: <PurchaseOrder />,
  read: <Read />,
  reminders: <Reminders />,
  repeat: <Repeat />,
  replicateRows: <ReplicateRows />,
  rockstarGames: <RockstarGames />,
  safari: <Safari />,
  sanAndreas: <SanAndreas />,
  save: <Save />,
  search: <Search />,
  selfServiceKiosk: <SelfServiceKiosk />,
  sensitivity: <Sensitivity />,
  settings: <Settings />,
  shakePhone: <ShakePhone />,
  share: <Share />,
  shazam: <Shazam />,
  shift: <Shift />,
  shift2: <Shift2 />,
  shiftMac: <ShiftMac />,
  showtime: <Showtime />,
  signalApp: <SignalApp />,
  siri: <Siri />,
  slack: <Slack />,
  smartWatch: <SmartWatch />,
  smile: <Smile />,
  soundcloud: <Soundcloud />,
  sparkles: <Sparkles />,
  speaker: <Speaker />,
  speedometer: <Speedometer />,
  spotify: <Spotify />,
  squaredMenu: <SquaredMenu />,
  ssd: <Ssd />,
  stop: <Stop />,
  svgCephy: <SvgCephy />,
  switchControl: <SwitchControl />,
  switchControl1: <SwitchControl1 />,
  switchControl2: <SwitchControl2 />,
  switchSimple: <SwitchSimple />,
  symantec: <Symantec />,
  sync: <Sync />,
  tabKey2: <TabKey2 />,
  tabMac: <TabMac />,
  tapPause: <TapPause />,
  tapPlay: <TapPlay />,
  tapStop: <TapStop />,
  tiktok: <Tiktok />,
  timer: <Timer />,
  todoist: <Todoist />,
  tools: <Tools />,
  touchpad: <Touchpad />,
  trello: <Trello />,
  tripadvisor: <Tripadvisor />,
  tv: <Tv />,
  twitch: <Twitch />,
  twitterx: <Twitterx />,
  uber: <Uber />,
  unity: <Unity />,
  unrealEngine: <UnrealEngine />,
  usb2: <Usb2 />,
  usbC: <UsbC />,
  usbOff: <UsbOff />,
  user: <User />,
  userGroups: <UserGroups />,
  vector: <Vector />,
  virtualButton: <VirtualButton />,
  virtualReality: <VirtualReality />,
  visualStudio: <VisualStudio />,
  voice: <Voice />,
  voiceControl: <VoiceControl />,
  voiceMemos: <VoiceMemos />,
  warning: <Warning />,
  waze: <Waze />,
  weather: <Weather />,
  wechat: <Wechat />,
  wellsfargo: <Wellsfargo />,
  whatsapp: <Whatsapp />,
  wiFi: <WiFi />,
  windows11: <Windows11 />,
  xfinity: <Xfinity />,
  yelp: <Yelp />,
  youtube: <Youtube />,
  zoom: <Zoom />,
};
