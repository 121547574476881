const handleCameraPermission = async () => {
    try {
        //@ts-ignore
        const permissionsQuery = await navigator.permissions.query({ name: 'camera' });
        if (permissionsQuery.state === 'granted') {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error handling camera permissions:', error);
        return false;
    }
};

const handleCameraDevices = async () => {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter((device) => device.kind === 'videoinput');
        return cameras;
    } catch (error) {
        console.error('Error handling camera devices:', error);
        return [];
    }
};

export { handleCameraPermission, handleCameraDevices };
