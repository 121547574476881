import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IconName } from "../../components/images/IconMap";
import {
  ContentLayout,
  PageTitle,
} from "../../components/text/ContentTypography";
import { BodyLarge, BodyMedium } from "../../components/text/Typography";
import DetectedPeripherals from "../../components/structure/DetectedPeripherals";
import PeripheralsContainer from "../../hooks/PeripheralsContainer";
import { CardSmallGrid } from "../../components/structure/CommonCardLayouts";
import CardInputExplore from "../../components/structure/CardInputExplore";
import { useEffect } from "react";
import Footer from "../../components/structure/app/Footer";
import Icons from "../../components/images/Icons";
import { FullLogo } from "../../components/images/icons/brand";
import styled from "@emotion/styled";

interface IExploreCardData {
  title: string;
  description: string;
  icon: IconName;
  available?: boolean;
  requirement: string;
  animate?: "voice" | "head" | "expression" | "virtualButton" | "motion";

  href: string;
}

const LandingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const peripheralsContainer = PeripheralsContainer.useContainer();
  useEffect(() => {
    peripheralsContainer.getPeripherals();
  }, []);
  const exploreCards: Record<string, IExploreCardData> = {
    voice: {
      title: t("common:voice.titlePlural"),
      description: t("common:voice.descriptionShort"),
      icon: "voice",
      requirement: t("common:microphone.require"),
      href: "2048/voice",
      available: true, //peripheralsContainer.microphoneEnabled,
      animate: "voice",
    },
    head: {
      title: t("common:head.titlePlural"),
      description: t("common:head.descriptionShort"),
      icon: "nod",
      requirement: t("common:camera.require"),
      href: "2048/head",
      available: true, // peripheralsContainer.cameraEnabled,
      animate: "head",
    },
    // expression: {
    //   title: t("common:expression.title"),
    //   description: t("common:face.descriptionShort"),
    //   icon: "expression",
    //   requirement: t("common:camera.require"),
    //   href: "face-expressions",
    //   available: peripheralsContainer.cameraEnabled,
    //   animate: "expression",
    // },
    virtualButton: {
      title: t("common:hotkey.titlePlural"),
      description: t("common:hotkey.descriptionShort"),
      icon: "virtualButton",
      requirement: t("common:landing.requiresClick"),
      href: "2048/buttons",
      available: true,
      animate: "virtualButton",
    },
    // motion: {
    //   title: t("common:motion.title"),
    //   description: t("common:motion.descriptionShort"),
    //   icon: "shakePhone",
    //   requirement: t("common:input.requiresCompanionApp"),
    //   href: "motion-controls",
    //   available: peripheralsContainer.companionAppEnabled,
    //   animate: "motion",
    // },
    // switch: {
    //     title: t('common:input.switch'),
    //     description: t('common:input.switchDescription'),
    //     icon: 'switchControl',
    //     requirement: t('common:input.requiresSwitch'),
    //     href: 'switch-controls',
    //     available: peripheralsContainer.hidSwitchEnabled,
    // },
  };

  return (
    <>
      <ContentLayout>
        <LandingHeader>
          <FullLogo />
        </LandingHeader>
        <PageTitle>{t("common:landing.explore")}</PageTitle>
        <BodyMedium>{t("common:landing.description")}</BodyMedium>

        <CardSmallGrid>
          {Object.keys(exploreCards).map((key) => {
            const card = exploreCards[key];
            return (
              <CardInputExplore
                animate={card.animate}
                key={`explore_${key}`}
                title={card.title}
                name={card.icon}
                requirement={card.requirement}
                onClick={() => navigate(card.href)}
                description={card.description}
                available={card.available}
              />
            );
          })}
        </CardSmallGrid>
      </ContentLayout>

      <Footer items={[{ title: `Copyright Cephable Inc. ${new Date().getFullYear()}`, href: "https://cephable.com", target: "_blank" }]} />
    </>
  );
};

const LandingHeader = styled.header`
  height: 4rem;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  svg {
    height: 4rem;
  }
`

export default LandingPage;
