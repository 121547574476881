import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconName } from '../images/IconMap';
import Icons from '../images/Icons';
import { color } from '../../constants/Styles';

type TextButtonVariant = 'default' | 'context' | 'control' | 'negative';
type ButtonType = 'button' | 'submit' | 'reset';

interface ITextButtonProps {
    variant?: TextButtonVariant;
    disabled?: boolean;
    startIcon?: IconName;
    endIcon?: IconName;
    children: React.ReactNode;
    target?: string;
    href?: string;
    type?: ButtonType;
    onClick?: (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.KeyboardEvent<HTMLButtonElement>
            | React.TouchEvent<HTMLButtonElement>
    ) => void;
}

function TextButton({
    type = 'button',
    variant = 'default',
    disabled = false,
    onClick,
    href,
    target,
    startIcon,
    endIcon,
    children,
    ...restProps
}: ITextButtonProps) {
    const commonProps = {
        variant,
        disabled,
        target,
    };

    endIcon = target === '_blank' ? 'externalLink' : endIcon;

    if (href) {
        return (
            <StyledLink href={href} {...commonProps} {...restProps}>
                {startIcon && <Icons name={startIcon} />}
                {children}
                {endIcon && <Icons name={endIcon} />}
            </StyledLink>
        );
    } else {
        return (
            <StyledButton type={type} onClick={onClick} {...commonProps} {...restProps}>
                {startIcon && <Icons name={startIcon} />}
                {children}
                {endIcon && <Icons name={endIcon} />}
            </StyledButton>
        );
    }
}

const buttonBaseStyles = css`
    font-family: 'Archivo', sans-serif;
    border: none;
    background-color: transparent;
    padding: 0.25rem 1rem 0.25rem 0;
    cursor: pointer;
    :focus-visible {
        outline: ${color.accent} 3px solid;
    }
    @media (prefers-reduced-motion: no-preference) {
        transition: all 0.1s ease-out;
    }
    svg {
        vertical-align: middle;
        scale: 0.8;
        padding: 0 0.25rem;
    }
`;
const controlButtonStyles = css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1rem; // 16px
    color: ${color.neutral};
    line-height: 1.313rem; // 21px
    :hover {
        cursor: pointer;
        color: ${color.secondary};
        text-decoration: underline;
    }
`;
const defaultButtonStyles = css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    color: ${color.text_default};
    font-size: 0.875rem;
    font-weight: 500;
    :hover {
        color: ${color.secondary};
        text-decoration: underline;
    }
`;
const contextButtonStyles = css`
    color: ${color.secondary};
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.625rem;
    text-decoration: underline !important;
    :hover {
        color: ${color.text_default};
        text-decoration: none;
    }
`;
const negativeButtonStyles = css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    color: ${color.error};
    font-size: 0.875rem;
    font-weight: 500;
    :hover {
        color: ${color.secondary};
        text-decoration: underline;
    }
`;

const StyledButton = styled.button<ITextButtonProps>`
    ${buttonBaseStyles}
    ${(props) => props.variant === 'default' && defaultButtonStyles}
    ${(props) => props.variant === 'context' && contextButtonStyles}
    ${(props) => props.variant === 'control' && controlButtonStyles}
    ${(props) => props.variant === 'negative' && negativeButtonStyles}
`;
const StyledLink = styled.a<ITextButtonProps>`
    ${buttonBaseStyles}
    ${(props) => props.variant === 'default' && defaultButtonStyles}
    ${(props) => props.variant === 'context' && contextButtonStyles}
    ${(props) => props.variant === 'control' && controlButtonStyles}
    ${(props) => props.variant === 'negative' && negativeButtonStyles}
`;

export default TextButton;
