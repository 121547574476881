import { IControlDescription } from "../../models/deviceProfiles/ControlDescription";

// tilt = angle change
export const ExpressionHeadTiltLeft = "head_tilt_left";
export const ExpressionHeadTiltRight = "head_tilt_right";
export const ExpressionHeadTiltBack = "head_tilt_back";
export const ExpressionHeadTiltForward = "head_tilt_forward";
export const ExpressionHeadTurnLeft = "head_turn_left";
export const ExpressionHeadTurnRight = "head_turn_right";

// lean = position change
export const ExpressionHeadLeanLeft = "head_lean_left";
export const ExpressionHeadLeanRight = "head_lean_right";
export const ExpressionHeadLeanBack = "head_lean_back";
export const ExpressionHeadLeanForward = "head_lean_forward";
export const ExpressionHeadMoveUp = "head_move_up";
export const ExpressionHeadMoveDown = "head_move_down";
export const ExpressionHeadCenter = "head_center";

export const HeadControls = [
  ExpressionHeadTiltLeft,
  ExpressionHeadTiltRight,
  ExpressionHeadTiltBack,
  ExpressionHeadTiltForward,
  ExpressionHeadTurnLeft,
  ExpressionHeadTurnRight,
  ExpressionHeadLeanLeft,
  ExpressionHeadLeanRight,
  ExpressionHeadLeanBack,
  ExpressionHeadLeanForward,
  ExpressionHeadMoveDown,
  ExpressionHeadMoveUp,
  ExpressionHeadCenter,
];

const createHeadCommand = (
  value: string,
  title: string,
  description: string
): IControlDescription => {
  return {
    value,
    title,
    description,
  };
};

export const HeadControlDescription = [
  createHeadCommand(
    ExpressionHeadTiltLeft,
    "Tilt Your Head Left",
    "If you are looking at the camera and tilt your head to your left, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadTiltRight,
    "Tilt Your Head Right",
    "If you are looking at the camera and tilt your head to your right, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadTiltBack,
    "Tilt Your Head Back",
    "If you are looking at the camera and tilt your head back and look up, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadTiltForward,
    "Tilt Your Head Forward",
    "If you are looking at the camera and tilt your head forward and look down, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadTurnLeft,
    "Turn Your Head Left",
    "If you are looking at the camera and turn your head and look to your left, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadTurnRight,
    "Turn Your Head Right",
    "If you are looking at the camera and turn your head and look to your right, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadLeanBack,
    "Lean Back",
    "If you are looking at the camera and lean back so your face is further from the camera, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadLeanForward,
    "Lean Forward",
    "If you are looking at the camera and lean forward so your face is closer to the camera, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadLeanLeft,
    "Lean Left",
    "If you are looking at the camera and lean left so your face is further left in the camera frame, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadLeanRight,
    "Lean Right",
    "If you are looking at the camera and lean right so your face is further right in the camera frame, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadMoveUp,
    "Move Up",
    "If you are looking at the camera and move your head up by stretching your neck or moving your whole body up, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadMoveDown,
    "Move Down",
    "If you are looking at the camera and move your head down by scrunching your neck, ducking, or lowering your whole body, this control will trigger."
  ),
  createHeadCommand(
    ExpressionHeadCenter,
    "Center Your Head",
    "If you are looking at the camera and move your head back to the center position matching baseline, this control will trigger."
  ),
];
