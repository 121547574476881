import DetectionRange from '../../models/expressionDetection/DetectionRange';

export const getTriggerValue = (
    range: DetectionRange,
    sensitivity: number | null | undefined,
    defaultValue: number,
    applyToMin = false
): number => {
    // sens is 0-100
    // apply that as a percentage between the range values
    if (sensitivity == null) {
        return defaultValue;
    }

    const modifier = sensitivity / 100;
    const distance = range.max - range.min;
    return applyToMin ? range.min + modifier * distance : range.max - modifier * distance;
};
