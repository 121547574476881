import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={100}
        height={100}
        fill="currentColor"
        viewBox="0 0 512 512"
        {...props}>
        <path d="M352 128.4 319.7 96 160 256l159.7 160 32.3-32.4L224.7 256z" />
    </svg>
);
export default SvgComponent;
