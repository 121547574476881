import styled from "@emotion/styled";
import {
  BodyMedium,
  DisplayLarge,
  LabelMedium,
  TitleLarge,
  TitleMedium,
  TitleSmall,
} from "./Typography";
import Button from "../buttons/CoreButton";
import TextButton from "../buttons/TextButton";
import { color } from "../../constants/Styles";
/**
 * ContentTypography.tsx
 *
 * This file contains the typography components for the content of the page.
 * They include additonal padding and margins to help with the layout of the page.
 * For core typography see Typography.tsx
 *
 */

export const ContentLayout = styled.div`
  max-width: 1000px;
  min-height: calc(100% - 144px);
  padding: 1rem 2rem 3rem 2rem;
  margin: 0 auto;
`;

export const ContentQuickWrap = styled.div`
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
`;

export const PageTitle = styled(DisplayLarge)`
  padding: 0.5rem 0;
`;
export const ContentLabelMedium = styled(LabelMedium)`
  padding: 1rem 0 0.5rem 0;
`;
export const ContentTitle = styled(TitleMedium)`
  padding: 2rem 0 0.5rem 0;
`;
export const ContentTitleSmall = styled(TitleSmall)`
  padding: 1rem 0 0.5rem 0;
`;
export const SecondaryText = styled(BodyMedium)`
  color: ${color.neutral};
  display: inline-block;
`;
export const ContentButton = styled(Button)`
  margin: 2rem 0;
`;
export const ContentTextButton = styled(TextButton)`
  display: inline-block;
  padding-left: 0.5rem;
`;

export const ContentText = styled(BodyMedium)`
  padding: 0rem 0 0.5rem 0;
`;

export const ContentTextSmall = styled(BodyMedium)`
  padding: 0rem 0 0.5rem 0;
`;

export const ContentTitleThin = styled(TitleSmall)`
  padding: 0rem 0 1rem 0;
  font-weight: 300;
`;

export const InlineButton = styled(TextButton)`
  font-weight: 700;
  font-size: 1.5rem;
  color: ${color.text_default};
  :hover {
    color: ${color.secondary};
  }
`;

export const Tag = styled.div`
  display: block;
  margin: 0.5rem 0;
  width: min-content;
  background-color: ${color.accent_2};
  padding: 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0.375rem;
`;
