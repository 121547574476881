import React from "react";
import HotkeyModel from "../../models/deviceProfiles/HotkeyModel";
import Button from "../buttons/CoreButton";
import styled from "@emotion/styled";
import { breakpoint_small } from "../../constants/breakpoints";

interface VirtualButtonControlsProps {
  hotkeys: HotkeyModel[];
  onHotkeyClick: (hotkey: HotkeyModel) => void;
  buttonSize: "small" | "medium" | "large";
}

const VirtualButtonControls: React.FC<VirtualButtonControlsProps> = ({
  hotkeys,
  onHotkeyClick,
  buttonSize
}) => {

  const getButtonMinSize = () => {
    switch (buttonSize) {
      case "small":
        return "80px";
      case "medium":
        return "120px";
      case "large":
        return "160px";
    }
  }
  const getButtonMobileSize = () => {
    switch (buttonSize) {
      case "small":
        return "60px";
      case "medium":
        return "80px";
      case "large":
        return "100px";
    }
  }

  const HotkeyButton = styled(Button)`
  min-width: ${getButtonMinSize()};
  min-height: ${getButtonMinSize()};
  ${breakpoint_small} {
    min-width: ${getButtonMobileSize()};
    min-height: ${getButtonMobileSize()};
  }
`;

  return (
    <ButtonContainer>
      {hotkeys.map((hotkey, index) => (
        <HotkeyButton
          key={index}
          $color="primary"
          onClick={() => onHotkeyClick(hotkey)}
        >
          {hotkey.displayName}
        </HotkeyButton>
      ))}
    </ButtonContainer>
  );
};


const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  ${breakpoint_small} {
    gap: 0.5rem;
  }
`;
export default VirtualButtonControls;
