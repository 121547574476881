import { useState } from "react";
import ContentDialog from "../dialog/ContentDialog";
import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardStack from "../card/CardStack";

interface VoiceSettingsDialogProps {
    settings: VoiceSettings;
    open: boolean;
    onClose: () => void;
    onConfirm: (settings: VoiceSettings) => void;
}
export interface VoiceSettings {
    isOptimistic: boolean;
}
const VoiceSettingsDialog: React.FC<VoiceSettingsDialogProps> = ({
    settings,
    open,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation();
    const [localSettings, setSettings] = useState<VoiceSettings>({ isOptimistic: settings.isOptimistic });
    const handleConfirm = () => {
        onConfirm(localSettings);
        localStorage.setItem('voiceSettings', JSON.stringify(localSettings));
        onClose();
    }
    return (
        <ContentDialog
            title={t('common:voice.settings')}
            open={open}
            onClose={onClose}
            onConfirm={handleConfirm}>


            <CardStack
                title={t('common:microphone.edit.optimistic.title')}
                description={t('common:microphone.edit.optimistic.description')}>
                <FormControlLabel
                    value="start"
                    control={
                        <Switch
                            checked={localSettings.isOptimistic}
                            onChange={() => {
                                setSettings({ ...localSettings, isOptimistic: !localSettings.isOptimistic });
                            }}
                        />
                    }
                    label={t('common:microphone.edit.optimistic.option')}
                    labelPlacement="start"
                />
            </CardStack>
        </ContentDialog>
    );
}

export default VoiceSettingsDialog;