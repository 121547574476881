import { useEffect, useRef, useState } from "react";
import { PermissionState } from "../../models/devices/DevicePermissionState";
import styled from "@emotion/styled";
import { color_gradient_primary_full } from "../../constants/Colors";
import { color, typographySize } from "../../constants/Styles";
import IconButton from "../buttons/IconButton";
import { PermissionChip } from "../structure/NotifyError";
import { PageTitle } from "../text/ContentTypography";
import { Trans, useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import Icons from "../images/Icons";
import VoiceCommandsContainer from "../../hooks/VoiceCommandsContainer";

interface VoiceControlsProps {
  commands: string[];
  onCommandDetected: (command: string) => void;
  allowDictation?: boolean;
  isOptimisticVoiceModel: boolean;
}
let currentVoiceCommandChain: string[] = [];
let isOptimistic = false;
const VoiceControls: React.FC<VoiceControlsProps> = ({
  commands,
  onCommandDetected,
  allowDictation = false,
  isOptimisticVoiceModel,
}) => {
  const { t } = useTranslation();
  const [resultText, setResultText] = useState<string>("");
  const [isFadeOut, setIsFadeOut] = useState<boolean>(false);
  const [isDictationMode, setIsDictationMode] = useState<boolean>(false);
  const voiceCommandsContainer = VoiceCommandsContainer.useContainer();

  const [checkPermissions, setCheckPermissions] = useState<PermissionState>(
    PermissionState.Loading
  );
  const containerRef = useRef<HTMLDivElement>(null);
  async function checkMediaPermissions() {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setCheckPermissions(PermissionState.Approved);
    } catch (e) {
      setCheckPermissions(PermissionState.Denied);
    }
  }
  useEffect(() => {
    isOptimistic = isOptimisticVoiceModel;
  }, [])
  useEffect(() => {
    if (isOptimisticVoiceModel !== isOptimistic) {
      isOptimistic = isOptimisticVoiceModel;

      console.log("optimistic voice model changed", isOptimisticVoiceModel);
      voiceCommandsContainer.stopListening();
      startListening();
    }
  }, [isOptimisticVoiceModel]);

  const startListening = async () => {
    console.log("starting listening");
    let allowedCommands: string[] = [];
    if (isOptimisticVoiceModel) {
      allowedCommands.push(...(commands ?? []));
    } else {
      allowedCommands = null;
    }
    await voiceCommandsContainer.startListening(allowedCommands);
  };
  let timeout: NodeJS.Timeout;
  let timeout2: NodeJS.Timeout;
  const handleSpeechResult = (text: string) => {
    setResultText(text);
    if (timeout) {
      clearTimeout(timeout);
    }
    if (timeout2) {
      clearTimeout(timeout2);
    }
    if (text?.length > 0) {
      setIsFadeOut(false);
      timeout = setTimeout(() => {
        setIsFadeOut(true);
        timeout2 = setTimeout(() => {
          setResultText("");
          voiceCommandsContainer.setDetectedCommands([]);
        }, 1000);
      }, 1000);
    }
  };

  useEffect(() => {
    checkMediaPermissions();
    voiceCommandsContainer.setPartialListeners([
      (result) => handleResult(false, result),
    ]);
    voiceCommandsContainer.setFinalListeners([
      (result) => handleResult(true, result),
    ]);

    return () => {
      console.log("cleaning up voice controls");
      voiceCommandsContainer.clearListeners();
      voiceCommandsContainer.stopListening();
    };
  }, []);
  useEffect(() => {
    if (checkPermissions == PermissionState.Approved) {
      console.log("starting listening");
      voiceCommandsContainer.setFinalListeners([
        ...voiceCommandsContainer.finalListenersRef.current,
        handleSpeechResult,
        (_) => {
          voiceCommandsContainer.setDetectedCommands([]);
        },
      ]);
      voiceCommandsContainer.setPartialListeners([
        ...voiceCommandsContainer.partialListenersRef.current,
        handleSpeechResult,
      ]);
      console.log(
        "listening for voice commands",
        isOptimisticVoiceModel ? "optimistic" : "non-optimistic"
      );
      startListening();
    }
  }, [checkPermissions]);

  const togglePause = () => {
    if (voiceCommandsContainer.isPaused) {
      voiceCommandsContainer.resumeListening();
    } else {
      voiceCommandsContainer.pauseListening();
    }
  };

  function processResultText(resultText: string): JSX.Element {
    if (!resultText) return null;
    const parts = resultText.split(" ");

    if (voiceCommandsContainer.detectedCommands.length > 0) {
      return (
        <>
          {parts.map((part, index) => {
            const detected = voiceCommandsContainer.detectedCommands.find(
              (command) => part.includes(command)
            );
            if (detected) {
              return <em key={index}>{part}</em>;
            } else {
              return <span key={index}> {part}</span>;
            }
          })}
        </>
      );
    }
    return <>{resultText}</>;
  }

  function handleResult(isFinal: boolean, text: string) {
    if (text.length < 1) return;
    // handle chain here, then fire commands to the bridge
    for (let chainItem of currentVoiceCommandChain) {
      text = text.replace(chainItem, "").trim();
    }

    if (commands.indexOf(text) > -1) {
      onCommandDetected(text);
      currentVoiceCommandChain = [...currentVoiceCommandChain, text];
    }
    if (isFinal) {
      currentVoiceCommandChain = [];
    }
  }

  return (
    <Container ref={containerRef}>
      {checkPermissions === PermissionState.Approved ? (
        <>
          <Tooltip
            title={
              voiceCommandsContainer.isPaused
                ? t("common:microphone.controls.resume")
                : "Pause voice controls"
            }
            placement="right"
          >
            <PauseButton onClick={togglePause}>
              <Icons
                name={voiceCommandsContainer.isPaused ? "play" : "pause"}
                size={32}
              />
            </PauseButton>
          </Tooltip>
          {allowDictation && (
            <Tooltip
              title={
                isDictationMode
                  ? t("common:microphone.controls.dictationPlay")
                  : t("common:microphone.controls.dictationPause")
              }
              placement="right"
            >
              <DictationButton
                onClick={() =>
                  voiceCommandsContainer.updateDictationMode(!isDictationMode)
                }
              >
                <Icons
                  name={isDictationMode ? "dictationPause" : "dictationPlay"}
                  size={32}
                />
              </DictationButton>
            </Tooltip>
          )}

          {voiceCommandsContainer.isPaused ? (
            <RecognitionResultsLabel className="preview">
              <i>{t("common:microphone.controls.paused")}</i>
            </RecognitionResultsLabel>
          ) : resultText ? (
            <RecognitionResultsLabel className={isFadeOut ? "fade-out" : ""}>
              {processResultText(resultText)}
            </RecognitionResultsLabel>
          ) : (
            <RecognitionResultsLabel className="preview">
              {isDictationMode ? (
                <i>{t("common:microphone.controls.dictationPrompt")}</i>
              ) : (
                <i>{t("common:microphone.controls.prompt")}</i>
              )}
            </RecognitionResultsLabel>
          )}
          {voiceCommandsContainer.isLatencyHigh && (
            <Tooltip
              title={t("common:microphone.controls.latency")}
              placement="left"
            >
              <WarningIcon>
                <Icons name="warning" size={16} color={color.dark_primary} />
                <VisuallyHidden role="alert">
                  {t("common:microphone.controls.latency")}
                </VisuallyHidden>
              </WarningIcon>
            </Tooltip>
          )}
        </>
      ) : (
        <>
          {checkPermissions === PermissionState.Loading ? (
            <i>{t("common:loading")}</i>
          ) : (
            <PermissionWrap>
              <PermissionChip>
                <Icons name="tools" />
                {t("common:microphone.permission.no")}
              </PermissionChip>
              <PermissionContent>
                <PageTitle>
                  {" "}
                  {t("common:microphone.permission.title")}
                </PageTitle>
                <Trans>{t("common:microphone.permission.help")}</Trans>
              </PermissionContent>
            </PermissionWrap>
          )}
        </>
      )}
    </Container>
  );
};

const WarningIcon = styled.div`
  margin-right: 16px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: ${color.accent_2};
  flex-shrink: 0;
`;

const PermissionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const PermissionWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
`;

const PauseButton = styled(IconButton)`
  min-width: 54px;
`;
const DictationButton = styled(IconButton)`
  min-width: 54px;
`;

// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role
const VisuallyHidden = styled.div`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const RecognitionResultsLabel = styled.p`
  font-size: ${typographySize.heading_1};
  text-align: start;
  align-self: start;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 12px;
  flex: 1;
  &.fade-out {
    animation: fade-response 0.5s 1;
    -webkit-animation: fade-response 0.5s 1;
    animation-fill-mode: forwards;

    animation-delay: 3s;
    -webkit-animation-delay: 1s; /* Safari and Chrome */
    -webkit-animation-fill-mode: forwards;
  }

  em {
    font-weight: 600;
    font-style: italic;
    padding: 4px;
    border-radius: 4px;
    color: white;
    background: ${color_gradient_primary_full};
  }

  @keyframes fade-response {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @-webkit-keyframes fade-response {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 16px;
  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
`;

export default VoiceControls;
