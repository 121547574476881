import * as React from 'react';
import type { SVGProps } from 'react';
const SvgJira = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={100}
        fill="currentColor"
        viewBox="0 0 44.343 46"
        {...props}>
        <path d="M22.156 0a1 1 0 0 0-.691.293l-.02.02L.88 20.879a3.015 3.015 0 0 0 0 4.242l20.586 20.586a1 1 0 0 0 1.414 0l.02-.02 20.566-20.566a3.015 3.015 0 0 0 0-4.242L22.879.293A1 1 0 0 0 22.156 0zm.078 2.477 19.817 19.816a.985.985 0 0 1 0 1.414L25.934 39.824c.118-.582.204-1.172.236-1.771a1 1 0 0 0 0-.106 12.046 12.046 0 0 0-2.633-6.896l7.342-7.344a1 1 0 0 0 0-1.414l-7.98-7.98A10.036 10.036 0 0 1 20.181 8a10.048 10.048 0 0 1 2.052-5.523zM18.41 6.176a12.023 12.023 0 0 0-.236 1.771 1 1 0 0 0 0 .106 12.044 12.044 0 0 0 2.635 6.896l-7.344 7.344a1 1 0 0 0 0 1.414l8 8.002A10.036 10.036 0 0 1 24.162 38a10.048 10.048 0 0 1-2.053 5.523L2.293 23.707a.985.985 0 0 1 0-1.414L18.41 6.176zm3.762 10.238L28.758 23l-6.586 6.586L15.586 23l6.586-6.586z" />
    </svg>
);
export default SvgJira;
