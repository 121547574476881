// TODO: Still seeing inconsistent behavior with microphone permissions.
// Perhaps look at https://www.electronjs.org/docs/latest/api/system-preferences#systempreferencesgetmediaaccessstatusmediatype-windows-macos for electron app?

const handleMicrophonePermission = async () => {
    try {
        //@ts-ignore
        const permissionsQuery = await navigator.permissions.query({ name: 'microphone' });
        if (permissionsQuery.state === 'granted') {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error handling microphone permission:', error);
        return false;
    }
};

const handleMicrophoneDevices = async () => {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const microphones = devices.filter((device) => device.kind === 'audioinput');
        return microphones;
    } catch (error) {
        console.error('Error handling microphone devices:', error);
        return [];
    }
};

export { handleMicrophonePermission, handleMicrophoneDevices };
