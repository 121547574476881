import { IControlDescription } from "../../models/deviceProfiles/ControlDescription";

// eyebrow
export const ExpressionLeftEyebrowRaised = "left_eyebrow_raise";
export const ExpressionRightEyebrowRaised = "right_eyebrow_raise";
export const ExpressionEyebrowsRaised = "eyebrows_raised";
export const ExpressionLeftEyebrowLowered = "left_eyebrow_lower";
export const ExpressionRightEyebrowLowered = "right_eyebrow_lower";
export const ExpressionEyebrowsLowered = "eyebrows_lower";

// mouth
export const ExpressionSmile = "smile";
export const ExpressionStoppedSmile = "stop_smile";
export const ExpressionMouthOpened = "mouth_open";
export const ExpressionMouthClosed = "mouth_close";
export const ExpressionMouthPucker = "mouth_pucker";

// eyes
export const ExpressionLeftEyeClosed = "left_eye_close";
export const ExpressionRightEyeClosed = "right_eye_close";
export const ExpressionLeftEyeOpened = "left_eye_open";
export const ExpressionRightEyeOpened = "right_eye_open";
export const ExpressionEyesClosed = "both_eyes_close";
export const ExpressionEyesOpened = "both_eyes_open";

export const FaceControls = [
  ExpressionLeftEyebrowRaised,
  ExpressionRightEyebrowRaised,
  ExpressionEyebrowsRaised,
  ExpressionLeftEyebrowLowered,
  ExpressionRightEyebrowLowered,
  ExpressionEyebrowsLowered,
  ExpressionSmile,
  ExpressionStoppedSmile,
  ExpressionMouthOpened,
  ExpressionMouthClosed,
  ExpressionLeftEyeClosed,
  ExpressionRightEyeClosed,
  ExpressionLeftEyeOpened,
  ExpressionRightEyeOpened,
  ExpressionEyesClosed,
  ExpressionEyesOpened,
];

const createFaceCommand = (
  value: string,
  title: string,
  description: string
): IControlDescription => {
  return {
    value,
    title,
    description,
  };
};

export const FaceControlDescription = [
  createFaceCommand(
    ExpressionSmile,
    "Start Smiling",
    "EXPERIMENTAL: If you start not smiling and then start to smile, this control will trigger. Note: using this control in the same profile as the Mouth Open / close control may cause conflicts."
  ),
  createFaceCommand(
    ExpressionStoppedSmile,
    "Stop Smiling",
    "EXPERIMENTAL: If you are smiling and then stop smiling, this control will trigger.  Note: using this control in the same profile as the Mouth Open / close control may cause conflicts."
  ),
  createFaceCommand(
    ExpressionEyebrowsRaised,
    "Raise Your Eyebrows",
    "EXPERIMENTAL: If you raise both your eyebrows from your baseline resting position, this control will trigger."
  ),
  createFaceCommand(
    ExpressionMouthOpened,
    "Open Your Mouth",
    "If your mouth is closed (or if your baseline is slightly open), and you open your mouth more, this control will trigger.  Note: using this control in the same profile as the smile control may cause conflicts."
  ),
  createFaceCommand(
    ExpressionMouthClosed,
    "Close Your Mouth",
    "If your mouth is open and you close it entirely, this control will trigger. Note: using this control in the same profile as the smile control may cause conflicts."
  ),
];
